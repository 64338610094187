import { css, cx } from '@fable/theme';
import { useTheme } from '@fable/theme';
import { getIcon } from './helpers';
import { IconProps } from './types';

const Icon: React.VFC<IconProps> = ({ className, type, ...nativeProps }) => {
  const { colors } = useTheme();

  nativeProps.fill = nativeProps.fill ? nativeProps.fill : colors.blackSwan;

  const icon = getIcon({ type, ...nativeProps });

  const styles = {
    icon: css`
      display: flex;

      svg {
        width: 100%;
        height: 100%;
      }
    `,
  };

  return (
    <span className={cx(styles.icon, className, 'fds-ui--icon')}>{icon}</span>
  );
};

export default Icon;
