import { css } from '@fable/theme';
import { Text } from '@fluentui/react-northstar';

const TaskHeader = ({ title, detail }: { title: string; detail?: string }) => (
  <div
    className={css`
      text-align: center;
    `}
  >
    <Text
      styles={{
        display: 'block',
        marginBottom: '2px',
        fontSize: '20px',
        lineHeight: '28px',
      }}
      weight="bold"
    >
      {title}
    </Text>
    {!!detail && <Text>{detail}</Text>}
  </div>
);

export default TaskHeader;
