import {AxiosError} from "axios";

function isAxiosError(error:Error): error is AxiosError {
    return error.hasOwnProperty('response');
}

export function errorMessage(error:Error): string {
    // @ts-ignore
    if (isAxiosError(error) && error?.response?.data?.detail) {
        // @ts-ignore
        return `${error.message}: ${error.response.data.detail}`
    }
    return error.message;
}