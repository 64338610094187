import {
  cloneElement,
  isValidElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Loader, Logo, TransitionElement } from '@fable/components';
import { css, cx, useTheme } from '@fable/theme';
import debounce from 'lodash/debounce';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { ChatContext } from '../chat_context';
import { flattenPages } from '../lib/chatUtils';
import { Message, OpenedThread } from '../chatTypes';

const ChatMessages = ({
  roomId = '',
  messageComponent,
  newMessageFlag,
  thread = {
    threadParentType: undefined,
    threadParentId: '',
    threadParentContentId: '',
  },
  ...nativeProps
}: {
  /** @param {string} roomId - The room id */
  roomId: string;
  /** @param {React.ReactElement<{ key: any; message: Message }>} messageComponent - The message component */
  messageComponent: React.ReactElement<{ key: any; message?: Message }>;
  /** @param {React.ReactElement | string} newMessageFlag - Element to show when a new message appears. ChatMessages will control click behavior. */
  newMessageFlag: React.ReactElement | string;
  /** @param {OpenedThread} thread - Thread data for loading messages with parent thread on top with its own cache */
  thread?: OpenedThread;
} & React.HTMLProps<HTMLDivElement>) => {
  const { motion, colors, nativeOverrides } = useTheme();
  const {
    newMessage,
    user,
    messagesQuery,
    messageSingleQuery,
    chatQueryClient,
  } = useContext(ChatContext);

  const virtuosoRef = useRef<VirtuosoHandle>(null);
  // https://virtuoso.dev/prepend-items/
  const initialItemCount = useRef(100);
  const startItemIndex = useRef(10000);

  const lastMessageSeenId = useRef<string>('');
  const atBottom = useRef<boolean>(true);

  const firstItemIndexUpdated = useRef<boolean>(true);

  const [firstItemIndex, setFirstItemIndex] = useState(startItemIndex.current);
  const [showNewMessageAlert, setShowNewMessageAlert] = useState(false);
  const { threadParentType, threadParentId, threadParentContentId } = thread;

  const messages = chatQueryClient?.getQueryData([
    'messages',
    // Intentionally not using chatParams from useChat in order to get queryData for a given instance of ChatMessages
    { roomId, threadParentType, threadParentId, threadParentContentId },
  ]);

  const messagesFlattened = flattenPages(
    (messages as { pages: { results: Message[] }[] })?.pages || []
  ).reverse();

  const loading = messagesQuery?.isLoading || messagesQuery?.isFetchingNextPage;

  const handleClickNewMessageNotification = () => {
    if (virtuosoRef.current) {
      virtuosoRef.current.scrollToIndex({
        index: messagesFlattened ? messagesFlattened.length - 1 : 0,
        behavior: 'smooth',
      });
      if (newMessage) {
        lastMessageSeenId.current = newMessage.id;
      }
    }
  };

  const handleStartReached = useCallback(() => {
    if (messagesQuery?.hasNextPage && !loading) {
      // https://virtuoso.dev/endless-scrolling/
      setTimeout(() => {
        firstItemIndexUpdated.current = false;

        messagesQuery?.fetchNextPage();
      }, 500);
    }

    return false;
  }, [messagesQuery, loading]);

  const handleAtBottom = debounce(
    (bottom: boolean) => {
      atBottom.current = bottom;
      if (messagesFlattened?.length && showNewMessageAlert && bottom) {
        setShowNewMessageAlert(false);
      }
    },
    200,
    { maxWait: 1000 }
  );

  /**
   * This hook determines how to set showNewMessageAlert
   * based on whether or not a new message was seen while not already at the bottom of the chat
   */
  useEffect(() => {
    if (
      newMessage &&
      !atBottom.current &&
      lastMessageSeenId.current !== newMessage.id &&
      !showNewMessageAlert &&
      user?.id !== newMessage.user.id
    ) {
      setShowNewMessageAlert(true);
    }
  }, [newMessage, showNewMessageAlert, user?.id]);

  /**
   * This hook responds to pagination
   * The page size would normally be 20 but for newer rooms, it could be as little as 1
   * So the firstItemIndex must be updated to match the actual number of messagesFlattened in the new page
   */
  useEffect(() => {
    const pages = messagesQuery?.data?.pages || [];
    const currentPageItemCount = pages[pages.length - 1]?.results?.length || 0;
    const nextScrollToIndex = firstItemIndex - currentPageItemCount;

    if (!loading && !firstItemIndexUpdated.current) {
      firstItemIndexUpdated.current = true;
      setFirstItemIndex(() => nextScrollToIndex);
    }
  }, [firstItemIndex, loading, messagesQuery?.data?.pages]);

  if (!isValidElement(messageComponent)) {
    console.error('Component is invalid:\n', messageComponent);

    return null;
  }

  if (
    (!!threadParentId && messageSingleQuery?.isFetching) ||
    (messagesQuery?.isLoading &&
      // Do not trigger loader when fetching more pages
      !messagesQuery.isFetchingNextPage &&
      !messagesQuery.isFetchingPreviousPage)
  ) {
    return (
      <Loader
        className={css`
          height: 100%;
          width: 100%;
        `}
      />
    );
  }

  return (
    <div
      {...nativeProps}
      className={cx(
        css`
          width: inherit;
          height: calc(100% - 56px);
          flex: 1 1 auto;
          position: relative;
          overflow: hidden;
        `,
        nativeProps.className
      )}
    >
      <Virtuoso
        className={css`
          > div + div {
            border-top: 1px solid ${colors.almostWhite};
          }
        `}
        ref={virtuosoRef}
        startReached={handleStartReached}
        atBottomStateChange={handleAtBottom}
        firstItemIndex={firstItemIndex}
        initialTopMostItemIndex={initialItemCount.current - 1}
        data={messagesFlattened}
        followOutput="auto"
        itemContent={(_, message) =>
          cloneElement(messageComponent, { key: message.id, message })
        }
      />
      {messagesQuery?.isFetchingNextPage && (
        <TransitionElement
          transitionType="fadeIn"
          className={css`
            position: absolute;
            top: 8px;
            left: 0;
            text-align: center;
            height: 50px;
            width: 100%;
            z-index: 1;
          `}
        >
          <Logo
            className={css`
              background: inherit;
              opacity: 0.8;
              height: 100%;
              width: 100%;
              &.animate svg {
                height: 50px;
                width: 50px;
              }
            `}
            isIcon
            animate
          />
        </TransitionElement>
      )}
      <button
        onClick={handleClickNewMessageNotification}
        className={cx(
          nativeOverrides.buttonClear,
          css`
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translateX(-50%);
            width: fit-content;
            height: fit-content;
          `,
          showNewMessageAlert &&
            css`
              transform: translate(-50%, -50px);
              ${motion.transition()};
            `
        )}
      >
        {newMessageFlag}
      </button>
    </div>
  );
};

export default ChatMessages;
