import { IconSVGProps } from '../types';

export const MenuIcon: React.FC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
  >
    <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" />
  </svg>
);
