import { useContext, useEffect } from 'react';
import { ChatContext } from '@fable/chat';
import { ClubContext } from 'utils/ClubContext';

const useSetClubSlug = () => {
  // TypeScript only throws errors for this in Teams, not in our web app
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setClubSlug, clubDetailsQuery } = useContext(ChatContext);
  const { tenantClub } = useContext(ClubContext);

  useEffect(() => {
    const clubSlug = tenantClub?.slug;

    if (!!clubSlug && !clubDetailsQuery?.isFetched) {
      setClubSlug(clubSlug);
    }
  }, [clubDetailsQuery?.isFetched, setClubSlug, tenantClub?.slug]);
};

export default useSetClubSlug;
