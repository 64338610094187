import {assign, createMachine, DoneInvokeEvent} from 'xstate';
import {AccessToken} from '@azure/core-auth';
import {errorAssign, isErrorWithCode} from './utils/machine';
import {accountIsLinked, FirebaseUserUpdateEvent} from './Firebase';

export interface MachineContext {
  error?: Error;
  accessToken?: string;
  customToken?: string;
  idToken?: string;
}

function errorIsUiRequired(ctx: MachineContext, event: DoneInvokeEvent<Error>) {
  return isErrorWithCode(event.data) && event.data.code === 'UiRequiredError';
}

export type StartEvent = { type: 'START' };
export type SignInWithMicrosoftEvelnt = { type: 'SIGN_IN_WITH_MICROSOFT' };
export type RetryEvent = { type: 'RETRY' };

export type LinkMicrosoftAndFirebaseEvent = {
  type: 'LINK_MICROSOFT_AND_FIREBASE';
};
export type LinkSuccessEvent = { type: 'LINK_SUCCESS' };

export type MachineEvent =
  | StartEvent
  | SignInWithMicrosoftEvelnt
  | RetryEvent
  | FirebaseUserUpdateEvent
  | LinkMicrosoftAndFirebaseEvent
  | LinkSuccessEvent;

export const machine = createMachine({
  predictableActionArguments: true,
  strict: true,
  id: 'app',
  initial: 'fetchingMicrosoftAccessToken',
  schema: {
    context: {} as MachineContext,
    events: {} as MachineEvent,
  },
  context: {},
  states: {
    idle: {
      on: {
        START: 'idle',
      },
    },
    fetchingMicrosoftAccessToken: {
      entry: [assign({ error: undefined })],
      invoke: {
        src: 'fetchMicrosoftAccessToken',
        onDone: {
          target: 'checkingForFirebaseUser',
          actions: [
            assign<MachineContext, DoneInvokeEvent<AccessToken>>({
              accessToken: (_, event) => event.data.token,
            }),
            // Required to get the PeoplePicker to
            // https://stackoverflow.com/questions/62090255/people-picker-from-microsoft-graph-api-toolkit-is-not-working#comment109893871_62091117
            'updateMgtProviderToken',
          ],
        },
        onError: [
          {
            target: 'promptForMicrosoftInteractiveAuth',
            cond: errorIsUiRequired,
          },
          {
            target: 'error',
            actions: [errorAssign],
          },
        ],
      },
    },
    checkingForFirebaseUser: {
      invoke: {
        src: 'getFirebaseUser',
      },
      on: {
        FIREBASE_USER_UPDATE: [
          {
            target: 'fetchingFableUser',
            cond: (ctx, event) => !!event.idToken,
            actions: [
              assign<MachineContext, FirebaseUserUpdateEvent>({
                idToken: (_, event) => event.idToken,
              }),
              'updateAxiosToken',
            ],
          },
          {
            target: 'fetchingCustomToken',
          },
        ],
      },
    },
    fetchingCustomToken: {
      invoke: {
        src: 'fetchCustomToken',
        onDone: {
          target: 'signingInWithFirebase',
          actions: [
            assign<MachineContext, DoneInvokeEvent<string>>({
              customToken: (_, event) => event.data,
            }),
          ],
        },
        onError: {
          target: 'error',
          actions: [errorAssign],
        },
      },
    },
    signingInWithFirebase: {
      invoke: {
        src: 'signInWithFirebase',
        onDone: {
          target: 'fetchingFableUser',
          actions: [
            assign<MachineContext, DoneInvokeEvent<string>>({
              idToken: (_, event) => event.data,
            }),
            'updateAxiosToken',
          ],
        },
        onError: {
          target: 'error',
          actions: [errorAssign],
        },
      },
    },
    fetchingFableUser: {
      invoke: {
        src: 'fetchFableUser',
        onDone: {
          target: 'checkingAccountLinked',
        },
        onError: {
          target: 'error',
          actions: [errorAssign],
        },
      },
    },
    checkingAccountLinked: {
      always: [
        {
          target: 'authenticated',
          cond: accountIsLinked,
        },
        {
          target: 'promptForAccountLink',
        },
      ],
    },
    promptForMicrosoftInteractiveAuth: {
      on: {
        SIGN_IN_WITH_MICROSOFT: 'signingInWithMicrosoft',
      },
    },
    signingInWithMicrosoft: {
      invoke: {
        src: 'signInWithMicrosoft',
        onDone: {
          target: 'fetchingMicrosoftAccessToken',
        },
        onError: {
          target: 'error',
          actions: [errorAssign],
        },
      },
    },
    promptForAccountLink: {
      on: {
        LINK_MICROSOFT_AND_FIREBASE: 'linkingMicrosoftAndFirebase',
      },
    },
    linkingMicrosoftAndFirebase: {
      invoke: {
        src: 'linkMicrosoftAndFirebase',
        onDone: {
          target: 'fetchingMicrosoftAccessToken',
        },
        onError: {
          target: 'error',
          actions: [errorAssign],
        },
      },
    },
    error: {
      on: {
        RETRY: 'fetchingMicrosoftAccessToken',
      },
    },
    authenticated: {
      type: 'final',
    },
  },
});
