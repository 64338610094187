import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { css } from '@fable/theme'
import { FlexBox } from '@fable/components'
import { ChatContext } from '@fable/chat'
import Nav from 'components/Nav'
import { ClubContext } from 'utils/ClubContext'
import useSetClubSlug from 'hooks/useSetClubSlug'
import ChannelTabLeft from 'components/channel_tab/ChannelTabLeft'
import ChannelTabRight from 'components/channel_tab/ChannelTabRight'

const ChannelTab = () => {
  const location = useLocation()
  // @ts-ignore
  const { modal, enterRoom } = useContext(ChatContext)
  const { showChat, setShowChat } = useContext(ClubContext)

  useSetClubSlug()

  useEffect(() => {
    if (showChat && !location.pathname.includes('discussions')) {
      setShowChat({
        show: false,
        callback: () => {
          enterRoom({ room: null })
        },
      })
    }
  }, [enterRoom, location.pathname, setShowChat, showChat])

  return (
    <FlexBox
      flexDirection="column"
      className={css`
        width: 100%;
        height: 100vh;
        min-height: 700px;
      `}
    >
      {modal.show ? (
        <div
          className={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
            > div {
              box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.3);
            }
          `}
        >
          {modal.component}
        </div>
      ) : null}
      <Nav />
      <FlexBox
        className={css`
          flex: 1 1 auto;
        `}
      >
        <ChannelTabLeft />
        <ChannelTabRight />
      </FlexBox>
    </FlexBox>
  )
}

export default ChannelTab
