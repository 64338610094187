import { TextStyleProps, TextStyleMapping } from '../types';

const baseStyles: TextStyleProps = {
  fontFamily: `'Inter', sans-serif`,
  fontWeight: 400,
};

const styles: TextStyleMapping = {
  L: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '-0.2px',
  },
  M: {
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '-0.2px',
  },
  S: {
    fontSize: '8px',
    lineHeight: '8px',
    letterSpacing: '0px',
  },
};

export const labelStyles = { styles, baseStyles };
