import Resizer from 'react-image-file-resizer';

const resizeImage = (
  file: File,
  type: 'file' | 'base64'
): Promise<File | string> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'JPEG',
      100,
      0,
      (result: any) => {
        switch (type) {
          case 'base64':
            resolve(result as string);
            break;
          case 'file':
            resolve(result as File);
            break;
        }
      },
      type
    );
  });

export default resizeImage;

/**
 * https://www.npmjs.com/package/react-image-file-resizer
 *
 *
 Resizer.imageFileResizer(
  file, // Is the file of the image which will resized.
  maxWidth, // Is the maxWidth of the resized new image.
  maxHeight, // Is the maxHeight of the resized new image.
  compressFormat, // Is the compressFormat of the resized new image.
  quality, // Is the quality of the resized new image.
  rotation, // Is the degree of clockwise rotation to apply to uploaded image.
  responseUriFunc, // Is the callBack function of the resized new image URI.
  outputType, // Is the output type of the resized new image.
  minWidth, // Is the minWidth of the resized new image.
  minHeight // Is the minHeight of the resized new image.
);
 */
