import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { css } from '@fable/theme'
import { Input, SearchIcon } from '@fluentui/react-northstar'
import { useInfiniteQuery, useQuery } from 'react-query'
import { defaultQueryOptions, getBooks, getFolios } from 'utils/query'
import { FlexBox } from '@fable/components'
import TaskHeader from 'components/task/TaskHeader'
import BookList from 'components/BookList'
import FolioList from 'components/FolioList'

const ClubBookTask = () => {
  const listContainer = useRef<HTMLDivElement | null>(null)
  const bookSearchOffset = useRef(0)
  const [searchTerm, setSearchTerm] = useState('')

  const folios = useQuery('folios', async () => await getFolios(), {
    ...defaultQueryOptions,
    enabled: true,
  })

  const bookSearch = useInfiniteQuery(
    ['bookSearch', searchTerm],
    async ({ pageParam }) => await getBooks(searchTerm, pageParam),
    {
      ...defaultQueryOptions,
      keepPreviousData: !!searchTerm,
      enabled: !!searchTerm,
      getNextPageParam: () => bookSearchOffset.current,
    }
  )

  const handleBookListScroll = (
    event: React.UIEvent<HTMLDivElement, UIEvent>
  ) => {
    const target = event.target as HTMLDivElement

    if (
      !bookSearch.isFetchingNextPage &&
      Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <=
        3.0
    ) {
      bookSearchOffset.current += 20
      bookSearch.fetchNextPage()
    }
  }

  const handleSearchbarChange = (e: SyntheticEvent<HTMLElement, Event>) => {
    setSearchTerm((e.target as HTMLInputElement).value)
  }

  const loading = bookSearch.isFetching && !bookSearch.isFetchingNextPage

  useEffect(() => {
    const loading = bookSearch.isFetching && !bookSearch.isFetchingNextPage
    if (loading && listContainer.current?.scrollTop) {
      listContainer.current?.scrollTo({ top: 0 })
    }
  }, [bookSearch.isFetching, bookSearch.isFetchingNextPage])

  return (
    <FlexBox
      flexDirection="column"
      className={css`
        width: 100%;
        height: 100vh;
      `}
    >
      <div
        className={css`
          flex: 0 0 auto;
          padding: 0 30px;
        `}
      >
        <div
          className={css`
            text-align: center;
            margin: 0 auto;
          `}
        >
          <TaskHeader
            title="Choose a book"
            detail="Select from over a million books on Fable or go with your current
            book."
          />
        </div>
        <Input
          className={css`
            margin: 18px auto;
          `}
          fluid
          placeholder="Search"
          icon={<SearchIcon />}
          onChange={handleSearchbarChange}
          value={searchTerm}
        />
      </div>
      {!!searchTerm ? (
        /*  The key lets React know which element is removed or added
        Otherwise scroll position does not change when switching between these two elements
        https://reactjs.org/docs/lists-and-keys.html#keys */
        <div
          ref={listContainer}
          onScroll={handleBookListScroll}
          key={1}
          className={css`
            flex: 1 1 auto;
            overflow: auto;
          `}
        >
          <BookList
            loading={loading}
            listData={(bookSearch?.data?.pages || []).flatMap(
              (page: any) => page?.data?.response?.books || []
            )}
          />
        </div>
      ) : (
        <div
          key={2}
          className={css`
            flex: 1 1 auto;
            overflow: auto;
          `}
        >
          <FolioList
            loading={folios.isLoading}
            listData={folios?.data?.data || []}
          />
        </div>
      )}
    </FlexBox>
  )
}

export default ClubBookTask
