import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import App from './App'
import { theme, ThemeProvider } from '@fable/theme'
import LinkToMicrosoftTask from './components/sign_in_task/LinkToMicrosoftTask'
import { Debug } from './Debug'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route
              path="/link-to-microsoft/"
              element={<LinkToMicrosoftTask />}
            />
            <Route path="*" element={<App />} />
          </Routes>
          <Debug />
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
)
