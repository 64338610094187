import { createContext } from 'react';
import { QueryClient, QueryClientProvider, UseQueryOptions } from 'react-query';
import { User } from '@fable/types';
import { ChatContextType } from '../chatTypes';
import useChat from './useChat';

export const ChatContext = createContext<ChatContextType>({
  error: null,
  chatQueryClient: null,
  user: null,
  clubId: '',
  clubBookId: '',
  messagesQuery: null,
  messageSingleQuery: null,
  clubDetailsQuery: null,
  clubBookQuery: null,
  clubMembersQuery: null,
  milestoneDiscussionsQuery: null,
  milestonesQuery: null,
  openedThreads: null,
  chatParams: {
    roomId: '',
    threadParentType: undefined,
    threadParentId: '',
    threadParentContentId: '',
  },
  modal: { show: false, component: null },
  currentRoom: null,
  newMessage: null,
  image: null,
  defaultQueryOptions: {} as Omit<
    UseQueryOptions<any, unknown, any, any>,
    'queryKey' | 'queryFn'
  >,
  setImage: () => null,
  setClubSlug: () => null,
  closeThread: () => null,
  setModal: () => null,
  enterRoom: () => null,
  sendMessage: () => null,
  deleteMessage: () => null,
  sendReaction: () => null,
  deleteReaction: () => null,
});

ChatContext.displayName = 'ChatContext';

const chatQueryClient = new QueryClient();

export const ChatContextProvider: React.FC<{
  user: User;
  allowNotifications?: boolean;
  children: React.ReactNode | React.ReactNode[];
}> = ({ user, allowNotifications, children }) => (
  <QueryClientProvider client={chatQueryClient}>
    {/* todo: figure out typing for updated Context API */}
    {/* @ts-ignore */}
    <ChatContext.Provider value={useChat({ user, allowNotifications })}>
      {children}
    </ChatContext.Provider>
  </QueryClientProvider>
);
