import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BookIcon,
  BookmarkAddIcon,
  BookmarkIcon,
  CartIcon,
  CheckIcon,
  ChevronIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseCircleIcon,
  CloseIcon,
  CopyIcon,
  DollarIcon,
  EditIcon,
  GiftIcon,
  InfoIcon,
  InfoIconReverse,
  LockIcon,
  MembersIcon,
  MenuIcon,
  MoreIcon,
  PersonBadgeIcon,
  PlayCircleIcon,
  PriceTagIcon,
  PlayIcon,
  SearchIcon,
  ShareIcon,
  StarIcon,
  TickIcon,
  TrashIcon,
  UserIcon,
  UserOutlineIcon,
  AppleIcon,
  GoogleIcon,
  MicrosoftIcon,
} from './variants'
import { IconProps } from './types'

export const getIcon = ({ type, ...nativeProps }: IconProps) => {
  let SVG: React.ElementType

  switch (type) {
    case 'apple':
      SVG = AppleIcon
      break
    case 'google':
      SVG = GoogleIcon
      break
    case 'microsoft':
      SVG = MicrosoftIcon
      break
    case 'arrow-left':
      SVG = ArrowLeftIcon
      break
    case 'arrow-right':
      SVG = ArrowRightIcon
      break
    case 'book':
      SVG = BookIcon
      break
    case 'bookmark-add':
      SVG = BookmarkAddIcon
      break
    case 'bookmark':
      SVG = BookmarkIcon
      break
    case 'cart':
      SVG = CartIcon
      break
    case 'check':
      SVG = CheckIcon
      break
    case 'chevron':
      SVG = ChevronIcon
      break
    case 'chevron-left':
      SVG = ChevronLeftIcon
      break
    case 'chevron-right':
      SVG = ChevronRightIcon
      break
    case 'close':
      SVG = CloseIcon
      break
    case 'close-circle':
      SVG = CloseCircleIcon
      break
    case 'copy':
      SVG = CopyIcon
      break
    case 'dollar':
      SVG = DollarIcon
      break
    case 'edit':
      SVG = EditIcon
      break
    case 'gift':
      SVG = GiftIcon
      break
    case 'info':
      SVG = InfoIcon
      break
    case 'info-reverse':
      SVG = InfoIconReverse
      break
    case 'lock':
      SVG = LockIcon
      break
    case 'members':
      SVG = MembersIcon
      break
    case 'menu':
      SVG = MenuIcon
      break
    case 'more':
      SVG = MoreIcon
      break
    case 'person-badge':
      SVG = PersonBadgeIcon
      break
    case 'play':
      SVG = PlayIcon
      break
    case 'play-circle':
      SVG = PlayCircleIcon
      break
    case 'price-tag':
      SVG = PriceTagIcon
      break
    case 'search':
      SVG = SearchIcon
      break
    case 'share':
      SVG = ShareIcon
      break
    case 'star':
      SVG = StarIcon
      break
    case 'tick':
      SVG = TickIcon
      break
    case 'trash':
      SVG = TrashIcon
      break
    case 'user':
      SVG = UserIcon
      break
    case 'user-outline':
      SVG = UserOutlineIcon
      break
    default:
      SVG = EditIcon
  }

  return <SVG {...nativeProps} />
}
