import { useContext } from 'react';
import pluralize from 'pluralize';
import { FlexBox } from '@fable/components';
import { css, useTheme } from '@fable/theme';
import { ChatContext } from '@fable/chat';
import { Text, Avatar } from '@fluentui/react-northstar';
import { useTeams } from 'hooks/useTeams';

const ChannelTabBannerControlsModerator = () => {
  // TypeScript only throws errors for this in Teams, not in our web app
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { clubDetailsQuery } = useContext(ChatContext);
  const { theme } = useTeams();
  const { mediaQueries } = useTheme();

  const { grey } = theme.siteVariables.colors;
  const club = clubDetailsQuery?.data?.data;

  return (
    <FlexBox
      className={css`
        flex-direction: column;
        gap: 8px;
        flex-wrap: wrap;
        ${mediaQueries.tabletL} {
          flex-direction: row;
          gap: 16px;
        }
      `}
    >
      <FlexBox
        className={css`
          gap: 8px;
          flex-wrap: wrap;
          ${mediaQueries.tabletL} {
            gap: 16px;
          }
        `}
        alignItems="center"
      >
        <Avatar
          size="large"
          image={`${club?.creator?.pic_resize}?w=80`}
          className={css`
            &.ui-avatar {
              display: none;
              ${mediaQueries.tabletL} {
                flex: 0 0 auto;
                display: block;
                flex: 0 0 auto;
              }
            }
          `}
        />
        <div>
          <Text
            className={css`
              white-space: nowrap;
            `}
            size="medium"
          >
            Moderated by
          </Text>
          <FlexBox
            alignItems="center"
            className={css`
              margin-top: 8px;
              gap: 8px;
              ${mediaQueries.tabletL} {
                gap: 16px;
                margin-top: 0;
              }
            `}
          >
            <Avatar
              size="medium"
              image={`${club?.creator?.pic_resize}?w=80`}
              className={css`
                &.ui-avatar {
                  flex: 0 0 auto;
                  ${mediaQueries.tabletL} {
                    display: none;
                  }
                }
              `}
            />
            <Text
              className={css`
                &.ui-text {
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 18px;
                  ${mediaQueries.tabletL} {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              `}
            >
              {club?.creator?.display_name}
            </Text>
          </FlexBox>
        </div>
      </FlexBox>
      <div
        className={css`
          display: flex;
          align-items: center;
          gap: 2px;
          ${mediaQueries.tabletL} {
            display: block;
          }
        `}
      >
        <FlexBox
          className={css`
            > div + div {
              margin-left: -8px;
            }
          `}
        >
          {club?.member_pics_snapshot?.map((pic) => (
            <Avatar key={pic} size="small" image={pic} />
          ))}
        </FlexBox>
        <Text
          className={css`
            &.ui-text {
              white-space: nowrap;
            }
          `}
          color={grey[450]}
          size="small"
        >
          {pluralize('Reader', club?.member_count, true)}
        </Text>
      </div>
    </FlexBox>
  );
};

export default ChannelTabBannerControlsModerator;
