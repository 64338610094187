import { css, useTheme } from '@fable/theme';
import { Text } from '@fable/components';
import { Attachment } from '../chatTypes';

const getSlug = (pathname = '') => {
  return pathname.substring(pathname.lastIndexOf('/') + 1);
};

const renderVideo = (url: string) => {
  let providerUrl;

  if (url.includes('vimeo')) {
    const id = getSlug(url);
    providerUrl = `https://player.vimeo.com/video/${id}`;
  }

  if (url.includes('youtube') || url.includes('youtu.be')) {
    const watchParam = 'watch?v=';
    let id = getSlug(url);

    if (id.includes(watchParam)) {
      id = id.replace(watchParam, '');
    }

    providerUrl = `https://www.youtube.com/embed/${id}?modestbranding=1;showinfo=0;rel=0`;
  }

  return (
    <iframe
      title="video-embed"
      src={providerUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};

const ChatMessageAttachmentLink = ({
  linkData,
}: {
  /** @param {Attachment} linkData - A link-based attachment like hyperlink or youtube video */
  linkData: Attachment;
}) => {
  const { colors } = useTheme();

  return (
    <a
      href={linkData.url}
      target="_blank"
      rel="noreferrer"
      className={css`
        display: block;
        * {
          word-break: break-word;
        }
      `}
    >
      <Text type="body" sizing="S" color={colors.hurricane}>
        {linkData.author}
      </Text>
      <Text type="body" sizing="M" color={colors.denim}>
        <strong>{linkData.title}</strong>
      </Text>
      {linkData.type !== 'video' && (
        <Text type="body" sizing="M">
          <strong>{linkData.description}</strong>
        </Text>
      )}
      <div
        className={css`
          margin-top: 10px;
          iframe {
            width: 100%;
            aspect-ratio: 16 / 9;
            max-width: ${linkData.thumbnail_width}px;
            max-height: ${linkData.thumbnail_height}px;
          }
        `}
      >
        {linkData.type === 'video' && renderVideo(linkData.url)}
        {linkData.type !== 'video' &&
          linkData.thumbnail !==
            'https://cdn.fable.co/chatv2/square1x1.png' && (
            <img
              className={css`
                width: 100%;
              `}
              src={linkData.thumbnail_url}
              alt={linkData.description}
            />
          )}
      </div>
    </a>
  );
};

export default ChatMessageAttachmentLink;
