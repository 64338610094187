import { useRef } from 'react';
import { css, cx, useTheme, FableTheme } from '@fable/theme';
import { useClickOutside } from '@fable/hooks';
import { OptionsMenuItems } from './OptionsMenuItems';
import { OptionsMenuItem, OptionsMenuSecondaryAction } from '../types';

interface Props {
  anchor: 'top' | 'bottom';
  className?: string;
  hover: boolean;
  items: OptionsMenuItem[];
  onClose: () => void;
  open: boolean;
  secondaryAction: OptionsMenuSecondaryAction | undefined;
  width?: string;
  centered: boolean;
}

export const OptionsMenuBox: React.VFC<Props> = ({
  anchor,
  className,
  hover,
  items,
  onClose,
  open,
  secondaryAction,
  width = '200px',
  centered,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme, width, centered);
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => open && onClose());

  const getAnchorPosition = () => {
    if (hover) return;

    switch (anchor) {
      case 'top':
        return styles.top;
      case 'bottom':
        return styles.bottom;
    }
  };

  return (
    <div
      className={cx(
        styles.container,
        open && styles.open,
        getAnchorPosition(),
        hover && styles.hover,
        className,
        'fds-ui--dropdown-box'
      )}
      ref={ref}
      onClick={onClose}
    >
      <div className={cx(styles.content, 'fds-ui--dropdown-box-content')}>
        {hover && (
          <span
            className={cx(styles.chevron, centered && styles.chevronCentered)}
          />
        )}
        <OptionsMenuItems
          className={
            centered
              ? css`
                  text-align: center;
                `
              : ''
          }
          items={items}
          secondaryAction={secondaryAction}
        />
      </div>
    </div>
  );
};

const getStyles = (
  { colors, motion }: FableTheme,
  width: string,
  centered: boolean
) => ({
  container: css`
    position: absolute;
    right: ${centered ? 'auto' : 0};
    left: ${centered ? '50%' : 'auto'};
    transform: translateX(${centered ? 50 : 0}%);
    z-index: 10;
    opacity: 0;
    pointer-events: none;
  `,
  content: css`
    padding: 8px 0;
    width: ${width};
    border-radius: 8px;
    border: 1px solid ${colors.whiteSmoke};
    background-color: ${colors.whiteFang};
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    position: relative;
  `,
  open: css`
    opacity: 1;
    pointer-events: auto;
  `,
  top: css`
    top: 0;
  `,
  bottom: css`
    bottom: 0;
  `,
  hover: css`
    transform: ${centered ? 'translate(-50%, 10px)' : 'translateY(10px)'};
    transition: transform ease ${motion.defaultSpeed},
      opacity ease ${motion.defaultSpeed};

    &:hover {
      opacity: 1;
      pointer-events: auto;
      transform: ${centered ? 'translate(-50%, 0)' : 'translateY(0)'};
    }
  `,
  chevron: css`
    display: block;
    top: -7px;
    right: 18px;
    background: ${colors.whiteFang};
    border-top-left-radius: 2px;
    border: 1px solid ${colors.whiteSmoke};
    border-bottom: transparent;
    border-right: transparent;
    width: 12px;
    height: 12px;
    position: absolute;
    transform: rotate(45deg);
  `,
  chevronCentered: css`
    top: -2.5px;
    left: 50%;
    right: auto;
    transform: rotate(45deg) translateX(-50%);
  `,
});
