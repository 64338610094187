import { createContext, useContext, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { ChatContext } from '@fable/chat';
import { Club } from '@fable/types';
import { useTeams } from 'hooks/useTeams';
import { defaultQueryOptions, getClub } from 'utils/query';
import useNavigation from 'hooks/useNavigation';

export interface ClubContextTypes {
  showChat: boolean;
  setShowChat: ({
    show,
    callback,
  }: {
    show: boolean;
    callback?: () => void;
  }) => void;
  tenantClub: Club | undefined;
  tenantClubQuery: UseQueryResult<{ data: Club }, unknown> | null;
  milestoneId: string;
  setMilestoneId: (s: string) => void;
  refetchAll: () => Promise<void>;
}

const useClubContext = () => {
  const [milestoneId, setMilestoneId] = useState('');
  const [showChatBoolean, setShowChatBoolean] = useState(false);
  const { context } = useTeams();
  const { urlClubSlug } = useNavigation();
  const { clubDetailsQuery, milestoneDiscussionsQuery, chatQueryClient } =
    // TypeScript only throws errors for this in Teams, not in our web app
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useContext(ChatContext);

  const { tid = '', channelId = '' } = context || {};

  const tenantClubQuery = useQuery(
    ['tenantClub', tid, channelId],
    async () => getClub({ tid, channelId }),
    {
      ...defaultQueryOptions,
      retry: 1,
      // If the club slug does exist in the URL, fetch the club information and add it to the current path
      enabled: !!tid && !!channelId && !urlClubSlug,
    }
  );

  const tenantClub = tenantClubQuery?.data?.data as Club;

  const setShowChat = ({
    show,
    callback,
  }: {
    show: boolean;
    callback?: () => any;
  }) => {
    setShowChatBoolean(show);

    if (callback) callback();
  };

  const refetchAll = async () => {
    await clubDetailsQuery?.refetch();
    await chatQueryClient?.invalidateQueries();
    await milestoneDiscussionsQuery?.refetch();
  };

  return {
    showChat: showChatBoolean,
    setShowChat,
    tenantClub,
    tenantClubQuery,
    milestoneId,
    setMilestoneId,
    refetchAll,
  };
};

export const ClubContext = createContext<ClubContextTypes>({
  showChat: false,
  setShowChat: () => null,
  tenantClub: undefined,
  tenantClubQuery: null,
  milestoneId: '',
  setMilestoneId: () => null,
  refetchAll: () => Promise.resolve(),
});

export const ClubContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <ClubContext.Provider value={useClubContext()}>
    {children}
  </ClubContext.Provider>
);
