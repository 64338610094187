import cloneDeep from 'lodash/cloneDeep';
import { get, patch, post, remove } from '@fable/api';
import { Reaction } from '@fable/types';
import {
  MessagesGet,
  Message,
  MessagesQueryPage,
  MessagePost,
  MessagePostBody,
  MessageDelete,
  ReactionRequest,
} from '../chatTypes';

export const camelToSnakeCase = (key: string) =>
  key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const appendMessage = ({
  cache,
  newMessage,
}: {
  cache: any;
  newMessage: Message;
}) => {
  const pagesModified = cloneDeep(cache.pages || []);

  if (
    pagesModified[0].next === 'pending' ||
    pagesModified[0].results?.length < 20
  ) {
    pagesModified[0].results = [newMessage, ...pagesModified[0].results];

    return {
      ...cache,
      pages: pagesModified,
    };
  }

  if (pagesModified[0].results?.length === 20) {
    return {
      ...cache,
      pages: [
        {
          // Set next as pending because this value comes from the Rest API call which is not fetched on every message
          next: 'pending',
          results: [newMessage],
        },
        ...pagesModified,
      ],
    };
  }

  return cache;
};

export const hasMessage = ({
  cache,
  newMessage,
}: {
  cache: any;
  newMessage: Message;
}) =>
  cache?.pages?.some((page: MessagesQueryPage) =>
    page.results.some((result: Message) => result.id === newMessage.id)
  );

export const updateMessage = ({
  cache,
  newMessage,
}: {
  cache: any;
  newMessage: Message;
}) => {
  /**
   * This seems to be the only way to overwrite an existing message in React Query
   * If we have to maintain a single source of truth, and that source of truth is
   * the cache created by React Query's useInfiniteQuery, and have to use optimistic updates (i.e. the message already exists)
   * then we're unfortunately left with having to traverse nested arrays of an unknown number of pages.
   * This could be a lot if one was so inclined to scroll that much.
   * https://github.com/tannerlinsley/react-query/discussions/848#discussioncomment-473919
   * https://github.com/tannerlinsley/react-query/discussions/3360
   */
  const pagesModified = cloneDeep(cache.pages).map(
    (page: MessagesQueryPage) => ({
      ...page,
      results: page.results.map((oldMessage) =>
        oldMessage.id === newMessage.id ? newMessage : oldMessage
      ),
    })
  );

  return {
    ...cache,
    pages: pagesModified,
  };
};

export const removeMessage = ({
  message,
  cache,
}: {
  message: Message;
  cache: any;
}) => {
  const pagesModified = cloneDeep(cache.pages).map(
    (page: MessagesQueryPage) => ({
      ...page,
      results: page.results.filter((x) => x.id !== message.id),
    })
  );

  return {
    ...cache,
    pages: pagesModified,
  };
};

export const updateReaction = ({
  reaction,
  type,
  cache,
}: {
  reaction: Reaction;
  type: 'reaction.created' | 'reaction.deleted';
  cache: any;
}) => {
  const modifyReaction = (message: Message) => {
    const messageUpdated = cloneDeep(message);
    const hasReaction = !!messageUpdated?.reactions?.find(
      (oldReaction) => oldReaction.content === reaction.content
    );

    switch (type) {
      case 'reaction.created':
        if (hasReaction) {
          messageUpdated.reactions = messageUpdated?.reactions?.map(
            (oldReaction) =>
              // Replace the old emoji object with the new one
              oldReaction.content === reaction.content
                ? { ...oldReaction, ...reaction }
                : oldReaction
          );
        } else {
          messageUpdated.reactions = [
            ...(messageUpdated.reactions || []),
            reaction,
          ];
        }

        break;
      case 'reaction.deleted':
        messageUpdated.reactions = messageUpdated?.reactions
          ?.map((oldReaction) =>
            // Replace the old emoji object with the new one
            oldReaction.content === reaction.content
              ? { ...oldReaction, ...reaction }
              : oldReaction
          )
          .filter((oldReaction) => oldReaction.count > 0);

        break;
    }

    return messageUpdated;
  };

  const pagesModified = cloneDeep(cache.pages).map(
    (page: MessagesQueryPage) => ({
      ...page,
      results: page.results.map((message) =>
        message.id === reaction.message_id ? modifyReaction(message) : message
      ),
    })
  );

  return {
    ...cache,
    pages: pagesModified,
  };
};

export const milestoneDiscussionsGet: any = async ({
  clubId,
  clubBookId,
}: {
  clubId: string;
  clubBookId: string;
}) =>
  await get(
    `/v2/clubs/${clubId}/club_books/${clubBookId}/milestone_discussions`
  );

export const milestonesGet: any = async ({
  clubId,
  clubBookId,
}: {
  clubId: string;
  clubBookId: string;
}) => await get(`/clubs/${clubId}/club_books/${clubBookId}/milestones`);

export const messagesGet = async ({
  currentRoomId,
  pageParam,
  threadParentId,
  threadParentType,
  threadParentContentId,
  clubId,
  clubBookId,
}: MessagesGet) => {
  const { data } = await get(
    `/clubs/${clubId}/club_books/${clubBookId}/messages?room_id=${currentRoomId}` +
      `${pageParam ? `&cursor=${pageParam}` : ''}` +
      `${
        threadParentId
          ? `&thread_parent_id=${
              threadParentType !== 'message'
                ? threadParentContentId
                : threadParentId
            }&thread_parent_type=${threadParentType}`
          : ''
      }`
  );

  return data;
};

export const messageSingleGet: any = async ({
  clubBookId,
  clubId,
  messageId,
}: {
  clubBookId: string;
  clubId: string;
  messageId: string;
}) =>
  await get(`/clubs/${clubId}/club_books/${clubBookId}/messages/${messageId}`);

export const messagePost: any = async (params: MessagePost) => {
  const body = new FormData();

  for (const key in params.body) {
    const data = params.body[key as keyof MessagePostBody];

    if (data !== undefined && data !== null) {
      body.append(camelToSnakeCase(key), data as string | Blob);
    }
  }

  return await post(
    `/clubs/${params.clubId}/club_books/${params.clubBookId}/messages`,
    body
  );
};

export const messageDelete: any = async (params: MessageDelete) =>
  await remove(
    `/clubs/${params.clubId}/club_books/${params.clubBookId}/messages/${params.id}`
  );

export const clubMembersGet: any = async (clubId: string) =>
  await get(`/v2/clubs/${clubId}/members`);

export const clubDetailsGet: any = async (slug: string) =>
  await get(`/v2/clubs/slug/${slug}`);

export const clubBookGet: any = async ({
  clubId,
  clubBookId,
}: {
  clubId: string;
  clubBookId: string;
}) => await get(`clubs/${clubId}/club_books/${clubBookId}`);

export const pollGet: any = async ({
  clubId,
  pollId,
}: {
  clubId: string;
  pollId: string;
}) => await get(`clubs/${clubId}/polls/${pollId}`);

export const pollSubmitAnswer: any = async ({
  clubId,
  pollId,
  choiceId,
}: {
  clubId: string;
  pollId: string;
  choiceId: string;
}) =>
  await post(`clubs/${clubId}/polls/${pollId}/vote`, { choice_id: choiceId });

export const reactionDelete: any = async ({
  messageId,
  emoji,
}: ReactionRequest) =>
  await remove(`/messages/${messageId}/reactions/${emoji}`);

export const reactionPost: any = async ({
  messageId,
  emoji,
}: ReactionRequest) =>
  await post(`/messages/${messageId}/reactions/`, { content: emoji });

export const markRoomAsRead: any = async ({ roomId }: { roomId: string }) =>
  roomId ? await patch(`/rooms/${roomId}/read_status`) : null;

let timeout: any;

export const debounce = (fn: Function, time: number) => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    fn();
  }, time);
};

export const isUrl = (string: string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getInfiniteQueryParam = (lastPage: {
  next: URL | 'pending' | undefined;
}) => {
  if (!lastPage.next) return;

  try {
    const nextUrl = new URL(lastPage.next);
    const params = new URLSearchParams(nextUrl.search);
    const nextCursor = params.get('cursor');

    return nextCursor;
  } catch {
    return lastPage.next;
  }
};

// For use in Virtuoso
export const flattenPages = (pages: { results: any[] }[]) =>
  pages.reduce((acc: any, curr: any) => [...acc, ...curr.results], []);
