import { css, cx, useTheme } from '@fable/theme';
import FlexBox from './FlexBox';
import getInputTheme from './utils/getInputTheme';
import TextInputLabel from './TextInputLabel';
import { TextInputProps } from './types';

const TextInput: React.VFC<TextInputProps> = ({
  buttonPosition,
  onChangeText,
  theme = 'light',
  label,
  error = false,
  buttonContent = null,
  onClickButton,
  buttonDisabled,
  children,
  inputRef,
  containerClass,
  ...nativeProps
}) => {
  const { fonts, colors, nativeOverrides } = useTheme();
  const {
    background,
    borderColor,
    focusBorderColor,
    textColor,
    placeholderColor,
  } = getInputTheme(theme);
  const showButton = !!onClickButton || !!buttonContent;

  const renderButton = () => (
    <button
      className={cx(nativeOverrides.buttonClear)}
      onClick={onClickButton}
      disabled={buttonDisabled}
      type="submit"
    >
      <FlexBox
        centerAll
        className={css`
          height: 100%;
          width: 100%;
        `}
      >
        {buttonContent}
      </FlexBox>
    </button>
  );

  const getPadding = () => {
    if (showButton) {
      if (buttonContent === 'right') return '0 6px 0 14px';
      return '0 0 0 14px';
    }
    return '0 14px';
  };

  /**
   * !important can be removed once global input style is removed
   */

  return (
    <FlexBox
      alignItems="center"
      justifyContent="space-between"
      className={cx(
        'text-input',
        css`
          column-gap: 5px;
          height: 52px;
          border-radius: 44px;
          border: 1px solid ${error ? colors.error : borderColor};
          background: ${background};
          &:focus-within {
            border: 1px solid ${error ? colors.error : focusBorderColor} !important;
          }
        `,
        containerClass
      )}
    >
      {showButton && buttonPosition === 'left' && renderButton()}
      <FlexBox
        justifyContent="center"
        flexDirection="column"
        className={css`
          flex: 1 1 auto;
          overflow: hidden;
          height: 100%;
          width: 100%;
          border-radius: inherit;
        `}
      >
        {!!label && !nativeProps.value && (
          <TextInputLabel theme={theme}>{label}</TextInputLabel>
        )}
        {/* styles are forced with important until Field.scss is removed (it's setting input styles with !important) */}
        <input
          {...nativeProps}
          ref={inputRef}
          className={css`
            margin: 0 !important;
            padding: ${getPadding()} !important;
            width: 100% !important;
            height: fit-content !important;
            background: transparent !important;
            border: none !important;
            border-radius: 0 !important;
            color: ${textColor} !important;
            font-family: ${fonts.body} !important;
            font-size: 14px !important;
            line-height: 14px !important;
            letter-spacing: -0.25px !important;
            box-shadow: none !important;
            flex: 1 1 auto;
            text-overflow: ellipsis;
            &::placeholder {
              color: ${placeholderColor} !important;
            }
            &:disabled {
              background: none !important;
            }
          `}
          data-testid="textInput"
          onChange={(e) => (onChangeText ? onChangeText(e.target.value) : null)}
        />
      </FlexBox>
      {children}
      {showButton && buttonPosition === 'right' && renderButton()}
    </FlexBox>
  );
};

export default TextInput;
