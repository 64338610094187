import { cx, css, useTheme } from '@fable/theme';
import { usePreloadImage } from '@fable/hooks';
import React, { useState } from 'react';
import Loader from './Loader';
import { LoaderType } from './types';
import Tag from './Tag';
import getRandom from './utils/getRandom';
import bookCoverGreen from './assets/book_covers/default-book-cover-green.png';
import bookCoverPink from './assets/book_covers/default-book-cover-pink.png';
import bookCoverWhite from './assets/book_covers/default-book-cover-white.png';

export const BOOK_COVER_PLACEHOLDERS = [
  bookCoverGreen,
  bookCoverPink,
  bookCoverWhite,
];

interface Props {
  className?: string;
  image: string;
  altTag?: string;
  size?: number;
  onLoad?: (e: React.SyntheticEvent<HTMLImageElement>) => void;
  tagTitle?: string | null;
}

const BookImage: React.FC<Props> = ({
  className = '',
  image,
  altTag,
  size = 360,
  onLoad,
  tagTitle = null,
}) => {
  const { colors } = useTheme();
  // prevent infinite loop (e.g. prerender.io blocks all image resources)
  const [defaultImage] = useState(getRandom(BOOK_COVER_PLACEHOLDERS));
  const [loaded, setLoaded] = useState(false);
  const preloadedImage = usePreloadImage(image, size);
  const imageSource = image ? preloadedImage?.src : defaultImage;

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setLoaded(true);
    onLoad && onLoad(e);
  };

  const styles = {
    image: css`
      display: block;
      width: 100%;
      opacity: ${loaded ? 1 : 0};
      transition: opacity ease 0.2s;
      border-radius: inherit;
    `,
    icon: css`
      max-width: 50%;
      max-height: 50%;
      width: 100%;
      height: 100%;

      svg {
        width: 100% !important;
        height: 100% !important;
      }
    `,
  };

  const loader = !loaded && (
    <Loader
      iconClass={styles.icon}
      isLight={true}
      type={LoaderType.contained}
    />
  );

  return (
    <div
      className={cx(
        css`
          position: relative;
          border-radius: 4px;
          background-color: ${!loaded ? colors.whiteSmoke : 'transparent'};
        `,
        className,
        'book-cover'
      )}
    >
      {loader}
      <img
        data-testid="bookCover"
        className={styles.image}
        src={imageSource}
        alt={altTag}
        onLoad={onImageLoad}
      />
      {!tagTitle ? null : (
        <Tag
          className={css`
            position: absolute;
            top: 4px;
            left: 4px;
            box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.06);
          `}
          title={tagTitle}
          backgroundColor={colors.whiteFang}
        />
      )}
    </div>
  );
};

export default BookImage;
