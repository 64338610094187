import { IconSVGProps } from '../types';

export const CheckIcon: React.VFC<IconSVGProps> = ({ className, stroke }) => (
  <svg
    className={className}
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
  >
    <path
      d="M2 6.77673L6.20552 10.698C6.3065 10.7921 6.4647 10.7866 6.55886 10.6856L14.5718 2.09172"
      stroke={stroke}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
