import { Text } from '@fluentui/react-northstar';
import { useTeams } from 'hooks/useTeams';

const FormLabel = ({ label }: { label: string }) => {
  const { theme } = useTeams();

  return (
    <Text
      styles={{ marginBottom: '8px', display: 'block' }}
      size="small"
      weight="semibold"
      color={theme.siteVariables.colors.grey[450]}
    >
      {label}
    </Text>
  );
};

export default FormLabel;
