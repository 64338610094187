import { css, cx, useTheme } from '@fable/theme';
import { Button, Text } from '@fable/components';

const ChatMemberMention = ({
  mentioner,
}: {
  mentioner?: { display_name: string; id: string };
}) => {
  const { motion, colors } = useTheme();

  const handleClickMention = (e: any, id?: string) => {
    e.stopPropagation();
    e.preventDefault();

    if (id) {
      window.open(
        `http://${
          process.env.ENVIRONMENT !== 'production' ? 'staging' : ''
        }.fable.co/${id}`,
        '_blank'
      );
    }
  };

  return (
    <Button
      transparent
      onClick={(e: React.MouseEvent<HTMLElement>) =>
        handleClickMention(e, mentioner?.id)
      }
      className={css`
        width: fit-content;
        height: fit-content;
        display: inline;
        &:hover {
          transform: scale(1.03);
          ${motion.transition('transform')};
        }
      `}
    >
      <Text
        className={cx(
          css`
            font-weight: 500;
            background: ${colors.mayaBlueLight};
          `,
          'mention'
        )}
        type="body"
        sizing="M"
        color={colors.blue}
      >
        @{mentioner?.display_name}
      </Text>
    </Button>
  );
};

export default ChatMemberMention;
