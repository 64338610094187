import { IconSVGProps } from '../types';

export const CopyIcon: React.VFC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill={fill}
  >
    <g clipPath="url(#clip0_690_2832)">
      <path d="M5.75 4.5V2.25C5.75 2.05109 5.82902 1.86032 5.96967 1.71967C6.11032 1.57902 6.30109 1.5 6.5 1.5H15.5C15.6989 1.5 15.8897 1.57902 16.0303 1.71967C16.171 1.86032 16.25 2.05109 16.25 2.25V12.75C16.25 12.9489 16.171 13.1397 16.0303 13.2803C15.8897 13.421 15.6989 13.5 15.5 13.5H13.25V15.75C13.25 16.164 12.9125 16.5 12.4948 16.5H3.50525C3.40635 16.5006 3.3083 16.4816 3.21674 16.4442C3.12519 16.4068 3.04192 16.3517 2.97174 16.282C2.90156 16.2123 2.84584 16.1294 2.80779 16.0381C2.76974 15.9468 2.7501 15.8489 2.75 15.75L2.75225 5.25C2.75225 4.836 3.08975 4.5 3.5075 4.5H5.75ZM7.25 4.5H13.25V12H14.75V3H7.25V4.5Z" />
    </g>
    <defs>
      <clipPath id="clip0_690_2832">
        <rect width="18" height="18" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
