const chatMessageAttachmentThemes = {
  theme1: { backgroundColor: '#064C37', textColor: '#CB8E9E' },
  theme2: { backgroundColor: '#C19C4E', textColor: '#161015' },
  theme3: { backgroundColor: '#0F5EAE', textColor: '#161015' },
  theme4: { backgroundColor: '#BE5D18', textColor: '#161015' },
  theme5: { backgroundColor: '#CB8E9E', textColor: '#064C37' },
  theme6: { backgroundColor: '#161015', textColor: '#CB8E9E' },
};

export default chatMessageAttachmentThemes;
