// https://www.figma.com/file/4yGCCzj6ebONAguqLtZFTr/Web-store_Consolidated?node-id=3%3A1259
import { cx, css, useTheme } from '@fable/theme';
import Logo from './logo/Logo';
import Icon from './icon';
import { ButtonProps } from './types';

const Button: React.VFC<ButtonProps> = ({
  children,
  className,
  dataTestId,
  destructive,
  disabled,
  height,
  icon,
  id,
  label,
  loading,
  mini,
  negative,
  onClick,
  outline,
  secondary,
  small,
  translucent,
  transparent,
  width,
  inverted,
  custom,
  title,
  type,
}) => {
  const { colors, motion } = useTheme();

  const primaryStyles = {
    default: css`
      color: ${negative ? colors.blackSwan : colors.whiteFang};
      background: ${negative ? colors.whiteFang : colors.uiGraphite};
      border: ${`2px solid ${negative ? colors.whiteFang : colors.uiGraphite}`};
    `,
    hover: css`
      color: ${negative ? colors.blackSwanHover : colors.whiteFang};
      border-color: ${negative ? colors.almostWhite : colors.uiGraphiteLight};
      background: ${negative ? colors.almostWhite : colors.uiGraphiteLight};
    `,
    disabled: css`
      color: ${negative ? colors.greyMedium : colors.whiteFang};
      border-color: ${negative ? colors.lightGrey : colors.uiPewterGreyLight};
      background: ${negative ? colors.lightGrey : colors.uiPewterGreyLight};
    `,
  };

  const secondaryStyles = {
    default: css`
      color: ${negative ? colors.whiteFang : colors.blackSwan};
      border: ${`2px solid ${negative ? colors.lightGrey : colors.blackSwan}`};
      background: ${negative ? colors.lightGrey : colors.whiteFang};
    `,
    hover: css`
      color: ${negative ? colors.almostWhite : colors.blackSwanHover};
      border-color: ${negative ? colors.whiteSmoke : colors.greyStrong};
      background: ${negative ? colors.whiteSmoke : colors.whiteFang};
    `,
    disabled: css`
      color: ${negative ? colors.lightGrey : colors.greyMedium};
      border-color: ${negative ? colors.lightGrey : colors.greyMedium};
      background: ${negative ? colors.lightGrey : colors.whiteFang};
    `,
  };

  const outlineStyles = {
    default: css`
      color: ${negative ? colors.whiteFang : colors.blackSwan};
      border: ${`2px solid ${negative ? colors.whiteFang : colors.blackSwan}`};
      background: none;
    `,
    hover: css`
      color: ${negative ? colors.whiteSmoke : colors.graphite};
      border-color: ${negative ? colors.whiteSmoke : colors.graphite};
      background: none;
    `,
    disabled: css`
      color: ${negative ? colors.greyNeutral : colors.greyMedium};
      border-color: ${negative ? colors.greyNeutral : colors.greyMedium};
      background: none;
    `,
  };

  const invertedStyles = {
    default: css`
      color: ${colors.blackSwan};
      border: ${`2px solid ${colors.pinkMoon}`};
      background: ${colors.pinkMoon};
    `,
    hover: css`
      color: ${colors.blackSwan};
      background: ${colors.pinkMoonMedium};
      border: ${`2px solid ${colors.pinkMoonMedium}`};
    `,
    disabled: css`
      color: ${colors.greyNeutral};
      border-color: ${colors.pinkMoonLight};
      background: ${colors.pinkMoonLight};
    `,
  };

  const destructiveStyles = {
    default: css`
      color: ${colors.whiteFang};
      border-color: ${colors.error};
      background: ${colors.mahogany};
    `,
    hover: css`
      color: ${colors.whiteFang};
      border-color: ${colors.errorHover};
      background: ${colors.mahogany};
    `,
    disabled: css`
      color: ${colors.whiteFang};
      border-color: ${colors.errorDisabled};
      background: ${colors.mahogany};
    `,
  };

  const loadingStyles = {
    default: css`
      color: ${colors.almostWhite};
      border-color: ${colors.lightGrey};
      background: ${colors.lightGrey};
    `,
    hover: css`
      color: ${colors.almostWhite};
      border-color: ${colors.lightGrey};
      background: ${colors.lightGrey};
    `,
    disabled: css`
      color: ${colors.almostWhite};
      border-color: ${colors.lightGrey};
      background: ${colors.lightGrey};
    `,
  };

  const translucentStyles = css`
    border-color: transparent !important;
    background: ${colors.blackSwan}20;

    &:hover {
      background: ${colors.blackSwan}40;
    }
  `;

  const transparentStyles = css`
    border-color: transparent;
    background: transparent;
    &:hover {
      border-color: transparent;
      background: transparent;
    }
  `;

  const styleObject = () => {
    if (secondary) return secondaryStyles;
    if (outline) return outlineStyles;
    if (inverted) return invertedStyles;
    if (destructive) return destructiveStyles;
    return primaryStyles;
  };

  const getHeight = () => {
    if (mini) return '28px';
    if (small) return '32px';
    return height || '44px';
  };

  const getLabelAttributes = () => {
    let fontSize = '14px';
    if (mini) {
      fontSize = '10px';
    }
    if (small) {
      fontSize = '12px';
    }

    return { fontSize };
  };

  const styles = {
    container: css`
      text-align: center;
      width: ${width || '247px'};
      height: ${getHeight()};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
      padding: 0;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      letter-spacing: -0.25px;
      font-weight: 600;
      border-radius: 60px;
      border-style: solid;
      .button-label {
        font-family: inherit;
        font-weight: inherit;
        font-size: ${getLabelAttributes().fontSize};
        letter-spacing: -0.25px;
        color: inherit;
      }

      ${loading ? loadingStyles : styleObject().default}
      &:hover {
        ${loading ? loadingStyles : styleObject().hover}
      }
      &:disabled {
        ${loading ? loadingStyles : styleObject().disabled}
        pointer-events: none;
      }
      ${translucent && translucentStyles}
      ${transparent && transparentStyles}
    `,
    icon: css`
      width: 18px;
      path {
        ${motion.transition('fill')}
      }
    `,
    withIconText: css`
      margin-left: ${!children && !label ? 0 : 8}px;
    `,
  };

  const buttonContent = () => {
    if (loading) {
      return (
        <Logo
          animate
          isIcon
          className={css`
            height: 20px;
            svg {
              height: 20px !important;
            }
          `}
        />
      );
    }

    const content = children ? (
      children
    ) : (
      <span className={cx('button-label', icon ? styles.withIconText : '')}>
        {label}
      </span>
    );

    if (icon) {
      const iconPrimaryFill = () => {
        if (disabled) {
          return negative ? colors.greyMedium : colors.whiteFang;
        }

        return negative ? colors.blackSwan : colors.whiteFang;
      };

      const iconSecondaryFill = () => {
        if (disabled) {
          return negative ? colors.greyNeutral : colors.greyMedium;
        }

        return negative ? colors.whiteFang : colors.blackSwan;
      };

      return (
        <>
          <Icon
            className={styles.icon}
            type={icon}
            fill={
              secondary || outline ? iconSecondaryFill() : iconPrimaryFill()
            }
          />
          {content}
        </>
      );
    }

    return content;
  };

  return (
    <button
      id={id}
      className={cx(
        styles.container,
        custom
          ? css`
              ${custom};
            `
          : '',
        className
      )}
      onClick={onClick}
      data-testid={dataTestId}
      disabled={disabled || loading}
      title={title}
      type={type}
    >
      {buttonContent()}
    </button>
  );
};

export default Button;
