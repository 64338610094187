import { IconSVGProps } from '../types';

export const ShareIcon: React.FC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33333 9.16634C3.79357 9.16634 4.16667 9.53944 4.16667 9.99967V16.6663C4.16667 16.8874 4.25446 17.0993 4.41074 17.2556C4.56702 17.4119 4.77899 17.4997 5 17.4997H15C15.221 17.4997 15.433 17.4119 15.5893 17.2556C15.7455 17.0993 15.8333 16.8874 15.8333 16.6663V9.99967C15.8333 9.53944 16.2064 9.16634 16.6667 9.16634C17.1269 9.16634 17.5 9.53944 17.5 9.99967V16.6663C17.5 17.3294 17.2366 17.9653 16.7678 18.4341C16.2989 18.9029 15.663 19.1663 15 19.1663H5C4.33696 19.1663 3.70107 18.9029 3.23223 18.4341C2.76339 17.9653 2.5 17.3294 2.5 16.6663V9.99967C2.5 9.53944 2.8731 9.16634 3.33333 9.16634Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.41074 1.07709C9.73618 0.751649 10.2638 0.751649 10.5893 1.07709L13.9226 4.41042C14.248 4.73586 14.248 5.26349 13.9226 5.58893C13.5972 5.91437 13.0695 5.91437 12.7441 5.58893L10 2.84485L7.25592 5.58893C6.93049 5.91437 6.40285 5.91437 6.07741 5.58893C5.75197 5.26349 5.75197 4.73586 6.07741 4.41042L9.41074 1.07709Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0.833008C10.4602 0.833008 10.8333 1.2061 10.8333 1.66634V12.4997C10.8333 12.9599 10.4602 13.333 10 13.333C9.53976 13.333 9.16667 12.9599 9.16667 12.4997V1.66634C9.16667 1.2061 9.53976 0.833008 10 0.833008Z"
    />
  </svg>
);
