import { css, cx, useTheme } from '@fable/theme';

const ReviewStarsHiddenButton = ({
  valueWhenHovered,
  left,
  right,
  readOnly,
  hoveredStar,
  setHoveredStar,
  onClickStar,
}: {
  hoveredStar: number;
  valueWhenHovered: number;
  left?: boolean;
  right?: boolean;
  readOnly?: boolean;
  setHoveredStar: (valueWhenHovered: number) => void;
  onClickStar?: (hoveredStar: number) => void;
}) => {
  const { nativeOverrides } = useTheme();

  return (
    <button
      disabled={readOnly === true}
      className={cx(
        nativeOverrides.buttonClear,
        css`
          position: absolute;
          top: 0;
          width: 50%;
          height: 100%;
          opacity: 0;
        `,
        left &&
          css`
            left: 0;
          `,
        right &&
          css`
            right: 0;
          `
      )}
      onMouseEnter={
        readOnly === true ? undefined : () => setHoveredStar(valueWhenHovered)
      }
      onClick={!!onClickStar ? () => onClickStar(hoveredStar) : undefined}
    />
  );
};

export default ReviewStarsHiddenButton;
