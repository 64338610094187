import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useMachine } from '@xstate/react'
import { Providers, ProviderState, SimpleProvider } from '@microsoft/mgt'
import { Button, Loader, Provider } from '@fluentui/react-northstar'
import { css, useTheme } from '@fable/theme'
import { IdentityType, TeamsFx } from '@microsoft/teamsfx'
import { firebaseAuth, get, setAuthToken } from '@fable/api'
import { ChatContextProvider } from '@fable/chat'
import { useQuery } from 'react-query'
import merge from 'lodash/merge'
import {
  getFirebaseUser$,
  linkToFirebaseToMicrosoft,
  scopes,
  signInWithFirebaseCustomToken,
  waitForAccountLinked,
} from './Firebase'
import { machine, MachineContext } from './App.Machine'
import ChannelTab from './ChannelTab'
import PersonalTabHome from './PersonalTabHome'
import ClubSetting from './ClubSetting'
import { defaultQueryOptions, getCustomToken, getProfile } from 'utils/query'
import { TeamsThemeContext } from 'utils/TeamsThemeContex'
import { useTeams } from 'hooks/useTeams'
import { ClubContextProvider } from 'utils/ClubContext'
import ClubBookTask from 'components/club_book_task/ClubBookTask'
import ClubBookTaskDetail from 'components/club_book_task/ClubBookTaskDetail'
import MilestoneTask from 'components/milestone_task/MilestoneTask'
import ScheduleMeetingTask from 'components/schedule_meeting_task/ScheduleMeetingTask'
import SignInTask from 'components/sign_in_task/SignInTask'
import ConnectToFableTask from 'components/sign_in_task/ConnectToFableTask'
import { useMachineLogger } from './hooks/useMachineLogger'

const teamsFx = new TeamsFx(IdentityType.User)

const App = () => {
  const { colors } = useTheme()
  const {
    context: teamsContext,
    theme,
    themeString,
    inTeams,
    error: teamsError,
  } = useTeams()

  const userQuery = useQuery('user', async () => await getProfile(), {
    ...defaultQueryOptions,
  })

  const [state, send, service] = useMachine(machine, {
    services: {
      fetchMicrosoftAccessToken: async () => {
        // Request all scopes so that the user is prompted for consent if necessary, but we don't actually use it,
        // since we don't make any graph calls in the client.
        await teamsFx.getCredential().getToken(scopes)
        // What we send the backend is the "SSO Token" which getToken() will return if no scopes are passed in.
        // This is a completely different token, retrieved via `microsoftTeams.authentication.getAuthToken` rather than
        // by the MSAL library.  The SSO Token can be passed to the backend and fed to
        // `ConfidentialClientApplication.acquire_token_on_behalf_of` - only these special SSO tokens, where the `aud`
        // claim is set to the Teams app ID, can be used for OBO.
        return await teamsFx.getCredential().getToken([])
      },
      signInWithMicrosoft: () => teamsFx.login(scopes),
      fetchCustomToken: async (ctx: MachineContext) => {
        if (!ctx.accessToken) {
          throw new Error('Expected access token')
        }
        return await getCustomToken(ctx.accessToken)
      },
      getFirebaseUser: getFirebaseUser$,
      signInWithFirebase: (ctx: MachineContext) => {
        if (!ctx.customToken) {
          throw new Error('Expected customToken')
        }
        return signInWithFirebaseCustomToken(ctx.customToken)
      },
      fetchFableUser: async () => {
        const result = await userQuery.refetch()
        if (result.error) {
          throw result.error
        }
        return result.data
      },
      linkMicrosoftAndFirebase: async () => {
        if (!firebaseAuth.currentUser) {
          throw new Error('Missing Firebase user')
        }
        if (teamsContext?.hostClientType === 'web') {
          await linkToFirebaseToMicrosoft(firebaseAuth.currentUser)
        } else {
          const result = await get('/auth/token')
          const accountLinkingUrl = new URL(
            `/link-to-microsoft/?token=${result.data.auth_token}`,
            window.location.href
          ).href
          window.open(accountLinkingUrl)
          await waitForAccountLinked()
        }
      },
    },
    actions: {
      updateAxiosToken: (ctx: MachineContext) => {
        if (!ctx.idToken) {
          throw new Error('Expected idToken')
        }
        setAuthToken(ctx.idToken)
      },
      // Required to get the PeoplePicker to
      // https://stackoverflow.com/questions/62090255/people-picker-from-microsoft-graph-api-toolkit-is-not-working#comment109893871_62091117
      updateMgtProviderToken: (ctx: MachineContext) => {
        if (!ctx.accessToken) {
          throw new Error('Expected accessToken')
        }
        // https://stackoverflow.com/a/60873345

        Providers.globalProvider = new SimpleProvider(
          () => new Promise((resolve) => resolve(`${ctx.accessToken}`))
        )

        Providers.globalProvider.setState(ProviderState.SignedIn)
      },
    },
  })

  useMachineLogger(service)

  useEffect(() => {
    if (inTeams && state.matches('idle')) {
      send('START')
    }
  }, [inTeams, state, send])

  const error = state.context.error || teamsError

  if (error) {
    return (
      <div
        className={css`
          max-width: 400px;
          margin: 20px auto;
          text-align: center;
        `}
      >
        <h1>Something went wrong</h1>
        <h3>Detailed error message:</h3>
        <p>{error.message}</p>
        <Button content="Try again" onClick={() => send({ type: 'RETRY' })} />
      </div>
    )
  }

  if (state.matches('promptForMicrosoftInteractiveAuth')) {
    return <SignInTask onSignInClick={() => send('SIGN_IN_WITH_MICROSOFT')} />
  } else if (state.matches('promptForAccountLink')) {
    return (
      <ConnectToFableTask
        onConnectClick={() => send('LINK_MICROSOFT_AND_FIREBASE')}
      />
    )
  }

  const user = userQuery?.data?.data

  const customTheme = merge(theme, {
    siteVariables: merge(theme.siteVariables, {
      noHover: {
        ':hover': {
          backgroundColor: 'no',
          boxShadow: 'no',
          borderColor: 'no',
        },
      },
      colorScheme: merge(theme.siteVariables.colorScheme, {
        brand: {
          ...theme.siteVariables.colorScheme.brand,
          borderFocus1: colors.fableGreen,
        },
      }),
      colors: merge(theme.siteVariables.colors, {
        // 210 and 435 do not exist but the design requires these colors
        grey: {
          ...theme.siteVariables.colors.grey,
          210: '#eeeeee',
          435: '#686868',
        },
      }),
    }),
  })

  const { grey } = customTheme.siteVariables.colors

  return (
    <TeamsThemeContext.Provider value={{ theme: customTheme, themeString }}>
      <Provider
        className={css`
          input.ui-box,
          textarea.ui-textarea {
            &::placeholder {
              color: ${grey[400]};
              opacity: 1;
            }
            &::-ms-input-placeholder {
              color: ${grey[400]};
            }
            &::-ms-input-placeholder {
              color: ${grey[400]};
            }
          }
          // Temporarily copying styles from other MS components since there's no
          // time picker component. There might be one in an updated library
          // When/if updating the library, swap this out for theirs if there is one
          input[type='time'] {
            width: 100%;
            background: rgb(243, 242, 241);
            border-color: transparent;
            color: rgb(37, 36, 35);
            border-radius: 4px;
            padding: 0.3125rem 0.75rem;
            height: 32px;
            cursor: pointer;
            &:focus {
              outline: none;
              border-bottom-color: ${colors.fableGreen};
            }
          }
          .ui-datepicker {
            position: relative;
            width: 100%;
            .ui-box {
              width: 100%;
              input {
                width: 100%;
              }
            }
            button {
              position: absolute;
              right: 0;
              box-shadow: none;
              outline: none;
              background: transparent;
              border: none;
              top: 0;
              z-index: 1;
            }
          }
        `}
        theme={customTheme}
        styles={{
          height: '100%',
          backgroundColor:
            teamsContext?.frameContext === 'task' ? 'white' : grey[210],
        }}
      >
        {state.matches('authenticated') ? (
          <ChatContextProvider user={user}>
            <ClubContextProvider>
              <Routes>
                <Route
                  path="/club-setting"
                  element={
                    <ClubSetting
                      postAuthCalled={!!state.context.customToken}
                      accessToken={state.context.accessToken}
                    />
                  }
                />
                <Route path="/club/*" element={<ChannelTab />} />
                <Route path="/add-book" element={<ClubBookTask />} />
                <Route path="/add-book/:id" element={<ClubBookTaskDetail />} />
                <Route path="/create-milestone" element={<MilestoneTask />} />
                <Route
                  path="/schedule-meeting"
                  element={<ScheduleMeetingTask />}
                />
                {/* comment out until UI is ready */}
                {/* <Route path="/create-milestone/manual" >
                    <MilestoneTaskManual />
                  </Route> */}
                <Route
                  path="/tabs/personal"
                  element={<PersonalTabHome user={user} />}
                />
              </Routes>
            </ClubContextProvider>
          </ChatContextProvider>
        ) : (
          <Loader color="#eeeeee" style={{ height: '100vh', width: '100%' }} />
        )}
      </Provider>
    </TeamsThemeContext.Provider>
  )
}

export default App
