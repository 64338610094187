import { useEffect, useState } from 'react'
import { Book } from '@fable/types'
import { Link } from 'react-router-dom'
import { css, cx, useTheme } from '@fable/theme'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
} from '@fluentui/react-northstar'

const FolioBookCarousel = ({ books }: { books: Book[] }) => {
  const { mediaQueries } = useTheme()
  const [refExists, setRefExists] = useState(false)

  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      spacing: 21,
      perView: 'auto',
    },
    loop: true,
    mode: 'snap',
    rtl: false,
  })

  useEffect(() => {
    if (instanceRef.current && !refExists) {
      setRefExists(true)
    }
  }, [instanceRef, refExists])

  return (
    <>
      <Button
        text
        iconOnly
        icon={<ArrowLeftIcon />}
        onClick={instanceRef.current?.prev}
      />
      <div ref={sliderRef} className="keen-slider">
        {books.map((book) => (
          <Link
            className={cx(
              'keen-slider__slide',
              css`
                max-width: 77px;
                min-width: 77px;
                ${mediaQueries.mobile} {
                  max-width: 107px;
                  min-width: 107px;
                }
              `
            )}
            key={book.id}
            to={`/add-book/${book.isbn}`}
          >
            <img
              data-testid="folioBook"
              className={css`
                height: 122px;
                width: 77px;
                ${mediaQueries.mobile} {
                  height: 172px;
                  width: 107px;
                }
              `}
              src={`${book.cover_image_resize}?w=214`}
              alt={`Cover for ${book.title}`}
            />
          </Link>
        ))}
      </div>
      <Button
        text
        iconOnly
        icon={<ArrowRightIcon />}
        onClick={instanceRef.current?.next}
      />
    </>
  )
}

export default FolioBookCarousel
