import { useContext } from 'react';
import { css, useTheme } from '@fable/theme';
import { Button, BookReview, BookReviewModal } from '@fable/components';
import { User, Book } from '@fable/types';
import { ChatContext } from '../chat_context';
import { AttachmentReview } from '../chatTypes';

const ChatMessageAttachmentReview = ({
  review,
  messageAuthor,
}: {
  /** @param {AttachmentReview} review - A review from the chat message attachments array. It's reformatted here to work with the BookReview component */
  review: AttachmentReview;
  /** @param {User} messageAuthor - The user who sent the message */
  messageAuthor: User;
}) => {
  const { clubBookQuery, setModal } = useContext(ChatContext);

  const { colors } = useTheme();

  const bookDetails = clubBookQuery?.data?.data;

  const reviewFormatted = {
    id: review.review_uuid,
    user: messageAuthor,
    book: bookDetails?.book as Book,
    rating: review.rating,
    review: review.review || '',
    emoji_reaction: review.emoji_reaction,
    labels: review.labels,
    created_at: review.created_at,
    updated_at: review.updated_at,
    public: false,
  };

  const setShowFullReview = () => {
    setModal({
      show: true,
      component: (
        <div
          className={css`
            position: relative;
            border-radius: 12px;
            background: ${colors.whiteFang};
            margin-top: 50px;
          `}
        >
          <Button
            onClick={() => setModal({ show: false })}
            icon="close"
            negative
            className={css`
              position: absolute;
              right: 10px;
              top: 10px;
              width: 45px;
            `}
          />
          <BookReviewModal
            showGiftBookButton={false}
            onCopyToClipboard={() => null}
            review={reviewFormatted}
          />
        </div>
      ),
    });
  };

  return (
    <div
      className={css`
        width: 100%;
        cursor: pointer;
        > div > div + div {
          // The rule is that if a review is a preview, it should have a white background
          // if it's not a preview it should have a grey background
          // In this case it's a preview with a grey background
          background: ${colors.almostWhite};
        }
      `}
      onClick={setShowFullReview}
    >
      <BookReview
        review={reviewFormatted}
        isPreview={true}
        showGiftBookButton={false}
        onCopyToClipboard={() => null}
      />
    </div>
  );
};

export default ChatMessageAttachmentReview;
