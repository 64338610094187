import { IconSVGProps } from '../types';

export const PlayCircleIcon: React.FC<IconSVGProps> = ({ className, fill }) => (
  <svg
    className={className}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill={fill}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5026 2.5026C6.36047 2.5026 3.0026 5.86047 3.0026 10.0026C3.0026 14.1447 6.36047 17.5026 10.5026 17.5026C14.6447 17.5026 18.0026 14.1447 18.0026 10.0026C18.0026 5.86047 14.6447 2.5026 10.5026 2.5026ZM1.33594 10.0026C1.33594 4.93999 5.43999 0.835938 10.5026 0.835938C15.5652 0.835938 19.6693 4.93999 19.6693 10.0026C19.6693 15.0652 15.5652 19.1693 10.5026 19.1693C5.43999 19.1693 1.33594 15.0652 1.33594 10.0026Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.44012 5.93454C8.71108 5.78953 9.03987 5.80542 9.29558 5.9759L14.2956 9.30923C14.5274 9.46379 14.6667 9.72398 14.6667 10.0026C14.6667 10.2812 14.5274 10.5414 14.2956 10.696L9.29558 14.0293C9.03987 14.1998 8.71108 14.2157 8.44012 14.0707C8.16915 13.9257 8 13.6433 8 13.3359V6.66927C8 6.36194 8.16915 6.07956 8.44012 5.93454ZM9.66667 8.22637V11.7788L12.331 10.0026L9.66667 8.22637Z"
    />
  </svg>
);
