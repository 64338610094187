import { ThemeModes, theme } from '@fable/theme';

const getInputTheme = (mode: ThemeModes) => {
  switch (mode) {
    case 'grey':
      return {
        background: theme.colors.pewterGreyLight,
        borderColor: theme.colors.pewterGreyLight,
        focusBorderColor: theme.colors.pewterGreyLight,
        textColor: theme.colors.blackSwan,
        placeholderColor: theme.colors.graphiteLight,
        labelColor: theme.colors.graphiteLight,
      };
    case 'light':
      return {
        background: theme.colors.whiteFang,
        borderColor: theme.colors.greyNeutral,
        focusBorderColor: theme.colors.graphite,
        textColor: theme.colors.blackSwan,
        placeholderColor: theme.colors.hurricane,
        labelColor: theme.colors.hurricane,
      };
    case 'dark':
      return {
        background: theme.colors.fableGreenDarker,
        borderColor: theme.colors.fableGreenDarker,
        focusBorderColor: theme.colors.hurricane,
        textColor: theme.colors.whiteFang,
        placeholderColor: theme.colors.hurricane,
        labelColor: theme.colors.greyNeutral,
      };
    case 'night':
      return {
        background: theme.colors.graphite,
        borderColor: theme.colors.graphite,
        focusBorderColor: theme.colors.hurricane,
        textColor: theme.colors.whiteFang,
        placeholderColor: theme.colors.hurricane,
        labelColor: theme.colors.greyNeutral,
      };
  }
};

export default getInputTheme;
