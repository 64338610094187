import { TextStyleProps, TextStyleMapping } from '../types';

const baseStyles: TextStyleProps = {
  fontFamily: `'Inter', sans-serif`,
  fontWeight: 400,
};

const styles: TextStyleMapping = {
  XL: {
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: ' -0.3px',
  },
  L: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: ' -0.3px',
  },
  M: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: ' -0.2px',
  },
  S: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: ' -0.2px',
  },
  XS: {
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: ' -0.2px',
  },
};

export const bodyStyles = { styles, baseStyles };
