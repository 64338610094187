import { useContext } from 'react'
import pluralize from 'pluralize'
import getDate from 'date-fns/getDate'
import format from 'date-fns/format'
import { FlexBox } from '@fable/components'
import {
  ChatContext,
  MilestoneDiscussion,
  Participant,
  Room,
} from '@fable/chat'
import { css, cx, useTheme } from '@fable/theme'
import {
  Avatar,
  ChatIcon,
  ChevronEndIcon,
  List,
  Text,
} from '@fluentui/react-northstar'
import { useTeams } from 'hooks/useTeams'
import calendarReverse from 'assets/icons/calendar-reverse.svg'
import book from 'assets/icons/open-book-white.svg'
import ChannelTabDiscussionsRoomsList from './ChannelTabDiscussionsRoomsList'
import { ClubContext } from 'utils/ClubContext'
import useNavigation from 'hooks/useNavigation'

interface MilestoneDiscussionMod extends MilestoneDiscussion {
  isLobby?: boolean
  participants?: Participant[]
  room_id?: string
}

const ChannelTabDiscussionsNavDiscussions = () => {
  const { theme } = useTeams()
  const { colors } = useTheme()
  const { updatePath } = useNavigation()
  const { setShowChat, showChat } = useContext(ClubContext)
  const { milestoneDiscussionsQuery, currentRoom, closeThread, enterRoom } =
    // TypeScript only throws errors for this in Teams, not in our web app
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useContext(ChatContext)

  const lobby = milestoneDiscussionsQuery?.data?.data?.lobby

  const milestoneDiscussions =
    milestoneDiscussionsQuery?.data?.data?.milestones || []

  const hasParticipants = (milestone: MilestoneDiscussionMod) =>
    milestone?.rooms?.some((room: Room) => room.participants?.length > 0)

  const hasRooms = (milestone: MilestoneDiscussionMod) =>
    !milestone?.isLobby &&
    milestone?.type !== 'start' &&
    milestone?.type !== 'finish'

  // This click handler is for rooms like "Kickoff" that are not nested
  // Nested room click events are in ChannelTabDiscussionsRoomList
  // Because both components use Microsoft's List component which has a
  // onSelectedIndexChange event for handling clicks
  const handleSelectNonNestedRoom = (milestone: MilestoneDiscussionMod) => {
    const room = milestone?.isLobby
      ? (milestone as unknown as Room)
      : milestone?.rooms[0]

    updatePath({ milestone, roomTitle: room?.title })

    setShowChat({
      show: true,
      callback: () => {
        closeThread()
        enterRoom({
          room,
        })
      },
    })
  }

  const renderParticipants = (participants: Participant[]) =>
    participants.map((participant: Participant) => (
      <Avatar
        data-testid="participant"
        key={participant.participant_id}
        size="small"
        image={participant.pic}
      />
    ))

  return (
    <List
      className={css`
        > li {
          &.clickable {
            cursor: pointer;
            &:hover {
              background: rgb(243, 242, 241);
            }
          }
          + li {
            border-top: 1px solid ${theme.siteVariables.colors.grey[150]};
          }
          .ui-list__itemheadermedia {
            align-self: flex-start;
          }
        }
      `}
      items={[lobby, ...milestoneDiscussions]?.map(
        (milestone: MilestoneDiscussionMod, i: number) => ({
          className: cx(
            milestone?.isLobby ||
              milestone?.type === 'start' ||
              milestone?.type === 'finish'
              ? 'clickable'
              : '',
            showChat &&
              !hasRooms(milestone) &&
              currentRoom?.room_id &&
              (currentRoom.room_id === milestone?.room_id ||
                currentRoom.room_id === (milestone?.rooms || [])[0]?.room_id)
              ? css`
                  background: rgb(243, 242, 241);
                `
              : ''
          ),
          key: milestone?.isLobby
            ? `${milestone?.room_id}-${i}`
            : `${milestone?.milestone_id}-${i}`,
          header: (
            <FlexBox
              alignItems="center"
              className={css`
                gap: 8px;
                padding: 8px 0;
              `}
              onClick={() =>
                !hasRooms(milestone)
                  ? handleSelectNonNestedRoom(milestone)
                  : undefined
              }
            >
              <div
                className={css`
                  min-width: 120px;
                `}
              >
                <FlexBox
                  alignItems="center"
                  className={css`
                    padding: 7px 5px;
                    border-radius: 4px;
                    background-color: ${colors.fableGreen};
                    color: white;
                    gap: 8px;
                    height: fit-content;
                    width: fit-content;
                  `}
                >
                  <img
                    className={css`
                      height: 12px;
                    `}
                    src={book}
                    alt="an open book"
                  />
                  <Text
                    className={css`
                      &.ui-text {
                        white-space: nowrap;
                      }
                    `}
                    size="small"
                    color="white"
                  >
                    {milestone?.isLobby ? 'Club Lobby' : `Milestone ${i}`}
                  </Text>
                </FlexBox>
              </div>
              <div>
                <Text
                  size="medium"
                  weight="semibold"
                  color={theme.siteVariables.colors.grey[435]}
                >
                  {milestone?.title}
                </Text>
                {milestone?.date && (
                  <FlexBox
                    className={css`
                      margin-top: 2px;
                    `}
                  >
                    <img
                      className={css`
                        margin-right: 7px;
                      `}
                      src={calendarReverse}
                      alt="calendar"
                    />
                    <Text
                      size="small"
                      color={theme.siteVariables.colors.grey[500]}
                    >
                      {format(new Date(milestone?.date), 'MMM')}
                      &nbsp;
                      {getDate(new Date(milestone?.date))}
                    </Text>
                  </FlexBox>
                )}
              </div>
            </FlexBox>
          ),
          headerMedia: (
            <FlexBox
              onClick={() =>
                !hasRooms(milestone)
                  ? handleSelectNonNestedRoom(milestone)
                  : undefined
              }
              alignItems="center"
            >
              {(milestone?.isLobby && milestone?.participants?.length) ||
              (!milestone?.isLobby && hasParticipants(milestone)) ? (
                <FlexBox
                  className={css`
                    padding-right: 11px;
                    margin-right: 11px;
                    border-right: 1px solid #e0e0e0;
                    > div + div {
                      margin-left: -8px;
                    }
                  `}
                >
                  {renderParticipants(
                    milestone?.isLobby
                      ? (milestone?.participants as Participant[])
                      : (milestone?.rooms.flatMap(
                          (room) => room.participants
                        ) as Participant[])
                  )}
                </FlexBox>
              ) : null}
              <FlexBox
                alignItems="center"
                justifyContent="flex-end"
                className={css`
                  height: 35px;
                `}
              >
                {!hasRooms(milestone) && milestone?.unread_count ? (
                  <Text
                    size="small"
                    className={css`
                      margin-right: 10px;
                    `}
                  >
                    {pluralize('new post', milestone?.unread_count, true)}
                  </Text>
                ) : null}
                <FlexBox alignItems="center">
                  <ChatIcon
                    outline
                    className={css`
                      margin-right: 5px;
                    `}
                  />
                  <Text size="small">{milestone?.total_messages}</Text>
                  <ChevronEndIcon
                    className={css`
                      height: 10px;
                      svg {
                        height: 10px;
                      }
                    `}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          ),
          content: hasRooms(milestone) ? (
            <ChannelTabDiscussionsRoomsList milestone={milestone} />
          ) : null,
        })
      )}
    />
  )
}

export default ChannelTabDiscussionsNavDiscussions
