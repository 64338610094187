import { Book, Review } from '@fable/types'
import { ChatContext } from '@fable/chat'
import { formatBookDescription } from '@fable/utils'
import { Emoji, FlexBox, ReviewStars } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { tasks } from '@microsoft/teams-js'
import { Text } from '@fluentui/react-northstar'
import getYear from 'date-fns/getYear'
import { useContext } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  addBookToClub,
  defaultQueryOptions,
  getBookDetail,
  getBookReviews,
} from 'utils/query'
import ClubBookTaskDetailSection from './ClubBookTaskDetailSection'
import TaskBody from 'components/task/TaskBody'
import BookDetailBanner from 'components/BookDetailBanner'

const ClubBookTaskDetail = () => {
  const { mediaQueries } = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  // TypeScript only throws errors for this in Teams, not in our web app
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { clubDetailsQuery } = useContext(ChatContext)

  const club = clubDetailsQuery?.data?.data
  const isbn = location.pathname.split('/')[2]

  const bookDetail = useQuery(
    ['bookDetail', isbn],
    async () => getBookDetail(isbn),
    { ...defaultQueryOptions, enabled: true }
  )

  const book = bookDetail?.data?.data?.response as Book

  const bookReviews = useQuery(
    ['reviews', book?.id],
    () => getBookReviews(book?.id || ''),
    { enabled: !!book?.id && !!book.review_count }
  )

  const addBook = useMutation(
    'addBook',
    async () => await addBookToClub({ club, book })
  )

  const reviews = bookReviews?.data?.data

  // This is copied from apps/main
  // move this to a shared utils package
  const formatRating = (rating: number) => {
    const dec = rating % 1
    if (dec < 0.5) {
      const result = rating - dec
      return result.toFixed(1)
    }
    return rating - dec + 0.5
  }

  const handleClickAddToClub = async () => {
    try {
      await addBook.mutateAsync()
      tasks.submitTask()
    } catch (error) {
      console.error(error)
    }
  }

  if (!book) return null

  const loading = addBook.isLoading

  return (
    <TaskBody
      buttons={[
        {
          type: 'secondary',
          title: 'Back',
          onClick: () => navigate(-1),
          disabled: loading,
        },
        {
          type: 'primary',
          title: 'Add to club',
          onClick: handleClickAddToClub,
          loading,
          disabled: loading,
        },
      ]}
    >
      <div
        className={css`
          flex: 1 1 auto;
          overflow-y: auto;
        `}
      >
        <BookDetailBanner
          book={book}
          className={css`
            padding: 0 min(30px, 9.73%);
            height: 179px;
            margin: 16px 0;
            ${mediaQueries.mobile} {
              padding: 0 9.73%;
              height: 260px;
            }
          `}
          bookStyles={css`
            height: 194px;
            ${mediaQueries.mobile} {
              height: 287px;
            }
          `}
          header={book.title}
          description={`Written by ${book.authors[0].name}`}
        />
        <div
          className={css`
            padding: 0 17px;
            ${mediaQueries.mobile} {
              padding: 0 27px;
            }
          `}
        >
          <FlexBox
            justifyContent="space-between"
            flexWrap="wrap"
            className={css`
              width: 100%;
              margin-bottom: 12px;
              gap: 10px;
            `}
          >
            <ClubBookTaskDetailSection
              header="Category"
              body={
                <>
                  <div>
                    {book.subjects.length
                      ? book.subjects[0].map((string) => (
                          <Text
                            styles={{ display: 'block' }}
                            key={string}
                            size="small"
                          >
                            {string}
                          </Text>
                        ))
                      : null}
                  </div>
                </>
              }
            />
            <ClubBookTaskDetailSection
              header="Released"
              body={`${getYear(new Date(book.published_date))}`}
            />
            <ClubBookTaskDetailSection
              header="Rating"
              body={
                book.review_average ? (
                  <ReviewStars
                    readOnly
                    color="#212121"
                    rating={book.review_average}
                  />
                ) : (
                  ''
                )
              }
            />
          </FlexBox>
          <div
            className={css`
              margin-bottom: 12px;
            `}
          >
            <ClubBookTaskDetailSection
              header="Description"
              body={formatBookDescription(
                book.fable_summary || book.description
              )}
            />
          </div>
          {book.review_count ? (
            <div
              className={css`
                margin-bottom: 12px;
              `}
            >
              <ClubBookTaskDetailSection
                header="Reviews"
                body={
                  <>
                    {reviews?.map((review: Review) => (
                      <div
                        key={review.id}
                        className={css`
                          margin: 6px 0;
                        `}
                      >
                        <FlexBox
                          alignItems="center"
                          className={css`
                            column-gap: 5px;
                          `}
                        >
                          {review.rating && (
                            <Text size="small" weight="bold">
                              {formatRating(review.rating)}
                            </Text>
                          )}
                          {review.emoji_reaction && (
                            <Emoji emoji={review.emoji_reaction} size={14} />
                          )}
                        </FlexBox>
                        <Text size="medium">{review.review}</Text>
                      </div>
                    ))}
                  </>
                }
              />
            </div>
          ) : null}
        </div>
      </div>
    </TaskBody>
  )
}

export default ClubBookTaskDetail
