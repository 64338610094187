import { assign, createMachine, DoneInvokeEvent } from 'xstate';
import { errorAssign, isErrorWithCode } from '../../utils/machine';
import { accountIsLinked, FirebaseUserUpdateEvent } from '../../Firebase';

export interface MachineContext {
  error?: Error;
  customToken?: string;
}

export type RetryEvent = { type: 'RETRY' };
export type LinkSuccessEvent = { type: 'LINK_SUCCESS' };

export type MachineEvent =
  | FirebaseUserUpdateEvent
  | RetryEvent
  | LinkSuccessEvent;

function errorIsProviderAlreadyLinked(
  ctx: MachineContext,
  event: DoneInvokeEvent<Error>
) {
  return (
    isErrorWithCode(event.data) &&
    event.data.code === 'auth/provider-already-linked'
  );
}

export const machine = createMachine({
  predictableActionArguments: true,
  strict: true,
  id: 'linkToMicrosoft',
  initial: 'fetchingCustomToken',
  schema: {
    context: {} as MachineContext,
    events: {} as MachineEvent,
  },
  context: {},
  states: {
    fetchingCustomToken: {
      invoke: {
        src: 'getCustomToken',
        onError: {
          target: 'error',
          actions: [errorAssign],
        },
        onDone: {
          target: 'signingInWithFirebase',
          actions: [
            assign<MachineContext, DoneInvokeEvent<string>>({
              customToken: (_, event) => event.data,
            }),
          ],
        },
      },
    },
    signingInWithFirebase: {
      invoke: {
        src: 'signInWithFirebase',
        onError: {
          target: 'error',
          actions: [errorAssign],
        },
        onDone: {
          target: 'linkingToMicrosoft',
          actions: [
            assign<MachineContext, DoneInvokeEvent<string>>({
              customToken: (_, event) => event.data,
            }),
          ],
        },
      },
    },
    linkingToMicrosoft: {
      invoke: {
        src: 'linkWithRedirect',
        onDone: {
          target: 'checkingAccountLinked',
        },
        onError: [
          {
            target: 'success',
            cond: errorIsProviderAlreadyLinked,
          },
          {
            target: 'error',
            actions: [errorAssign],
          },
        ],
      },
    },
    checkingAccountLinked: {
      always: [
        {
          target: 'success',
          cond: accountIsLinked,
        },
        {
          target: 'error',
        },
      ],
    },
    success: {},
    error: {},
  },
});
