import { IconSVGProps } from '../types';

export const ChevronRightIcon: React.VFC<IconSVGProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    data-testid="chevron-right-icon"
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill={fill}
  >
    <path d="M3.17202 4.99999L0.343018 2.17199L1.75702 0.756989L6.00002 4.99999L1.75702 9.24299L0.343018 7.82799L3.17202 4.99999Z" />
  </svg>
);
