import * as ReactDOMServer from 'react-dom/server';
import { User } from '@fable/types';
import { Mention } from '../chatTypes';
import {
  charCodeIsSpace,
  recursivelyParseNodes,
  replaceAtCaret,
} from './contentEditableUtils';

export interface MentionLinkArgs {
  display_name: string;
  // A mention without an ID will not be counted, but rendering it allows it to be edited
  id?: string;
}

// Ex: Hey <@123-421321ASdfds-asfdsafsd-afdadfas> what's up?;
export const clubMemberMentionRegex = /<@(.*?)>/i;

// Replaces plain text with a span containing the text
export const insertMentionLink = ({
  userInfo,
}: {
  userInfo: MentionLinkArgs;
}) => {
  const selection = window.getSelection();
  const node = selection?.focusNode;
  // const activeNode = node.firstChild || node;
  const textContent = node?.textContent;
  const start = textContent?.lastIndexOf('@');
  const hasStart = start !== undefined && start > -1;
  const prevCharCode =
    start !== undefined ? textContent?.charCodeAt(start - 1) : null;
  const nextCharCode =
    start !== undefined ? textContent?.charCodeAt(start + 1) : null;
  // A valid mention has a space before @ and no space after @
  const isValidMention =
    prevCharCode !== null &&
    !charCodeIsSpace(nextCharCode) &&
    (charCodeIsSpace(prevCharCode) || Number.isNaN(prevCharCode));
  const mention = textContent?.substring(textContent.lastIndexOf('@'));

  if (mention && textContent && hasStart && isValidMention) {
    replaceAtCaret({
      node,
      start,
      end: start + mention.length,
      content: userInfo?.display_name ? `@${userInfo?.display_name}` : mention,
      element: {
        tag: 'span',
        attributes: {
          // Setting contentEditable to false allows deleting a full tag when hitting backspace once set,
          // preventing getting stuck in the tag
          'data-uuid': userInfo?.id || '000',
          // id is added so the tag can be replaced by plain text when editing a mention
          ...(userInfo.id && { id: userInfo.id }),
        },
      },
    });
  }
};

// This can be used to replace a mention in a message that has been received from the server
export const formatMessage = ({
  message,
  users,
}: {
  message: string;
  users: User[];
}) => {
  const matchedUser = (s: string) =>
    users.find(
      (x) =>
        clubMemberMentionRegex.exec(s) &&
        clubMemberMentionRegex.exec(s)![1] === x.id
    );

  return message
    .split(' ')
    .map((s) => {
      if (s === '<!channel>') {
        return ReactDOMServer.renderToStaticMarkup(
          <span className="mention channel-mention" data-uuid="club">
            @club
          </span>
        );
      }
      if (
        clubMemberMentionRegex.exec(s) &&
        clubMemberMentionRegex.exec(s)![1]
      ) {
        const match = matchedUser(s);

        return ReactDOMServer.renderToStaticMarkup(
          <span className="mention" data-uuid={match?.id}>
            @{match?.display_name}
          </span>
        );
      }

      return s;
    })
    .join(' ');
};

export const filterUsersByKeyword = ({
  clubMembers,
  keyword,
}: {
  clubMembers: User[];
  keyword: string;
}) =>
  keyword.length === 0
    ? clubMembers
    : clubMembers.filter((clubMember) => {
        const usernameLc = clubMember.display_name?.toLowerCase() || '';
        const keywordLc = keyword.toLowerCase();
        const split = usernameLc.split(' ');

        if (usernameLc.substring(0, keywordLc.length) === keywordLc) {
          return true;
        }

        for (const partial of split) {
          if (partial.substring(0, keywordLc.length) === keywordLc) {
            return true;
          }
        }

        return false;
      });

export const createMentionsArrayFromString = ({
  clubMembers,
  message,
}: {
  clubMembers: User[];
  message: string;
}) => {
  const clubMembersObj = clubMembers.reduce(
    (obj: { [key: string]: User }, curr) => ({
      ...obj,
      [curr.id as string]: curr,
    }),
    {}
  );

  const mentionIds = message
    .split(' ')
    .filter(
      (x) =>
        clubMemberMentionRegex.exec(x) && clubMemberMentionRegex.exec(x)![1]
    )
    .map((x) => clubMemberMentionRegex.exec(x)![1]);

  return Array.from(new Set(mentionIds)).map((id) => ({
    id,
    display_name: clubMembersObj[id].display_name,
  })) as Mention[];
};

export const formatMessageTextToSend = (htmlString: string) => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;

  recursivelyParseNodes({
    node: div,
    callbacks: {
      span: [
        (node: Node) => {
          const id = (node as Element).id;

          if (id) {
            const replacement =
              node.textContent === '@club' ? `<!${id}>` : `<@${id}>`;

            (node as Element).replaceWith(replacement);
          }
        },
      ],
      div: [
        (node) => {
          const replacement = document.createElement('div');

          replacement.innerHTML = `\r${(node as Element).innerHTML}`;

          (node as Element).replaceWith(replacement);
        },
      ],
      br: [(node) => (node as Element).replaceWith('\n')],
    },
  });

  return div.textContent || '';
};
