import { useEffect, useRef } from 'react';

const useNotifications = (settings?: { disabled: boolean }) => {
  const notificationPermission = useRef('denied');

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
    // Let's check if the browser supports notifications
    if ('Notification' in window && settings?.disabled !== true) {
      const checkNotificationPromise = () => {
        try {
          Notification.requestPermission().then();
        } catch (e) {
          return false;
        }

        return true;
      };

      if (checkNotificationPromise()) {
        Notification.requestPermission().then((permission) => {
          notificationPermission.current = permission;
        });
      } else {
        Notification.requestPermission((permission) => {
          notificationPermission.current = permission;
        });
      }
    }
  }, [settings?.disabled]);

  const notify = (notification: NotificationOptions & { title: string }) => {
    if (notificationPermission.current === 'granted' && !!notification) {
      new Notification(notification.title || '', {
        body: notification.body || '',
        icon: 'https://fable.co/images/email/shapes/fable-icon.png',
      });
    }
  };

  return notify;
};

export default useNotifications;
