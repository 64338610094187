import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Input, Text, TextArea } from '@fluentui/react-northstar';
import { css } from '@fable/theme';
import { settings } from '@microsoft/teams-js';
import { createClub, defaultQueryOptions, getGenres } from 'utils/query';
import { useTeams } from 'hooks/useTeams';
import FormLabel from '../FormLabel';
import useControlledForm from 'hooks/useControlledForm';

const ClubForm = () => {
  const { inTeams, theme, context } = useTeams();
  const { channelId = '' } = context || {};
  const { grey } = theme.siteVariables.colors;

  const { formData, setFormData } = useControlledForm<{
    title: string;
    genre_ids: string[];
    about_us: string;
    tenant_team_channel_id: string;
  }>({
    title: '',
    genre_ids: [''],
    about_us: '',
    tenant_team_channel_id: channelId,
  });

  const genreListQuery = useQuery('genres', async () => await getGenres(), {
    ...defaultQueryOptions,
    enabled: true,
  });

  const genreList = useMemo(
    () => genreListQuery?.data?.data || [],
    [genreListQuery]
  );

  const createClubMutation = useMutation(
    'createClub',
    async () => await createClub(formData)
  );

  const onSaveHandler = useCallback(
    async (saveEvent: settings.SaveEvent) => {
      try {
        await createClubMutation.mutateAsync();
      } catch (error) {
        saveEvent.notifyFailure(`${error}`);
      }

      try {
        saveEvent.notifySuccess();
      } catch (error) {
        saveEvent.notifyFailure(`${error}`);
      }
    },
    [createClubMutation]
  );

  useEffect(() => {
    inTeams && channelId && settings.registerOnSaveHandler(onSaveHandler);
  }, [inTeams, channelId, onSaveHandler]);

  useEffect(() => {
    // Activates MS Teams save button if every value in the formData object has data
    settings.setValidityState(Object.values(formData).every((val) => !!val));
  }, [formData]);

  useEffect(() => {
    // Set tenant_team_channel_id when context is loaded (which seems to happen after component renders)
    if (!!channelId && !formData.tenant_team_channel_id) {
      setFormData({ tenant_team_channel_id: channelId });
    }
  }, [formData.tenant_team_channel_id, channelId, setFormData]);

  useEffect(() => {
    if (!!genreList?.length && !formData.genre_ids[0].length) {
      // Sets the first genre item since the <select> element displays the first one by default
      setFormData({ genre_ids: [genreList[0].id] });
    }
  }, [formData.genre_ids, genreList, setFormData]);

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className={css`
        width: 100%;
        height: 100%;
        background-color: #ffffff;
      `}
    >
      <Text
        styles={{ marginBottom: '46px', fontSize: '20px' }}
        align="center"
        weight="bold"
        size="large"
      >
        You want to read great books with your team?
        <br /> We'll show you how to start a book club!
      </Text>
      <Text
        size="large"
        align="center"
        weight="semibold"
        styles={{ marginBottom: '20px' }}
      >
        Start a new book club by filling out the following:
      </Text>
      <FormLabel label="Club Name" />
      <Input
        styles={{ marginBottom: '20px' }}
        fluid
        placeholder="Name"
        name="name"
        value={formData.title}
        required
        onChange={(e) => {
          const title = (e.target as HTMLInputElement)?.value;

          setFormData({ title });
        }}
      />
      <FormLabel label="Select a genre" />
      <select
        className={css`
          margin-bottom: 20px;
          display: inline-block;
          line-height: 1em;
          width: 100%;
          background-color: ${grey[100]};
          border-width: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: ${grey[450]}
          appearance: none;
          padding: 10px;
        `}
        name="genreId"
        data-testid="genreSelect"
        value={formData.genre_ids[0]}
        onChange={(e) => {
          const id = e.target.value;

          setFormData({ genre_ids: [id] });
        }}
      >
        {genreList.map((genreItem: { name: string; id: string }) => (
          <option value={genreItem.id} key={genreItem.id}>
            {genreItem.name}
          </option>
        ))}
      </select>
      <FormLabel label="Provide description" />
      <TextArea
        styles={{
          marginBottom: '20px',
        }}
        fluid
        placeholder="Type something"
        name="description"
        value={formData.about_us}
        required
        onChange={(e) => {
          const about_us = (e.target as HTMLInputElement)?.value;

          setFormData({ about_us });
        }}
      />
      {process.env.NODE_ENV === 'test' && (
        <button
          data-testid="submitButton"
          type="submit"
          // This is temporary for testing only due to time restraint
          // It needs to be removed when mocks in ClubForm.test.tsx are working
          onClick={async () => {
            // @ts-ignore
            await onSaveHandler({
              notifySuccess: () => null,
              notifyFailure: () => null,
            });
          }}
        />
      )}
    </form>
  );
};

export default ClubForm;
