import { FlexBox } from '@fable/components';
import { css } from '@fable/theme';
import { Skeleton } from '@fluentui/react-northstar';
import range from 'lodash/range';

const formatSkeletonList = () =>
  range(10).map((x) => ({
    key: `${Math.random()}-${x}`,
    content: (
      <Skeleton animation="pulse">
        <FlexBox
          data-testid="skeleton"
          className={css`
            width: 100%;
            padding: 0 30px 18px 30px;
          `}
          alignItems="center"
        >
          <Skeleton.Shape
            styles={{ flex: '1 0 auto', maxWidth: '60px' }}
            height="94px"
          />
          <div
            className={css`
              flex: 1 0 auto;
              margin-left: 17px;
            `}
          >
            <Skeleton.Line
              styles={{ marginBottom: '4px' }}
              width="100%"
              height="17px"
            />
            <Skeleton.Line width="100%" height="11px" />
            <Skeleton.Line width="90%" height="11px" />
            <Skeleton.Line width="60%" height="11px" />
          </div>
        </FlexBox>
      </Skeleton>
    ),
  }));

export default formatSkeletonList;
