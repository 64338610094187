import { useTheme } from '@fable/theme';

interface Props {
  className: string;
  light?: boolean;
}

const FableIcon: React.FC<Props> = ({ className, light }) => {
  const { colors } = useTheme();

  return (
    <svg
      width="44"
      height="24"
      viewBox="0 0 44 24"
      fill="none"
      className={className}
    >
      <path
        d="M12.3554 2.16211L9.63501 3.73259L17.4883 17.333L20.2087 15.7625L12.3554 2.16211Z"
        fill={light ? colors.whiteFang : colors.pinkMoon}
      />
      <path
        d="M3.7313 9.63281L2.16064 12.3529L15.7625 20.2053L17.3332 17.4852L3.7313 9.63281Z"
        fill={light ? colors.whiteFang : colors.mayaBlue}
      />
      <path
        d="M15.7066 20.416H0V23.557H15.7066V20.416Z"
        fill={light ? colors.whiteFang : colors.mahogany}
      />
      <path
        d="M43.979 20.416H28.2725V23.557H43.979V20.416Z"
        fill={light ? colors.whiteFang : colors.denim}
      />
      <path
        d="M40.2458 9.63477L26.6438 17.4872L28.2144 20.2073L41.8164 12.3549L40.2458 9.63477Z"
        fill={light ? colors.whiteFang : colors.lavenderBlush}
      />
      <path
        d="M31.6246 2.16211L23.7712 15.7625L26.4916 17.333L34.3448 3.7326L31.6246 2.16211Z"
        fill={light ? colors.whiteFang : colors.saffron}
      />
      <path
        d="M23.5603 0H20.4189V15.7048H23.5603V0Z"
        fill={light ? colors.whiteFang : colors.shamrock}
      />
    </svg>
  );
};

export default FableIcon;
