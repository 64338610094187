import { get } from '../fable';

const getClubSuggestion = async (bookId: string | undefined) =>
  await get(`/books/${bookId}/club_suggestion`);

const getClubsByGenre = async (ids: string) =>
  await get(`/public_clubs?genre_ids=${ids}`);

export const club = {
  getClubSuggestion,
  getClubsByGenre,
};
