import { IconSVGProps } from '../types';

export const UserOutlineIcon: React.FC<IconSVGProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
  >
    <circle
      cx="5"
      cy="4.25"
      r="1.6"
      stroke={fill}
      strokeOpacity="0.8"
      strokeWidth="0.8"
    />
    <path
      d="M8 10C8 8.89543 6.65685 8 5 8C3.34315 8 2 8.89543 2 10"
      stroke={fill}
      strokeOpacity="0.8"
      strokeWidth="0.8"
    />
  </svg>
);
