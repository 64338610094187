import { QuoteThemeKey } from '@fable/types';
import { QuoteTheme } from './themeTypes';

const QUOTE_THEME: Record<QuoteThemeKey, QuoteTheme> = {
  theme1: {
    name: 'theme1',
    backgroundColor: 'fableGreen',
    bookTitleColor: 'whiteFang',
    authorColor: 'pinkMoon',
    quoteColor: 'pinkMoon',
  },
  theme2: {
    name: 'theme2',
    backgroundColor: 'hazelnut',
    bookTitleColor: 'whiteFang',
    authorColor: 'blackSwan',
    quoteColor: 'blackSwan',
  },
  theme3: {
    name: 'theme3',
    backgroundColor: 'denim',
    bookTitleColor: 'whiteFang',
    authorColor: 'blackSwan',
    quoteColor: 'blackSwan',
  },
  theme4: {
    name: 'theme4',
    backgroundColor: 'orange',
    bookTitleColor: 'whiteFang',
    authorColor: 'blackSwan',
    quoteColor: 'blackSwan',
  },
  theme5: {
    name: 'theme5',
    backgroundColor: 'pinkMoon',
    bookTitleColor: 'whiteFang',
    authorColor: 'blackSwan',
    quoteColor: 'fableGreen',
  },
  theme6: {
    name: 'theme5',
    backgroundColor: 'blackSwan',
    bookTitleColor: 'whiteFang',
    authorColor: 'pinkMoonDark',
    quoteColor: 'pinkMoonDark',
  },
};

export default QUOTE_THEME;
