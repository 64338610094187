import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileObject } from '@fable/types';
import { resizeImage } from '@fable/utils';

// https://github.com/react-dropzone/react-dropzone

const DragAndDropFileUpload: React.FC<
  {
    onDragComponent: React.ReactNode;
    placeHolderComponent: React.ReactNode;
    onUpload: ({ render, fileData }: FileObject) => void;
  } & React.HTMLProps<HTMLDivElement>
> = ({ onDragComponent, placeHolderComponent, onUpload, ...nativeProps }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles?.length) {
        const reader = new FileReader();

        reader.onabort = () => console.error('file reading was aborted');
        reader.onerror = () => console.error('file reading has failed');
        reader.onload = async (e) => {
          if (e.target?.result) {
            // render is a bas64 string for rendering the image
            // fileData is the raw data for sending to the backend
            const render = (await resizeImage(
              acceptedFiles[0],
              'base64'
            )) as string;
            const fileData = (await resizeImage(
              acceptedFiles[0],
              'file'
            )) as File;

            onUpload({
              render,
              fileData,
            });
          }
        };
        reader.readAsDataURL(acceptedFiles[0]);
      }
    },
    [onUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} {...nativeProps}>
      <input {...getInputProps()} disabled={nativeProps.disabled} />
      {isDragActive ? onDragComponent : placeHolderComponent}
    </div>
  );
};

export default DragAndDropFileUpload;
