import { useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { post } from '@fable/api'
import {
  BookImage,
  FlexBox,
  Logo,
  Modal,
  PhoneNumberField,
} from '@fable/components'
import { useClickOutside } from '@fable/hooks'
import { css, cx, useTheme } from '@fable/theme'
import { Book } from '@fable/types'
import {
  Button,
  Card,
  CheckmarkCircleIcon,
  CloseIcon,
  OpenOutsideIcon,
  Text,
} from '@fluentui/react-northstar'
import { useTeams } from 'hooks/useTeams'
import { AxiosError } from 'axios'

const ChannelTabBookModal = ({
  book,
  closeModal,
}: {
  book: Book
  closeModal: () => void
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { colors, nativeOverrides } = useTheme()
  const [success, setSuccess] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState({ code: '', number: '' })
  const { theme } = useTeams()

  const addFreeBook = useMutation(
    'addFreeBook',
    async () => await post('/v2/books/owned/', { book_id: book.id })
  )

  const sendSms = useMutation(
    'sendSms',
    async () =>
      await post('/sms_messages/', {
        book_id: book.id,
        phone: phoneNumber.code + phoneNumber.number,
        notification_type: 'read_book',
      })
  )

  const handleClickSend = async () => {
    if (book.is_free) await addFreeBook.mutateAsync()
    await sendSms.mutateAsync()

    setSuccess(true)
  }

  const loading = addFreeBook.isLoading || sendSms.isLoading
  const error = (addFreeBook.error || sendSms.error) as AxiosError

  useClickOutside(ref, closeModal)

  return (
    <Modal onClose={closeModal}>
      <Card
        ref={ref}
        fluid
        elevated
        size="large"
        styles={{
          ...theme.siteVariables.noHover,
          margin: '60px auto 0px auto',
          maxWidth: '400px',
        }}
      >
        {success ? (
          <Card.Body>
            <FlexBox gap="12px" flexDirection="column" centerAll>
              <CheckmarkCircleIcon
                size="largest"
                style={{ color: colors.fableGreen }}
              />
              <Text weight="semibold" size="large">
                Link Sent!
              </Text>
              <Text size="medium">
                A download link has been sent to your mobile device.
              </Text>
            </FlexBox>
          </Card.Body>
        ) : (
          <Card.Body>
            <FlexBox
              gap="16px"
              className={css`
                margin-bottom: 16px;
              `}
            >
              <BookImage
                className={css`
                  width: 40px;
                `}
                image={`${book.cover_image_resize}?w=80`}
              />
              <div>
                <Text weight="semibold" size="medium">
                  {book.title}
                </Text>
                <br />
                {book.authors?.length ? (
                  <Text size="medium">by {book.authors[0].name}</Text>
                ) : null}
              </div>
            </FlexBox>
            <Text style={{ marginBottom: '16px' }} size="medium" align="center">
              Enter your phone number and we will send a link to open your book
              sample from your mobile device
            </Text>
            {!!error && (
              <Text size="medium" color={colors.error}>
                {error.message}
              </Text>
            )}
            <PhoneNumberField error={!!error} onChange={setPhoneNumber} />
            <Text styles={{ marginTop: '16px' }} size="small" align="center">
              By clicking “Send”, I accept{' '}
              <a href="https://fable.co/terms" target="_blank" rel="noreferrer">
                Terms of Service
                <OpenOutsideIcon
                  className={css`
                    margin-left: 2px;
                    scale: 0.8;
                  `}
                />
              </a>
              &nbsp;and have read the
              <a
                href="https://fable.co/privacy"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Privacy Policy
                <OpenOutsideIcon
                  className={css`
                    margin-left: 2px;
                    scale: 0.8;
                  `}
                />
              </a>
              .
            </Text>
            <Button
              disabled={loading || (!phoneNumber.number && !phoneNumber.code)}
              onClick={handleClickSend}
              className={css`
                align-self: center;
                margin-top: 16px;
              `}
            >
              {loading ? (
                <Logo
                  className={css`
                    svg {
                      width: 20px;
                    }
                  `}
                  animate
                  isIcon
                />
              ) : (
                'Send Text'
              )}
            </Button>
          </Card.Body>
        )}
        <button
          className={cx(
            nativeOverrides.buttonClear,
            css`
              cursor: pointer;
              position: absolute;
              top: 10px;
              right: 10px;
            `
          )}
        >
          <CloseIcon onClick={closeModal} />
        </button>
      </Card>
    </Modal>
  )
}

export default ChannelTabBookModal
