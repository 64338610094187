import { MilestoneDiscussion } from '@fable/chat'
import { useNavigate, useLocation } from 'react-router-dom'

const useNavigation = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(location.search)

  const urlRoomName = decodeURIComponent(location.pathname.split('/')[3])
  const urlMilestoneId = urlParams.get('milestoneId')
  const urlClubSlug = urlParams.get('club')

  const createSearchParams = () => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('milestoneId')

    return `?${searchParams.toString()}`
  }

  const updatePath = ({
    milestone,
    roomTitle,
  }: {
    milestone: MilestoneDiscussion
    roomTitle: string
  }) => {
    const searchParamsString = createSearchParams()
    const newRoute = `/club/discussions/${roomTitle}${searchParamsString}&milestoneId=${milestone.milestone_id}`

    if (!urlMilestoneId) {
      // As of 11/2022 the suggestion is to allow navigation for opening and closing chat
      // If every room is a route, you'll end up cycling back through every single room before dismissing the chat pane
      navigate(newRoute)
    } else {
      navigate(newRoute, { replace: true })
    }
  }

  const createLinkToThread = (messageId: string) => {
    const searchParamsString = createSearchParams()

    return `/club/discussions/${urlRoomName}/${messageId}${searchParamsString}&milestoneId=${urlMilestoneId}`
  }

  return {
    urlRoomName,
    urlMilestoneId,
    urlClubSlug,
    updatePath,
    createLinkToThread,
  }
}

export default useNavigation
