import { css, cx, useTheme } from '@fable/theme';
import Text from './text/Text';

interface Props extends React.HTMLProps<HTMLDivElement> {
  backgroundColor?: string;
  color?: string;
  title?: string;
  textTransform?: string;
  children?: React.ReactNode | React.ReactNode[];
}

const Tag: React.FC<Props> = ({
  backgroundColor,
  color,
  title,
  textTransform = 'uppercase',
  children,
  ...nativeProps
}) => {
  const { colors } = useTheme();

  const text = !!title ? (
    <Text
      className={css`
        text-transform: ${textTransform};
      `}
      type="link"
      sizing="S"
      color={color ? color : colors.blackSwan}
    >
      {title}
    </Text>
  ) : null;

  return (
    <span
      {...nativeProps}
      className={cx(
        css`
          padding: 4px 8px;
          display: table;
          width: auto;
          border-radius: 4px;
          position: relative;
          z-index: 1;
          overflow: hidden;
          background-color: ${backgroundColor
            ? backgroundColor
            : colors.whiteSmoke};
        `,
        nativeProps.className
      )}
    >
      {text || children}
    </span>
  );
};

export default Tag;
