import { css, cx } from '@fable/theme';
import FableIcon from './FableIcon';
import FableLogo from './FableLogo';
import FableText from './FableText';

interface Props {
  isIcon?: boolean;
  isLight?: boolean;
  isText?: boolean;
  isMixed?: boolean;
  animate?: boolean;
  className?: string;
  iconStyles?: string;
}

const Logo: React.FC<Props> = ({
  isIcon = false,
  isLight = false,
  isText = false,
  isMixed = false,
  animate = false,
  className,
  iconStyles = '',
}) => {
  let logo = <FableLogo className={iconStyles} />;

  if (isIcon && !isLight) {
    logo = <FableIcon className={iconStyles} />;
  } else if (isIcon && isLight) {
    logo = <FableIcon light={isLight} className={iconStyles} />;
  } else if (!isIcon && isLight) {
    logo = <FableLogo light={isLight} className={iconStyles} />;
  } else if (!isIcon && isMixed) {
    logo = <FableLogo mixed={isMixed} className={iconStyles} />;
  }

  if (isText) {
    logo = <FableText light={isLight} className={iconStyles} />;
  }

  const animation = css`
    svg {
      path {
        opacity: 0.4;
        animation: flicker 1s linear infinite;
      }

      path:nth-child(3) {
        animation-delay: 0.14s;
      }

      path:nth-child(2) {
        animation-delay: 0.28s;
      }

      path:nth-child(1) {
        animation-delay: 0.42s;
      }

      path:nth-child(7) {
        animation-delay: 0.56s;
      }

      path:nth-child(6) {
        animation-delay: 0.7s;
      }

      path:nth-child(5) {
        animation-delay: 0.84s;
      }

      path:nth-child(4) {
        animation-delay: 1s;
      }

      @keyframes flicker {
        from {
          opacity: 1;
        }
        to {
          opacity: 0.4;
        }
      }
    }
  `;

  return (
    <div
      className={cx(
        css`
          display: block;
          position: relative;
        `,
        animate && animation,
        'logo',
        className
      )}
    >
      {logo}
    </div>
  );
};

export default Logo;
