import { TextStyleProps, TextStyleMapping } from '../types';

const baseStyles: TextStyleProps = {
  fontFamily: `'Des Montilles', cursive`,
  fontWeight: 'normal',
};

const styles: TextStyleMapping = {
  M: {
    fontSize: '28px',
    lineHeight: '28px',
    letterSpacing: 'normal',
  },
};

export const scriptStyles = { styles, baseStyles };
