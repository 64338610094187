import { Text } from '@fluentui/react-northstar';

const ClubBookTaskDetailSection = ({
  header,
  body,
}: {
  header: string | React.ReactChild;
  body: string | React.ReactChild;
}) => (
  <div>
    <Text size="small" weight="bold">
      {header}
    </Text>
    <br />
    <Text size="medium">{body}</Text>
  </div>
);

export default ClubBookTaskDetailSection;
