import { useEffect, useState } from 'react';
import createImage from './utils/createImage';

const usePreloadImage = (src?: string | null, size: number = 640) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  // Sometimes images don't render, so this should ensure the component is rendered when the image is loaded
  // https://jack72828383883.medium.com/how-to-preload-images-into-cache-in-react-js-ff1642708240
  useEffect(() => {
    const newImage = createImage(src || '', size);

    newImage.onload = () => {
      setImage(newImage);
    };
  }, [src, size]);

  return image;
};

export default usePreloadImage;
