import { css, cx, useTheme } from '@fable/theme';
import { allEmoji, Emoji, Text } from '@fable/components';
import { EmojiString } from '@fable/types';
import Linkify from 'react-linkify';
import { clubMemberMentionRegex } from '../lib/mentionLib';
import ChatMemberMention from './ChatMemberMention';
import { Message } from '../chatTypes';

const ChatMessageTextContent = ({ message }: { message: Message }) => {
  const { colors } = useTheme();

  const findMentioner = ({
    message,
    text,
  }: {
    message: Message;
    text: string;
  }) =>
    message.mentions?.find(
      (mention) => mention.id === clubMemberMentionRegex.exec(text)![1]
    );

  const parseMessage = ({
    message,
    text,
  }: {
    message: Message;
    text: string;
  }) => {
    if (clubMemberMentionRegex.test(text)) {
      const mentioner = findMentioner({ message, text });

      return (
        <ChatMemberMention
          key={`${mentioner?.id}${Math.random()}`}
          mentioner={mentioner}
        />
      );
    }

    // when checking for an exact match, it will return false due to invisible characters
    // but text.includes will work as expected
    if (text.includes('<!channel>')) {
      return (
        <span
          key={Math.random()}
          className={cx(
            css`
              color: ${colors.blue};
              background: ${colors.mayaBlueLight};
              font-size: inherit;
              font-weight: 500;
              line-height: inherit;
              letter-spacing: inherit;
              font-family: inherit;
            `,
            'mention'
          )}
        >
          {text.replace('<!channel>', '@club')}&nbsp;
        </span>
      );
    }

    return (
      <span key={Math.random()}>
        {/* replaces emoji with our emoji */}
        {allEmoji[text as EmojiString] ? (
          <Emoji
            className={css`
              width: 14px;
              height: 14px;
            `}
            emoji={text as EmojiString}
          />
        ) : (
          // otherwise just returns text
          text
        )}
        &nbsp;
      </span>
    );
  };

  return (
    <div>
      {message.text.split('\n').map((line, j) => (
        <div key={j}>
          <Text
            className={css`
              display: flex;
              flex-wrap: wrap;
              align-items: baseline;
              word-break: break-all;
            `}
            type="body"
            sizing="M"
          >
            <Linkify
              componentDecorator={(
                decoratedHref,
                decoratedText,
                key: number
              ) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {line?.split(' ').map((text) => parseMessage({ message, text }))}
            </Linkify>
          </Text>
        </div>
      ))}
    </div>
  );
};

export default ChatMessageTextContent;
