import { useContext } from 'react';
import { css, useTheme } from '@fable/theme';
import { ChatContext } from '@fable/chat';
import { Text } from '@fluentui/react-northstar';
import banner from 'assets/graphics/banner.png';

const ChannelTabBanner = () => {
  const { mediaQueries } = useTheme();
  // TypeScript only throws errors for this in Teams, not in our web app
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { clubDetailsQuery } = useContext(ChatContext);
  const club = clubDetailsQuery?.data?.data;

  return (
    <div
      data-testid="channelTabBanner"
      className={css`
        background: url(${banner}) top left / cover no-repeat;
        height: 148px;
        padding: 0 18px;
        display: flex;
        align-items: center;
        ${mediaQueries.tabletL} {
          height: 186px;
          padding: 0 32px;
        }
      `}
    >
      <div>
        <Text size="larger" color="white" weight="bold">
          {club?.title}
        </Text>
        <br />
        <Text color="white" weight="semibold">
          {club?.feature_text || club?.seo_description || club?.about_us}
        </Text>
      </div>
    </div>
  );
};

export default ChannelTabBanner;
