import { User } from '@fable/types';
import ChatMessageAttachmentQuote from './ChatMessageAttachmentQuote';
import ChatMessageAttachmentReview from './ChatMessageAttachmentReview';
import { Attachment, AttachmentQuote, AttachmentReview } from '../chatTypes';
import ChatMessageAttachmentLink from './ChatMessageAttachmentLink';

interface Props {
  /** @param {Attachment} linkData - as seen in a message attachment */
  linkData: Attachment;
  /** @param {User} messageAuthor - The user who sent the message */
  messageAuthor: User;
}

const ChatMessageAttachmentLinkUnfurl = ({
  linkData,
  messageAuthor,
}: Props) => {
  switch (linkData.type) {
    case 'review':
      return (
        <ChatMessageAttachmentReview
          review={linkData as AttachmentReview}
          messageAuthor={messageAuthor}
        />
      );
    case 'quote':
      const quote = linkData as AttachmentQuote;

      return <ChatMessageAttachmentQuote quote={quote} />;
  }

  return <ChatMessageAttachmentLink linkData={linkData} />;
};

export default ChatMessageAttachmentLinkUnfurl;
