import { helpers } from './helpers';
import { downloads } from './downloads';
import { book } from './book';
import { club } from './club';

export const requests = {
  helpers,
  ...downloads,
  ...book,
  ...club,
};
