import { css, useTheme } from '@fable/theme';
import ChatMessageAttachmentLinkUnfurl from './ChatMessageAttachmentLinkUnfurl';
import { FlexBox } from '@fable/components';
import { AttachmentGeneric, AttachmentPoll, Message } from '../chatTypes';
import ChatMessageAttachmentPhoto from './ChatMessageAttachmentPhoto';
import ChatMessageAttachmentPoll from './ChatMessageAttachmentPoll';
import isBefore from 'date-fns/isBefore';
import ChatMessageAttachmentPollResults from './ChatMessageAttachmentPollResults';

const ChatMessageAttachment = ({ message }: { message: Message }) => {
  const { colors } = useTheme();

  const renderAttachment = (attachment: AttachmentGeneric) => {
    if (attachment.type === 'photo') {
      return <ChatMessageAttachmentPhoto image={attachment} />;
    }

    if (attachment.type === 'poll') {
      const poll = attachment as AttachmentPoll;
      if (isBefore(new Date(poll.expiration_at), new Date())) {
        return (
          <ChatMessageAttachmentPollResults poll={poll as AttachmentPoll} />
        );
      }

      return <ChatMessageAttachmentPoll poll={poll} />;
    }

    return (
      <FlexBox>
        {attachment.type === 'link' ||
          (attachment.type === 'rich' && (
            // this is a horizontal line that only appears for links
            <div
              className={css`
                flex: 0 0 5px;
                width: 5px;
                margin-right: 10px;
                background: ${colors.whiteSmoke};
              `}
            />
          ))}
        <ChatMessageAttachmentLinkUnfurl
          messageAuthor={message.user}
          linkData={attachment}
        />
      </FlexBox>
    );
  };

  return (
    <div
      className={css`
        > div {
          padding-bottom: 20px;
          & + div {
            padding-top: 20px;
            border-top: 1px solid ${colors.almostWhite};
          }
        }
      `}
    >
      {message.attachments?.map((attachment) => (
        <div key={`${message.id}-attachment-${attachment.id}`}>
          {renderAttachment(attachment)}
        </div>
      ))}
    </div>
  );
};

export default ChatMessageAttachment;
