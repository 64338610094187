import { css } from '@fable/theme';
import { Circle, FlexBox, Logo, TransitionElement } from './';
import { usePreloadImage } from '@fable/hooks';
import defaultBg from './assets/default-author-background.png';

const RoundImage: React.FC<
  {
    diameter?: string; // rendered width and height
    src: string;
    alt: string;
    size: number; // For optimization via cloudimg.io
  } & React.HTMLProps<HTMLDivElement>
> = ({ diameter, src, alt, size, ...nativeProps }) => {
  const image = usePreloadImage(src, size);

  const getDiameter = () => {
    if (diameter) return diameter;
    if (size) return `${size}px`;
    return '120px';
  };

  return (
    <Circle diameter={getDiameter()} className={nativeProps.className}>
      {image?.src?.length ? (
        <TransitionElement
          transitionType="fadeIn"
          className={css`
            height: 100%;
          `}
        >
          <img
            // This component is shared with internal and public profiles
            // Their data objects are the same except the usernames API returns profile_image while settings/profile returns pic
            className={css`
              ${image.naturalHeight < image.naturalWidth
                ? 'height: 100%;'
                : 'width: 100%;'}
            `}
            src={image.src}
            alt={alt}
          />
        </TransitionElement>
      ) : (
        <FlexBox
          className={css`
            height: 100%;
            padding: 1px;
            position: relative;
            svg {
              width: 100%;
            }
          `}
        >
          <img
            src={defaultBg}
            alt="default background"
            className={css`
              height: 99%;
            `}
          />
          <Logo
            isIcon
            animate
            isLight
            className={css`
              position: absolute;
              width: 30%;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0);
            `}
          />
        </FlexBox>
      )}
    </Circle>
  );
};

export default RoundImage;
