import { useEffect, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import {
  teamsDarkTheme,
  teamsHighContrastTheme,
  teamsTheme,
  ThemePrepared,
} from '@fluentui/react-northstar';

const getTheme = (): string | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  const theme = urlParams.get('theme');
  return theme == null ? undefined : theme;
};

const getContext = (): Promise<microsoftTeams.Context> => {
  return new Promise((resolve, reject) => {
    microsoftTeams.getContext(resolve);
  });
};

const initialize = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    microsoftTeams.initialize(resolve);
  });
};

export function useTeams() {
  const [inTeams, setInTeams] = useState(false);
  const [context, setContext] = useState<microsoftTeams.Context | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [themeString, setThemeString] = useState<string>('default');
  const [theme, setTheme] = useState<ThemePrepared>(teamsTheme);
  const [fullScreen, setFullScreen] = useState<boolean | undefined>(undefined);
  const [initialTheme] = useState(getTheme());

  const themeChangeHandler = (theme: string | undefined) => {
    setThemeString(theme || 'default');
    switch (theme) {
      case 'dark':
        setTheme(teamsDarkTheme);
        break;
      case 'contrast':
        setTheme(teamsHighContrastTheme);
        break;
      case 'default':
      default:
        setTheme(teamsTheme);
    }
  };

  useEffect(() => {
    themeChangeHandler(initialTheme);
    const cb = async () => {
      try {
        setLoading(true);
        await initialize();
        const contextResult = await getContext();
        setContext(contextResult);
        setFullScreen(contextResult.isFullScreen);
        themeChangeHandler(contextResult.theme);
        microsoftTeams.registerOnThemeChangeHandler(themeChangeHandler);
        microsoftTeams.registerFullScreenHandler((isFullScreen) => {
          setFullScreen(isFullScreen);
        });
        setInTeams(true);
      } catch (error) {
        console.error('Teams init failed', error);
        setError(error as Error);
        setInTeams(false);
      } finally {
        setLoading(false);
      }
    };
    cb();
  }, [initialTheme]);

  return { inTeams, context, loading, theme, themeString, fullScreen, error };
}
