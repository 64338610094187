import { useState, useEffect } from 'react';
import { cx, useTheme } from '@fable/theme';
import { ClassNamesArg } from '@emotion/react';

type TransitionType =
  | ''
  | 'staggerUp'
  | 'fadeIn'
  | 'fadeOut'
  | 'slideInFromRight'
  | 'slideOutFromLeft';

const TransitionElement: React.VFC<{
  className?: string;
  index?: number; // For rending from a loop
  trigger?: boolean; // To trigger the animation. If not provided, animation is triggered on mount
  transitionType: TransitionType;
  children: React.ReactNode | React.ReactNode[];
}> = ({ className, index, trigger, transitionType, children }) => {
  const [showItems, setshowItems] = useState(false);
  const { motion } = useTheme();

  const transition = (): ClassNamesArg => {
    switch (transitionType) {
      case 'staggerUp':
        if (index === undefined) {
          // index is required for staggerUp
          console.error('index is required for staggerUp');
        }
        return motion.staggerUp(showItems, index || 0);
      case 'fadeIn':
        return motion.fadeIn;
      case 'fadeOut':
        return motion.fadeOut;
      case 'slideInFromRight':
        return motion.slideInFromRight;
      case 'slideOutFromLeft':
        return motion.slideOutFromLeft;
      default:
        return motion.transition();
    }
  };

  const triggerStagger = () => {
    setTimeout(() => {
      setshowItems(true);
    }, 200);
  };

  useEffect(() => {
    if (!showItems) {
      switch (transitionType) {
        case 'staggerUp':
          // The stagger up must be triggered since it runs on a setTimeout
          if (trigger === undefined || trigger === true) triggerStagger();
          break;
      }
    }
  }, [trigger, showItems, setshowItems, transitionType]);

  return (
    <div
      className={cx(
        trigger === undefined || trigger === true ? transition() : '',
        className
      )}
    >
      {children}
    </div>
  );
};

export default TransitionElement;
