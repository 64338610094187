import { useContext } from 'react';
import { ChatContext } from '@fable/chat';
import { FlexBox } from '@fable/components';
import { css, cx, useTheme } from '@fable/theme';
import { Button, Card, Text } from '@fluentui/react-northstar';
import ChannelTabDiscussionsNavDiscussions from 'components/channel_tab/ChannelTabDiscussionsNavDiscussions';
import ChannelTabAddBookCard from 'components/channel_tab/ChannelTabAddBookCard';
import addCircle from 'assets/icons/add-circle.svg';
import { ClubContext } from 'utils/ClubContext';
import { useTeams } from 'hooks/useTeams';
import invokeTask from 'utils/invokeTask';

const ChannelTabDiscussionsNav = ({ className }: { className?: string }) => {
  const { colors } = useTheme();
  const { theme } = useTeams();
  const { refetchAll, setShowChat } = useContext(ClubContext);
  const { milestoneDiscussionsQuery, clubDetailsQuery, user } =
    // TypeScript only throws errors for this in Teams, not in our web app
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useContext(ChatContext);

  const milestoneDiscussions =
    milestoneDiscussionsQuery?.data?.data?.milestones || [];

  const club = clubDetailsQuery?.data?.data;
  const isModerator = club?.creator.id === user?.id;

  const allowCreateMilestone =
    club?.creator.id === user?.id &&
    club?.current_book &&
    club.current_book.chapter_count > 0 &&
    milestoneDiscussions?.length < 4;

  return (
    <Card
      fluid
      className={cx(
        css`
          display: flex;
          flex-direction: column;
          height: 100%;
        `,
        className
      )}
      styles={theme.siteVariables.noHover}
    >
      {!isModerator && !milestoneDiscussions?.length ? (
        <Card.Body>
          This club doesn't have a book associated with it. Please contact the
          club moderator.
        </Card.Body>
      ) : (
        <>
          <Card.Header
            className={css`
              &.ui-card__header {
                margin-bottom: 0;
              }
            `}
          >
            <FlexBox
              className={css`
                margin-bottom: 18px;
                padding-left: 18px;
              `}
              alignItems="center"
              justifyContent="space-between"
            >
              <Text weight="semibold" size="medium">
                Discussions
              </Text>
              {allowCreateMilestone && (
                <Button
                  onClick={() =>
                    invokeTask({
                      title: 'Create Milestone',
                      slug: 'create-milestone',
                      onSuccess: () => {
                        setShowChat({ show: false, callback: refetchAll });
                      },
                    })
                  }
                  styles={{
                    background: colors.fableGreen,
                    color: colors.whiteFang,
                    borderColor: colors.whiteFang,
                    ':active': {
                      background: colors.fableGreenExtraDark,
                      color: colors.whiteFang,
                      borderColor: colors.whiteFang,
                    },
                    ':hover': {
                      background: colors.fableGreenExtraDark,
                      color: colors.whiteFang,
                      borderColor: colors.whiteFang,
                    },
                  }}
                >
                  <img
                    className={css`
                      margin-right: 6px;
                    `}
                    src={addCircle}
                    alt="Plus sign in a circle. Click to create milestone."
                  />
                  Create new Milestone
                </Button>
              )}
            </FlexBox>
          </Card.Header>
          <Card.Body>
            {!club?.current_book ? (
              <ChannelTabAddBookCard />
            ) : (
              <ChannelTabDiscussionsNavDiscussions />
            )}
          </Card.Body>
        </>
      )}
    </Card>
  );
};

export default ChannelTabDiscussionsNav;
