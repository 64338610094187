import { IconSVGProps } from '../types';

export const DollarIcon: React.VFC<IconSVGProps> = ({ className, fill }) => (
  <svg className={className} x="0px" y="0px" viewBox="0 0 20 20" fill={fill}>
    <path
      d="M10,13.9c0.6,0,1.2-0.1,1.7-0.3c0.5-0.2,0.9-0.5,1.1-0.8c0.3-0.4,0.4-0.8,0.4-1.3v0c0-0.6-0.2-1.1-0.6-1.5
   c-0.4-0.4-1-0.6-1.8-0.8L9.9,8.9C9.6,8.9,9.3,8.8,9.2,8.7C9,8.6,8.8,8.5,8.7,8.4S8.6,8.1,8.6,7.9v0c0-0.2,0.1-0.3,0.2-0.5
   C8.8,7.3,9,7.2,9.2,7.1C9.4,7,9.7,7,10,7c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.4,0.2,0.6l0,0l1.6,0l0,0
   c0-0.5-0.2-0.9-0.4-1.3c-0.3-0.4-0.6-0.7-1.1-0.8c-0.5-0.2-1-0.3-1.6-0.3C9.4,5.7,8.9,5.8,8.4,6C8,6.1,7.6,6.4,7.3,6.8
   C7.1,7.1,6.9,7.6,6.9,8v0c0,0.4,0.1,0.8,0.3,1.1C7.4,9.4,7.7,9.7,8,9.9c0.4,0.2,0.8,0.4,1.3,0.5l0.9,0.2c0.5,0.1,0.9,0.3,1.1,0.4
   c0.2,0.2,0.3,0.4,0.3,0.6v0c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2,0.1-0.5,0.1-0.9,0.1c-0.3,0-0.6,0-0.8-0.1
   c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.3-0.6v0H6.8l0,0c0,0.5,0.2,0.9,0.4,1.3c0.3,0.4,0.7,0.6,1.1,0.8
   C8.9,13.8,9.4,13.9,10,13.9z M9.7,14.8h0.7V4.7H9.7V14.8z"
    />
  </svg>
);
