import { useContext } from 'react';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';
import {
  ChatContext,
  ChatMessageEmojiRow,
  ChatMessageReplyButton,
  Message,
} from '@fable/chat';
import { FlexBox, RoundImage } from '@fable/components';
import { cloudImageResize } from '@fable/utils';
import { css, cx } from '@fable/theme';
import { Text } from '@fluentui/react-northstar';
import useNavigation from 'hooks/useNavigation';

const ChatPaneMessageReplyRow = ({ message }: { message: Message }) => {
  // TypeScript only throws errors for this in Teams, not in our web app
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { openedThreads } = useContext(ChatContext);
  const { createLinkToThread } = useNavigation();
  // Prevents nested threads
  const disableReply = !!openedThreads && openedThreads.length > 0;

  return (
    <>
      <FlexBox
        className={css`
          gap: 13px;
          a {
            text-decoration: none;
          }
        `}
        alignItems="center"
      >
        <ChatMessageEmojiRow message={message} emojiSize={14} />
        <Link to={createLinkToThread(message.id)}>
          <ChatMessageReplyButton message={message} disabled={disableReply}>
            <Text size="small" color="#616161">
              Reply
            </Text>
          </ChatMessageReplyButton>
        </Link>
      </FlexBox>
      {!openedThreads?.length && (
        <FlexBox
          className={css`
            margin-top: 18px;
            width: 100%;
            a {
              text-decoration: none;
            }
          `}
          alignItems="center"
          justifyContent="space-between"
        >
          {message.replies?.count && (
            <ChatMessageReplyButton message={message} disabled={disableReply}>
              <Link to={createLinkToThread(message.id)}>
                <FlexBox
                  alignItems="center"
                  className={css`
                    gap: 8px;
                  `}
                >
                  {message.replies.participants.slice(0, 4).map((x, i) => (
                    <RoundImage
                      key={i}
                      className={cx(
                        i > 0
                          ? css`
                              margin-left: -8px;
                            `
                          : '',
                        i > 2
                          ? css`
                              opacity: ${`0.${100 - (i + 1) * 10}`};
                            `
                          : css`
                              opacity: 1;
                            `
                      )}
                      src={cloudImageResize(x.pic || '', 20)}
                      alt={`${x.display_name}'s profile image`}
                      size={20}
                    />
                  ))}
                  <Text size="small" color="#616161">
                    {pluralize('reply', message.replies.count, true)}
                  </Text>
                </FlexBox>
              </Link>
            </ChatMessageReplyButton>
          )}
        </FlexBox>
      )}
    </>
  );
};

export default ChatPaneMessageReplyRow;
