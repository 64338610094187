import { createContext, ReactChild, useState } from 'react';

export type SwipeDirection = 'left' | 'right' | undefined;

export const useSwipeCards = () => {
  const [swipeDirection, setSwipeDirection] = useState<SwipeDirection>();

  return {
    setSwipeDirection,
    swipeDirection,
  };
};

export const SwipeCardsContext = createContext<{
  setSwipeDirection: (dir: SwipeDirection) => void;
  swipeDirection: SwipeDirection;
}>({
  setSwipeDirection: () => null,
  swipeDirection: undefined,
});

export const SwipeCardsContextProvider = ({
  children,
}: {
  children: ReactChild;
}) => (
  <SwipeCardsContext.Provider value={useSwipeCards()}>
    {children}
  </SwipeCardsContext.Provider>
);
