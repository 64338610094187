import { get } from '../fable';

const getBook = async (isbn: string) => await get(`/books/${isbn}/`);
const getOwnedBooks = async ({
  includePreorder,
}: {
  includePreorder?: boolean;
}) =>
  await get(
    `/v2/books/owned/${
      includePreorder ? '?include=preorder&include=owned' : ''
    }`
  );

const getSimilarBooks = async (bookId: string) =>
  await get(`/books/${bookId}/similar_books`);

const getGenres = async () => await get('/genres?count=true');

export const book = {
  getBook,
  getSimilarBooks,
  getOwnedBooks,
  getGenres,
};
