import { createContext } from 'react';
import { ThemePrepared } from '@fluentui/react-northstar';

export const TeamsThemeContext = createContext<{
  theme?: ThemePrepared;
  themeString: string;
}>({
  theme: undefined,
  themeString: '',
});
