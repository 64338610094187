import { css, cx } from '@fable/theme';
import { EmojiString } from '@fable/types';
import { allEmoji } from './utils/emojiMap';

const Emoji: React.FC<
  { emoji: EmojiString; size?: number } & React.HTMLProps<HTMLImageElement>
> = ({ emoji, size = 60, ...nativeProps }) => {
  return (
    <img
      data-testid="fableMoji"
      className={cx(
        css`
          width: ${size}px;
          height: ${size}px;
        `,
        nativeProps.className
      )}
      src={allEmoji[emoji]?.src}
      alt={allEmoji[emoji]?.alt}
    />
  );
};

export default Emoji;
