import pinkFlower from 'assets/graphics/flower-pink.svg';
import blueStar from 'assets/graphics/star-blue.svg';
import yellowStar from 'assets/graphics/star-yellow.svg';
import redSquiggle from 'assets/graphics/squiggle-red.svg';
import peopleChatting from 'assets/graphics/people-chatting.png';
import { FlexBox } from '@fable/components';
import { css, useTheme } from '@fable/theme';

const ClubMastheadBanner = () => {
  const { colors } = useTheme();

  return (
    <div
      className={css`
        position: relative;
        background: ${colors.fableGreen};
        overflow: hidden;
      `}
    >
      <FlexBox alignItems="center" justifyContent="space-between">
        <div>
          <img
            className={css`
              position: absolute;
              top: 14px;
              left: 15px;
              z-index: 0;
            `}
            src={pinkFlower}
            alt="a pink flower"
          />
          <img
            className={css`
              position: absolute;
              top: 75px;
              left: 73px;
              z-index: 0;
            `}
            src={yellowStar}
            alt="a yellow star"
          />
          <img
            className={css`
              position: absolute;
              top: 93px;
              left: 37px;
              z-index: 0;
            `}
            src={blueStar}
            alt="a blue star"
          />
        </div>
        <div
          className={css`
            position: relative;
            align-self: flex-end;
          `}
        >
          <img
            className={css`
              width: 380px;
              margin-bottom: -10px;
              z-index: 2;
            `}
            src={peopleChatting}
            alt="People chatting amongst shapes and colors"
          />
          <img
            className={css`
              position: absolute;
              top: 5px;
              right: 10px;
              z-index: 0;
            `}
            src={redSquiggle}
            alt="A red squiggle"
          />
        </div>
      </FlexBox>
    </div>
  );
};

export default ClubMastheadBanner;
