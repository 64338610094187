import { IconSVGProps } from '../types';

export const MoreIcon: React.VFC<IconSVGProps> = ({ className, fill }) => (
  <svg className={className} viewBox="0 0 32 32">
    <g>
      <path
        fill={fill}
        d="M12.7,16c0-1.8,1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3s-1.5,3.3-3.3,3.3S12.7,17.8,12.7,16z"
      />
      <path
        fill={fill}
        d="M24.3,16c0-1.8,1.5-3.3,3.3-3.3S31,14.2,31,16s-1.5,3.3-3.3,3.3S24.3,17.8,24.3,16z"
      />
      <path
        fill={fill}
        d="M1,16c0-1.8,1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3s-1.5,3.3-3.3,3.3S1,17.8,1,16z"
      />
    </g>
  </svg>
);
