import { useTheme } from '@fable/theme';

interface Props {
  className: string;
  light?: boolean;
}

const FableText: React.FC<Props> = ({ className, light }) => {
  const { colors } = useTheme();
  const color = light ? colors.whiteFang : colors.blackSwan;

  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 72.2 24"
      className={className}
    >
      <g>
        <path
          fill={color}
          d="M3.8,7.2V4.8c0-3.1,2.9-4.1,5.3-4.1c1.9,0,3.1,0.5,3.9,1l-1.1,2.8C11.4,4,10.6,3.6,9.3,3.6C8,3.6,7,4,7,5.5
		v1.7h5.2v3H7v13.2H3.8V10.1H0v-3H3.8z"
        />
        <path fill={color} d="M49.9,1.1h3.2v22.2h-3.2V1.1z" />
        <path
          fill={color}
          d="M63.5,23.7c-5.6,0-9.5-3.9-9.5-8.6c0-4.7,4-8.6,9.1-8.6c5.2,0,9.1,3.9,9.1,8.2c0,0.5-0.1,1.4-0.1,1.7H57.5
		c0.6,2.8,2.9,4.4,6,4.4c3.1,0,4.5-1.5,5.3-3.2l3,1.4C70.7,21.6,67.8,23.7,63.5,23.7z M57.5,13.7h11.2c-0.6-2.6-2.8-4.3-5.6-4.3
		C60.2,9.4,58.1,11.1,57.5,13.7z"
        />
        <path
          fill={color}
          d="M40.4,6.6c-2.7,0-4.9,1.1-6.3,2.7V1.1h-3.2v16.3l0,2.2h0c0,0.7-0.6,1.4-1.3,1.4c-0.7,0-1.3-0.6-1.3-1.4v-8.5
		c0-2.6-2.9-4.5-7.9-4.5c-4.2,0-6.9,1.9-8.2,3.6v0l2.8,1.2c1.5-1.3,3.4-2,5.7-2c2.9,0,4.5,1,4.5,2c0,1.3-1.3,1.7-6.1,2
		c-4,0.3-7.3,1.5-7.3,5.1c0,4,3.7,5.2,6.3,5.2c3.2,0,5.6-1.1,7.3-3c0,0.1,0,0.1,0,0.2c0.1,0.3,0.1,0.5,0.2,0.6
		c0.8,1.8,2.7,2.2,4.1,2.2c1.6,0,3.7-0.5,4.3-2.9l0.2,0.2c1.4,1.6,3.6,2.7,6.3,2.7c4.7,0,8.7-3.6,8.7-8.6
		C49.1,10.2,45.2,6.6,40.4,6.6z M25.1,18.1c-1.7,2.1-3.7,2.9-6.5,2.9c-2.8,0-3.5-1.3-3.5-2.5c0-1.3,0.8-2.1,4.3-2.4
		c1.9-0.2,4.3-0.5,5.7-1.3V18.1z M39.9,20.9c-3.5,0-5.8-2.5-5.8-5.8c0-3.3,2.3-5.8,5.8-5.8c3.5,0,5.8,2.5,5.8,5.8
		C45.7,18.4,43.4,20.9,39.9,20.9z"
        />
      </g>
    </svg>
  );
};

export default FableText;
