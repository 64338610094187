/* eslint-disable */

import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { windowReady } from '@fable/utils';

const fableApiBase = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

function isInvalidJWT(error: { response?: { data?: any; status: number } }) {
  return (
    error?.response?.status === 403 &&
    /(JWT|token).*invalid/i.test(error?.response?.data?.detail)
  );
}

const namespace = 'fableApi';

function exponentialDelay(retryNumber = 0) {
  const delay = Math.pow(2, retryNumber) * 100;
  const randomSum = delay * 0.2 * Math.random(); // 0-20% of the delay
  return new Promise((resolve) => setTimeout(resolve, delay + randomSum));
}

export function setAuthHeader(headers: any, idToken: string) {
  headers['Authorization'] = `JWT ${idToken}`;
}

export function setAuthToken(idToken: string) {
  setAuthHeader(fableApiBase.defaults.headers.common, idToken);
}

fableApiBase.interceptors.request.use((config: any) => {
  const userAgent = navigator.userAgent;
  config.headers.common['X-Fable-User-Agent'] = `Fable/1 Web ${userAgent}`;
  return config;
});

fableApiBase.interceptors.response.use(undefined, async (error) => {
  if (error.config && isInvalidJWT(error)) {
    const config = error.config;
    if (!config[namespace]) {
      config[namespace] = { retryCount: 1 };
    }
    const fableState = config[namespace];
    fableState.retryCount++;
    console.log('Refreshing expired Firebase JWT');
    await exponentialDelay(fableState.retryCount);
    const idToken = (await getAuth()?.currentUser?.getIdToken()) || '';
    setAuthToken(idToken);
    setAuthHeader(config.headers, idToken);
    return fableApiBase.request(config);
  }
  throw error;
});

if (process.env.NODE_ENV === 'development' && windowReady) {
  // Ignore for now
  // @ts-ignore
  window.fableApi = fableApi;
}

export const get = (url: string, params?: any) => {
  if (params) {
    return fableApiBase.get(url, params);
  }
  return fableApiBase.get(url);
};
export const patch = (url: string, params?: any) =>
  fableApiBase.patch(url, params);
export const post = (url: string, params?: any) =>
  fableApiBase.post(url, params);
export const remove = (url: string, params?: any) => {
  if (params) {
    return fableApiBase.delete(url, { data: params });
  }
  return fableApiBase.delete(url);
};

export const put = (url: string, params?: any) => fableApiBase.put(url, params);

export const fableApi = fableApiBase;
