import { CSSInterpolation, ThemeModes } from '@fable/theme';
import { RefObject } from 'react';
import { IconTypes } from './icon/types';

export interface EmojiObject {
  src: string;
  alt: string;
}

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  dataTestId?: string;
  destructive?: boolean;
  disabled?: boolean;
  height?: string | number | undefined;
  icon?: IconTypes | null;
  label?: string;
  loading?: boolean;
  mini?: boolean;
  negative?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  outline?: boolean;
  secondary?: boolean;
  small?: boolean;
  translucent?: boolean;
  transparent?: boolean;
  width?: string | number | undefined;
  inverted?: boolean;
  custom?: CSSInterpolation;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

export enum LoaderType {
  contained = 'contained',
  fullscreen = 'fullscreen',
}

export interface TextComponent {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  color?: string;
  font?: string;
  text?: string | number;
}

export interface InputTheme {
  background: string;
  borderColor: string;
  focusBorderColor: string;
  textColor: string;
  placeholderColor: string;
  labelColor: string;
}

export interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  containerClass?: string;
  buttonPosition?: 'left' | 'right';
  onChangeText?: (s: string) => void;
  theme?: ThemeModes;
  error?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  buttonContent?: string | React.ReactChild;
  onClickButton?: () => void;
  buttonDisabled?: boolean;
}

export interface DropdownPropsItem {
  onClick?: (item: DropdownPropsItem) => void;
  component: React.ReactNode;
  mobileOptionText: string;
  mobileOptionValue: string;
}

// Keywords(s) are required when searchable is true
export interface DropdownPropsItemWithSearch extends DropdownPropsItem {
  keywords: string[];
}

export interface DropdownPropsBase {
  mobileListName: string;
}

export interface DropdownPropsWithSearch
  extends DropdownPropsBase,
    TextInputProps {
  searchable: true;
  items: DropdownPropsItemWithSearch[];
}

export interface DropdownPropsWithoutSearch
  extends DropdownPropsBase,
    TextInputProps {
  searchable?: false;
  items: DropdownPropsItem[];
}

export type DropdownProps =
  | DropdownPropsWithSearch
  | DropdownPropsWithoutSearch;

export interface OptionsMenuItem {
  title: string;
  icon?: React.ReactNode;
  path?: string;
  color?: string;
  action?: () => void;
}

export interface OptionsMenuSecondaryAction {
  title: string;
  color?: string;
  action: () => void;
  icon?: React.ReactNode;
}
