import { Link } from 'react-router-dom'
import { FlexBox, Logo } from '@fable/components'
import { Book } from '@fable/types'
import { css } from '@fable/theme'
import { Text, List } from '@fluentui/react-northstar'
import formatSkeletonList from 'utils/formatSkeletonList'

const BookList = ({
  loading,
  listData,
}: {
  loading: boolean
  listData: Book[]
}) => {
  const formatBookList = () =>
    listData.map((book: Book) => {
      // Book data formatted for List component
      const temp = document.createElement('div')
      const descriptionTrimmed = book.description.substring(0, 126)
      temp.innerHTML = descriptionTrimmed
      const bookDescription =
        temp.textContent || temp.innerText || descriptionTrimmed

      return {
        key: book.id,
        content: (
          <Link
            className={css`
              cursor: pointer;
              text-decoration: none;
              color: unset;
            `}
            key={book.id}
            to={`/add-book/${book.isbn}`}
          >
            <FlexBox
              data-testid="bookListItem"
              className={css`
                padding-bottom: 18px;
              `}
            >
              <img
                className={css`
                  width: 50px;
                `}
                src={`${book.cover_image_resize}?w=200`}
                alt={book.title}
              />
              <div
                className={css`
                  margin-left: 17px;
                `}
              >
                <Text weight="bold">{book.title}</Text>,
                {book.authors?.length ? (
                  <Text
                    className={css`
                      margin-bottom: 5px;
                      display: block;
                    `}
                  >
                    by {book.authors[0].name}
                  </Text>
                ) : null}
                <Text>{bookDescription}</Text>
              </div>
            </FlexBox>
          </Link>
        ),
      }
    })

  return (
    <>
      <List
        className={css`
          .ui-list__item__contentWrapper {
            display: block;
          }
        `}
        items={loading ? formatSkeletonList() : formatBookList()}
      />
      {loading && (
        <FlexBox
          centerAll
          className={css`
            padding: 24px 0;
          `}
        >
          <Logo isIcon animate />
        </FlexBox>
      )}
    </>
  )
}

export default BookList
