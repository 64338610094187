import { FlexBox } from '@fable/components'
import { css } from '@fable/theme'
import { Card } from '@fluentui/react-northstar'
import { useTeams } from 'hooks/useTeams'

const ChannelTabBannerControlsItem = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { theme } = useTeams()

  return (
    <Card
      fluid
      className={css`
        height: inherit;
      `}
      styles={{
        ...theme.siteVariables.noHover,
      }}
    >
      <FlexBox
        className={css`
          height: 100%;
        `}
        centerAll
        flexDirection="column"
      >
        {children}
      </FlexBox>
    </Card>
  )
}

export default ChannelTabBannerControlsItem
