import { useTheme, css, cx } from '@fable/theme';
import { Text } from '@fable/components';
import { MentionLinkArgs } from '../lib';

const ChatInputMentionPicker = ({
  clubMembers,
  onClickUser,
}: {
  clubMembers: MentionLinkArgs[] | null;
  onClickUser: (clubMember: MentionLinkArgs) => void;
}) => {
  const { colors, nativeOverrides } = useTheme();

  return (
    <ul
      className={cx(
        'chat-input-mention-picker',
        css`
          background: ${colors.whiteFang};
          border: 1px solid ${colors.greyMedium};
          width: fit-content;
          padding: 0;
          margin: 0;
          list-style-type: none;
          max-height: 200px;
          overflow: auto;
        `
      )}
    >
      {clubMembers
        ?.filter((clubMember: MentionLinkArgs) => !!clubMember.display_name)
        .map((clubMember: MentionLinkArgs, i: number) => (
          <li
            key={i}
            className={css`
              display: flex;
              align: center;
            `}
          >
            <button
              onClick={() => onClickUser(clubMember)}
              className={cx(
                nativeOverrides.buttonClear,
                css`
                  height: 40px;
                  width: 100%;
                  padding: 0 10px;
                `
              )}
            >
              <Text
                className={css`
                  white-space: nowrap;
                `}
                type="body"
                sizing="M"
              >
                {clubMember.display_name}
              </Text>
            </button>
          </li>
        ))}
    </ul>
  );
};

export default ChatInputMentionPicker;
