import 'isomorphic-fetch';
import { useState } from 'react';
import { createMicrosoftGraphClient, TeamsFx } from '@microsoft/teamsfx';
import { tasks } from '@microsoft/teams-js';
import { FlexBox } from '@fable/components';
import {
  Datepicker,
  DatepickerProps,
  Input,
  OpenOutsideIcon,
  TextArea,
} from '@fluentui/react-northstar';
import { User } from '@microsoft/microsoft-graph-types';
import { css, useTheme } from '@fable/theme';
import { PeoplePicker } from '@microsoft/mgt-react';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setDate from 'date-fns/setDate';
import setMonth from 'date-fns/setMonth';
import setYear from 'date-fns/setYear';
import useControlledForm from 'hooks/useControlledForm';
import { useTeams } from 'hooks/useTeams';
import FormLabel from 'components/FormLabel';
import TaskBody from 'components/task/TaskBody';
import TaskHeader from 'components/task/TaskHeader';

const ScheduleMeetingTask = () => {
  const { context } = useTeams();
  const { mediaQueries, colors } = useTheme();

  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setFormData, formData } = useControlledForm({
    subject: '',
    start: {
      dateTime: new Date(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    end: {
      dateTime: new Date(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: {
      contentType: 'text',
      content: '',
    },
    attendees: [] as { emailAddress: { address: string; name: string } }[],
  });

  const toDoubleDigit = (num: number | string) => `0${num}`.slice(-2);

  const timeFormatted = `${toDoubleDigit(
    getHours(formData.start.dateTime)
  )}:${toDoubleDigit(getMinutes(formData.start.dateTime))}`;

  const handleDateChange = (newDate?: Date) => {
    if (!newDate) return;

    const year = newDate.getFullYear();
    const month = newDate.getMonth();
    const dayDate = newDate.getDate();

    // https://github.com/date-fns/date-fns/issues/1532#issuecomment-940788297
    const startDate = [formData.start.dateTime]
      .map((date) => setYear(date, year))
      .map((date) => setMonth(date, month))
      .map((date) => setDate(date, dayDate))[0];

    const endDate = setHours(startDate, getHours(startDate) + 1);

    setFormData({
      start: {
        ...formData.start,
        dateTime: startDate,
      },
      end: {
        ...formData.end,
        dateTime: endDate,
      },
    });
  };

  const handleTimeChange = (time: string) => {
    const timeSplit = time.split(':');
    const hours = timeSplit[0];
    const minutes = timeSplit[1];

    const startDate = [formData.start.dateTime]
      .map((date) => setHours(date, Number(hours)))
      .map((date) => setMinutes(date, Number(minutes)))[0];

    const endDate = [formData.start.dateTime]
      .map((date) => setHours(date, Number(hours) + 1))
      .map((date) => setMinutes(date, Number(minutes)))[0];

    setFormData({
      // data.value is a Date object
      start: {
        ...formData.start,
        dateTime: startDate,
      },
      end: {
        ...formData.end,
        dateTime: endDate,
      },
    });
  };

  const handleClickSaveEvent = async () => {
    const teamsfx = new TeamsFx();
    const client = createMicrosoftGraphClient(teamsfx);

    setLoading(true);

    try {
      await client
        // Not possible to create a team calendar event as of 9/2022
        // https://learn.microsoft.com/en-us/answers/questions/657295/post-api-to-create-calender-events-for-teams-chann.html
        .api(`/me/calendar/events`)
        .post(formData);

      setShowSuccess(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPeople = (selectedPeople: User[]) => {
    if (!selectedPeople) return;

    /**
     * The attendees object differs from the User object received from PeoplePicker
     * The default attendee is added to formData when PeoplePicker fires the onSelectionChange event
     * which happens when defaultSelectedUserIds changes as well as when selecting a person
     */
    setFormData({
      ...formData,
      // format reference https://learn.microsoft.com/en-us/graph/api/calendar-post-events?view=graph-rest-1.0&tabs=javascript#example-1-create-an-event-in-a-specific-calendar
      attendees: selectedPeople.map((person) => ({
        emailAddress: {
          address: person.mail || '',
          name: person.displayName || '',
        },
      })),
    });
  };

  // Rendering once the userObjectId exists ensures defaultSelectedUserIds is set with the currently logged in user
  if (!context?.userObjectId) return null;

  return (
    <TaskBody
      className={css`
        padding: 0 17px;
      `}
      buttons={
        showSuccess
          ? [
              {
                icon: <OpenOutsideIcon />,
                iconPosition: 'after',
                type: 'primary',
                title: 'Click to view calendar',
                onClick: () => {
                  window.open('https://teams.microsoft.com/_#/calendarv2');
                  setTimeout(() => tasks.submitTask(), 500);
                },
              },
            ]
          : [
              {
                type: 'secondary',
                title: 'Cancel',
                disabled: loading,
                onClick: () => tasks.submitTask(),
              },
              {
                type: 'primary',
                title: 'Save',
                loading,
                disabled:
                  loading ||
                  !formData.subject ||
                  formData.attendees.length === 0,
                onClick: () => {
                  handleClickSaveEvent();
                },
              },
            ]
      }
    >
      {showSuccess ? (
        <FlexBox
          centerAll
          flexDirection="column"
          className={css`
            gap: 17px;
            height: 100%;
          `}
        >
          <TaskHeader title="Meeting scheduled!" />
        </FlexBox>
      ) : (
        <>
          <TaskHeader title="Schedule Meeting" />
          <FlexBox
            flexDirection="column"
            className={css`
              gap: 17px;
            `}
          >
            <FlexBox
              // The datepicker component has some weird nested styles that
              // requires overwriting. It also doesn't play nice with flex wrap
              className={css`
                gap: 17px;
                flex-wrap: wrap;
                ${mediaQueries.mobile} {
                  gap: 12px;
                  flex-wrap: nowrap;
                }
              `}
              alignItems="center"
            >
              <div
                className={css`
                  width: 100%;
                  ${mediaQueries.mobile} {
                    flex: 1 1 50%;
                  }
                `}
              >
                <FormLabel label="Date" />
                <Datepicker
                  allowManualInput={false}
                  minDate={new Date()}
                  selectedDate={formData.start.dateTime}
                  onDateChange={(
                    _: unknown,
                    data: (DatepickerProps & { value: Date }) | undefined
                  ) => handleDateChange(data?.value)}
                />
              </div>
              <div
                className={css`
                  flex: 1 1 50%;
                `}
              >
                <FormLabel label="Time" />
                <input
                  data-testid="timePicker"
                  type="time"
                  value={timeFormatted}
                  onChange={(e) => handleTimeChange(e.target.value)}
                />
              </div>
            </FlexBox>
            <div>
              <FormLabel label="Attendees" />
              {/* The returned type in their source code is Event but selectedPeople doesn't exist on event target */}
              <div
                // The content of PeoplePicker is rendered in a shadow dom,
                // aside from injecting a stylesheet into the shadow dom (there are two shadow-roots)
                // setting the variables below appears to be the only way to style it
                className={css`
                  border-radius: 4px;
                  overflow: hidden;
                  background-color: rgb(243, 242, 241);
                  padding-left: calc(0.75rem - 4px);
                  mgt-people-picker {
                    --input-border: 1px solid transparent;
                    --input-background-color: rgb(243, 242, 241);
                    --input-border-color--hover: transparent;
                    --input-border-color--focus: transparent;
                    --color-sub1: rgb(37, 36, 35);
                    --theme-primary-color: ${colors.fableGreen};
                    --theme-dark-color: ${colors.fableGreenDark};
                  }
                `}
              >
                <PeoplePicker
                  defaultSelectedUserIds={[context.userObjectId]}
                  groupId={context?.groupId}
                  selectionChanged={(e: any) =>
                    handleSelectPeople(e?.target?.selectedPeople)
                  }
                />
              </div>
            </div>
            <div>
              <FormLabel label="Topic" />
              <Input
                fluid
                value={formData.subject}
                required
                placeholder="Milestone 2"
                onChange={(e) => {
                  const subject = (e.target as HTMLInputElement)?.value;

                  setFormData({ subject });
                }}
              />
            </div>
            <div>
              <FormLabel label="Provide description" />
              <TextArea
                fluid
                placeholder="Type something"
                value={formData.body.content}
                required
                styles={{ height: '65px' }}
                onChange={(e) => {
                  const content = (e.target as HTMLInputElement)?.value;

                  setFormData({
                    body: {
                      contentType: 'text',
                      content,
                    },
                  });
                }}
              />
            </div>
          </FlexBox>
        </>
      )}
    </TaskBody>
  );
};

export default ScheduleMeetingTask;
