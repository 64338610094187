import { IconSVGProps } from '../types';

export const ArrowRightIcon: React.VFC<IconSVGProps> = ({
  className,
  fill,
}) => (
  <svg
    className={className}
    fill={fill}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    data-testid="arrow-right-icon"
  >
    <path d="M8.25016 0.0833755L6.81079 1.52275L12.507 7.22921L0.0834948 7.22921L0.0834945 9.27087L12.507 9.27087L6.81078 14.9773L8.25016 16.4167L16.4168 8.25004L8.25016 0.0833755Z" />
  </svg>
);
