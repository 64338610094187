import { useContext } from 'react';
import { FileObject } from '@fable/types';
import { ChatContext } from '@fable/chat';
import { cx, css, useTheme } from '@fable/theme';
import { DragAndDropFileUpload, FlexBox } from '@fable/components';
import sendIcon from 'assets/icons/send.svg';
import imageThumbnail from 'assets/icons/image-thumbnail-icon.svg';

const ChatPaneActionBar = ({
  className,
  handleSendMessage,
}: {
  className?: string;
  handleSendMessage?: () => void;
}) => {
  const { nativeOverrides, mediaQueries } = useTheme();
  // TypeScript only throws errors for this in Teams, not in our web app
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setImage, image } = useContext(ChatContext);

  return (
    <FlexBox alignItems="center">
      <DragAndDropFileUpload
        disabled={!!image?.fileData}
        className={css`
          margin-left: 8px;
          width: fit-content;
        `}
        data-testid="fileUploader"
        onDragComponent={
          <FlexBox
            centerAll
            className={css`
              width: 20px;
              height: 20px;
              opacity: 0.7;
            `}
          >
            <img src={imageThumbnail} alt="Silhouette of a photograph" />
          </FlexBox>
        }
        placeHolderComponent={
          <FlexBox
            centerAll
            title={
              image?.fileData
                ? 'Only one image can be sent per message'
                : 'Click or drag-and-drop to upload an image'
            }
            className={cx(
              css`
                width: 20px;
                height: 20px;
                cursor: pointer;
              `,
              image?.fileData &&
                css`
                  opacity: 0.5;
                  cursor: default;
                `
            )}
          >
            <img src={imageThumbnail} alt="Silhouette of a photograph" />
          </FlexBox>
        }
        onUpload={({ render, fileData }: FileObject) =>
          setImage({ render, fileData })
        }
      />
      <button
        title="Hold Shift and press Return to add a new line"
        className={cx(
          nativeOverrides.buttonClear,
          className,
          css`
            width: fit-content;
            ${mediaQueries.tablet} {
              width: 25%;
              flex: 1 0 25%;
            }
          `
        )}
        onClick={handleSendMessage}
      >
        <FlexBox alignItems="center" justifyContent="flex-end">
          <img
            src={sendIcon}
            alt="Arrow pointing right. Click to send message"
          />
        </FlexBox>
      </button>
    </FlexBox>
  );
};

export default ChatPaneActionBar;
