import { tasks } from '@microsoft/teams-js';
import getHost from 'utils/getHost';

const invokeTask = ({
  title,
  slug,
  height = 656,
  width = 600,
  onSuccess,
}: {
  title: string;
  slug: string;
  height?: number;
  width?: number;
  onSuccess?: () => void;
}) => {
  tasks.startTask(
    {
      title,
      url: `${getHost()}/${slug}${window.location.search}`,
      height,
      width,
    },
    async (err: string) => {
      if (err) return console.error(err);
      if (onSuccess) onSuccess();
    }
  );
};

export default invokeTask;
