import { useContext, useRef, useState } from 'react';
import { Loader } from '@fluentui/react-northstar';
import { css, cx, useTheme } from '@fable/theme';
import { ChatContext } from '@fable/chat';
import { Resizable } from 're-resizable';
import ChannelTabDiscussionsNav from 'components/channel_tab/ChannelTabDiscussionsNav';
import ChannelTabBanner from 'components/channel_tab/channel_tab_banner/ChannelTabBanner';
import ChannelTabBannerControls from 'components/channel_tab/channel_tab_banner/ChannelTabBannerControls';
import { useTeams } from 'hooks/useTeams';
import { ClubContext } from 'utils/ClubContext';

const ChannelTabLeft = () => {
  const { theme } = useTeams();
  const { mediaQueries, colors } = useTheme();
  const { clubDetailsQuery, milestoneDiscussionsQuery } =
    // @ts-ignore
    useContext(ChatContext);
  const { showChat, tenantClubQuery } = useContext(ClubContext);

  const resizeEnableOptions = useRef({
    top: false,
    right: true,
    bottom: false,
    left: false,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
  });

  const [leftWidth, setLeftWidth] = useState(window.innerWidth - 320);

  const loading =
    !showChat &&
    (tenantClubQuery?.isLoading ||
      clubDetailsQuery?.isLoading ||
      milestoneDiscussionsQuery?.isLoading ||
      clubDetailsQuery?.isRefetching ||
      milestoneDiscussionsQuery?.isFetching);

  return (
    <Resizable
      enable={
        showChat
          ? resizeEnableOptions.current
          : {
              ...resizeEnableOptions.current,
              right: false,
            }
      }
      handleClasses={{
        right: showChat
          ? css`
              width: 5px !important;
              right: 0 !important;
              transition: 0.3s;
              &:hover {
                background: ${colors.uiBlueExtraLight};
              }
            `
          : '',
      }}
      className={cx(
        css`
          display: flex;
          flex-direction: column;
          height: 100%;
        `,
        showChat
          ? css`
              min-width: 484px;
              max-width: calc(100% - 360px);
              border-right: 1px solid ${theme.siteVariables.colors.grey[250]};
            `
          : ''
      )}
      size={{
        width: showChat ? leftWidth : '100%',
        height: 'auto',
      }}
      onResizeStop={(e, direction, ref, d: any) => {
        setLeftWidth(leftWidth + d.width);
      }}
    >
      <div
        className={css`
          flex: 0 0 auto;
          height: fit-content;
        `}
      >
        <ChannelTabBanner />
        <div
          className={css`
            margin-top: 13px;
            ${mediaQueries.tabletL} {
              margin-top: -32px;
            }
          `}
        >
          <ChannelTabBannerControls />
        </div>
      </div>
      {loading ? (
        <Loader
          color={theme.siteVariables.colors.grey[210]}
          style={{ height: '100vh', width: '100%' }}
        />
      ) : (
        <div
          className={css`
            margin: 18px 26px 22px 26px;
            z-index: 1;
            flex: 1 1 auto;
            overflow-y: auto;
            height: 0;
            ${mediaQueries.tabletL} {
              margin-top: 12px;
            }
          `}
        >
          <ChannelTabDiscussionsNav
            className={css`
              &.ui-card {
                margin: 0;
                width: auto;
                padding-bottom: 0;
                height: fit-content;
              }
            `}
          />
        </div>
      )}
    </Resizable>
  );
};

export default ChannelTabLeft;
