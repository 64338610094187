import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ChatMessageAttachment,
  ChatMessageTextContent,
  Message,
} from '@fable/chat';
import { FlexBox } from '@fable/components';
import { css, cx } from '@fable/theme';
import { Avatar, Text } from '@fluentui/react-northstar';
import TimeAgo from 'javascript-time-ago';
import ChatPaneMessageReplyRow from 'components/chat/ChatPaneMessageReplyRow';
import { useTeams } from 'hooks/useTeams';
import en from 'javascript-time-ago/locale/en';
import ChatPaneMessageOptionsMenu from './ChatPaneMessageOptionsMenu';

const ChatPaneMessage = ({ message }: { message?: Message }) => {
  const timeInterval = useRef<NodeJS.Timeout | null>(null);
  const timeAgo = useRef<TimeAgo | null>(null);
  const [time, setTime] = useState('');
  const { theme } = useTeams();

  const { grey } = theme.siteVariables.colors;

  const tick = useCallback(() => {
    if (!timeAgo.current) return;

    const timeFormatted = timeAgo.current.format(
      new Date(message?.created_at || ''),
      'mini-minute-now'
    );

    const timeString = `${timeFormatted}${
      timeFormatted === 'now' ? '' : ' ago'
    }`;

    setTime(timeString);
  }, [message?.created_at]);

  useEffect(() => {
    TimeAgo.addLocale(en);
    const TimeAgoInstance = new TimeAgo('en-US');

    timeAgo.current = TimeAgoInstance;
  }, []);

  useEffect(() => {
    if (!time) {
      tick();
    }

    timeInterval.current = setInterval(() => tick(), 30000);

    return () => {
      if (timeInterval.current) clearInterval(timeInterval.current);
    };
  }, [tick, time]);

  if (!message) return <div />;

  return (
    <FlexBox
      className={cx(
        'chat-message',
        css`
          padding: 27px 18px;
          background: ${grey[210]};
        `
      )}
    >
      <Avatar
        image={`${message?.user?.pic_resize}?w=30`}
        name={message?.user?.display_name}
        status="unknown"
        size="small"
        className={css`
          flex: 0 0 auto;
          margin-right: 8px;
        `}
      />
      <div
        className={css`
          flex: 1 1 auto;
          width: 80%;
        `}
      >
        <FlexBox alignItems="center" justifyContent="space-between">
          <div
            className={css`
              position: relative;
              width: 100%;
            `}
          >
            <FlexBox
              alignItems="center"
              className={css`
                width: 100%;
              `}
            >
              <Text weight="semibold" size="small">
                {message?.user?.display_name}
              </Text>
              <Text
                className={css`
                  margin-left: 5px;
                `}
                size="small"
              >
                {message?.user?.pronouns}
              </Text>
              <Text
                className={css`
                  margin-left: clamp(12px, 8.54%, 24px);
                  margin-right: 24px;
                `}
                size="small"
                color={theme.siteVariables.colors.grey[450]}
              >
                {time}
              </Text>
            </FlexBox>
          </div>
          <ChatPaneMessageOptionsMenu message={message} />
        </FlexBox>
        <div
          className={
            message.type === 'highlight'
              ? css`
                  border-left: 2px solid ${grey[300]};
                  padding-left: 5px;
                `
              : ''
          }
        >
          <Text size="small">
            {message?.text && <ChatMessageTextContent message={message} />}
          </Text>
          {message?.attachments?.length && (
            <div
              className={css`
                margin-top: 12px;
                width: 100%;
              `}
            >
              <ChatMessageAttachment message={message} />
            </div>
          )}
        </div>
        <div
          className={css`
            margin-top: 24px;
          `}
        >
          <ChatPaneMessageReplyRow message={message} />
        </div>
      </div>
    </FlexBox>
  );
};

export default ChatPaneMessage;
