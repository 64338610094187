import { FlexBox } from '@fable/components';
import { css, cx, useTheme } from '@fable/theme';
import { Button, ButtonProps } from '@fluentui/react-northstar';
import useSetClubSlug from 'hooks/useSetClubSlug';

const TaskBody: React.FC<{
  buttons: ({
    type: 'primary' | 'secondary';
    title: string;
  } & ButtonProps)[];
  className?: string;
}> = ({ buttons, className, children }) => {
  const { mediaQueries } = useTheme();

  /**
   * @note
   *
   * The club slug is lost from the task module (Teams) presumably because it runs
   * in its own iframe.
   *
   * Club data must be refetched via setting the club slug
   */
  useSetClubSlug();

  return (
    <FlexBox
      flexDirection="column"
      justifyContent="space-between"
      className={cx(
        css`
          width: 100%;
          height: 100vh;
          ${mediaQueries.mobile} {
            padding: 0 56px;
          }
        `,
        className
      )}
    >
      <div
        className={css`
          flex: 1 1 auto;
          overflow-y: auto;
        `}
      >
        {children}
      </div>
      <FlexBox
        alignItems="center"
        justifyContent="flex-end"
        className={css`
          flex: 0 0 auto;
          width: 100%;
          padding: 21px 12px;
          button + button {
            margin-left: 5px;
          }
          ${mediaQueries.mobile} {
            padding: 24px 0;
          }
        `}
      >
        {buttons.map((button, i) => (
          <Button
            onClick={button.onClick}
            primary={button.type === 'primary'}
            key={i}
            loading={button.loading}
            disabled={button.disabled}
            icon={button.icon}
            iconPosition={button.iconPosition}
            iconOnly={button.iconOnly}
            content={button.title}
          />
        ))}
      </FlexBox>
    </FlexBox>
  );
};

export default TaskBody;
