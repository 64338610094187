import { css, cx, useTheme } from '@fable/theme';
import { ReviewAttribute, Review } from '@fable/types';
import BookImage from '../BookImage';

import ReviewStars from '../review_stars/ReviewStars';
import BookReviewActions from './BookReviewActions';
import { FlexBox, Emoji, Text } from '../';

const BookReview = ({
  review,
  isPreview,
  showGiftBookButton,
  onCopyToClipboard,
}: {
  review?: Review;
  isPreview: boolean;
  /** @note temporary until redux is removed */
  showGiftBookButton: boolean;
  onCopyToClipboard: () => void;
}) => {
  const { colors } = useTheme();
  /**
   * there is no reliable way to use CSS to add a readmore link, so
   * since this is a preview I'm just going with character count to meet design specs
   */
  const reviewText = isPreview ? (
    <span>
      {review?.review.substring(0, 76)}
      {review && review.review.length > 76 && (
        <>
          ...
          <b>Read more</b>
        </>
      )}
    </span>
  ) : (
    <span>{review?.review}</span>
  );

  return (
    <div
      data-testid="review"
      className={css`
        position: relative;
        text-align: center;
      `}
    >
      {!isPreview && !!review && (
        <div
          className={css`
            position: absolute;
            right: 10px;
            top: 5px;
            z-index: 2;
          `}
        >
          <BookReviewActions
            review={review}
            showGiftBookButton={showGiftBookButton}
            onCopyToClipboard={onCopyToClipboard}
          />
        </div>
      )}
      <div
        className={cx(
          css`
            background: ${review?.book?.background_color};
            border-radius: 16px 16px 0px 0px;
            overflow: hidden;
          `,
          isPreview &&
            css`
              padding-top: 13px;
              height: 140px;
            `,
          !isPreview &&
            css`
              padding-top: 28px;
              height: 162px;
            `
        )}
      >
        <BookImage
          className={css`
            margin: 0 auto;
            width: 116px;
          `}
          size={116}
          image={review?.book?.cover_image || ''}
        />
      </div>
      <FlexBox
        flexDirection="column"
        alignItems="center"
        className={cx(
          css`
            position: relative;
            padding: 40px 24px 24px 24px;
            border-radius: 0px 0px 16px 16px;
          `,
          isPreview &&
            css`
              height: 100%;
              background: ${colors.whiteFang};
            `,
          !isPreview &&
            css`
              background: ${colors.almostWhite};
            `
        )}
      >
        {review?.emoji_reaction && (
          <Emoji
            className={css`
              position: absolute;
              top: -30px;
              left: 50%;
              transform: translateX(-50%);
            `}
            emoji={review?.emoji_reaction}
          />
        )}

        <ReviewStars
          readOnly
          color={review?.book?.background_color || ''}
          rating={review?.rating || 0}
        />
        {!isPreview && (
          <Text
            className={css`
              padding: 9px 0px 12px 0px;
            `}
            type="subhead"
            sizing={{ base: 'XS', mobileL: 'S' }}
            color={colors.greyMedium}
            tag="h4"
          >
            {review?.book?.title}
          </Text>
        )}
        <div
          className={css`
            max-height: 456px;
          `}
        >
          {review?.review && review.review.length > 0 ? (
            <Text
              data-testid="reviewBody"
              className={css`
                max-height: 128px;
                max-width: 350px;
                overflow: auto;
              `}
              type="body"
              sizing={{ base: 'S', mobileL: 'M' }}
            >
              {reviewText}
            </Text>
          ) : null}
          {review?.attributes && review.attributes.length > 0 ? (
            <FlexBox
              centerAll
              flexWrap="wrap"
              className={css`
                margin-top: 14px;
                gap: 10px 4px;
              `}
            >
              {review.attributes.map((attribute: ReviewAttribute, i) => (
                <div
                  key={i}
                  className={css`
                    white-space: nowrap;
                    padding: 2px 9.5px;
                    border-radius: 24px;
                    width: fit-content;
                    background: ${review?.book?.background_color};
                  `}
                >
                  <Text type="link" sizing="M" color={colors.whiteFang}>
                    {attribute.name}
                  </Text>
                </div>
              ))}
            </FlexBox>
          ) : null}
        </div>
      </FlexBox>
    </div>
  );
};

export default BookReview;
