import { useState } from 'react';

const useControlledForm = <T>(
  fields: T
): { setFormData: (fields: Partial<T>) => void; formData: T } => {
  const [state, setState] = useState<T>(fields);

  const setFormData = (updates: Partial<T>) => {
    /// { ...prevState, ...updatedField }
    setState((prevState: T) => ({ ...prevState, ...updates }));
  };

  return {
    setFormData,
    formData: state,
  };
};

export default useControlledForm;
