import { css } from '@fable/theme';
import { Button, ButtonProps, Text } from '@fluentui/react-northstar';
import { FlexBox } from '@fable/components';
import peopleAtWorkImage from '../../assets/graphics/people-at-work.png';
import { ComponentEventHandler } from '@fluentui/react-northstar/dist/es/types';

interface ConnectToFableTaskProps {
  onConnectClick: ComponentEventHandler<ButtonProps>;
}

const ConnectToFableTask = ({ onConnectClick }: ConnectToFableTaskProps) => {
  return (
    <FlexBox
      flexDirection="column"
      className={css`
        width: 100%;
        height: 100vh;
      `}
    >
      <div
        className={css`
          flex: 0 0 auto;
          padding: 0 30px;
        `}
      >
        <div
          className={css`
            text-align: center;
            margin: 0 auto;
          `}
        >
          <img src={peopleAtWorkImage} alt="people at work" />
          <div>
            <Text
              styles={{
                display: 'block',
                marginBottom: '2px',
                fontSize: '20px',
                lineHeight: '28px',
              }}
              weight="bold"
            >
              Next step: Sign up for a Fable account.
            </Text>
          </div>
          <Text>
            This will just take a moment! A new browser window will open, and
            you'll link your Microsoft account to Fable
          </Text>
        </div>
        <div
          className={css`
            text-align: center;
            margin: 20px auto 0 auto;
          `}
        >
          <Button content="Connect to Fable" onClick={onConnectClick} />
        </div>
      </div>
    </FlexBox>
  );
};

export default ConnectToFableTask;
