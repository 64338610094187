import { useContext } from 'react';
import { css, useTheme } from '@fable/theme';
import { ChatContext } from '../chat_context';
import { Attachment } from '../chatTypes';

const ChatMessageAttachmentPhoto = ({
  image,
}: {
  /** @param {Attachment} image - The attachment info for a type "photo" */
  image: Attachment;
}) => {
  const { setModal } = useContext(ChatContext);
  const { nativeOverrides } = useTheme();

  const handleClickPhoto = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    url: string
  ) => {
    e.preventDefault();

    setModal({
      show: true,
      component: (
        <img
          data-testid="fullsize"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className={css`
            height: calc(100% - 20%);
            margin-top: 50px;
          `}
          src={url}
          alt="attachment full size"
        />
      ),
    });
  };

  return (
    <div>
      <button
        className={nativeOverrides.buttonClear}
        onClick={(e) => handleClickPhoto(e, image.url)}
      >
        <img
          className={css`
            width: 100%;
          `}
          data-testid="inMessageThumbnail"
          src={image.thumbnail_url}
          alt="attachment"
        />
      </button>
    </div>
  );
};

export default ChatMessageAttachmentPhoto;
