// import { useContext } from 'react';
// import { ChatContext } from '@fable/chat';
import { FlexBox } from '@fable/components';
import { css, useTheme } from '@fable/theme';
import ChannelTabBannerControlsModerator from 'components/channel_tab/channel_tab_banner/ChannelTabBannerControlsModerator';
import ChannelTabBannerControlsBook from 'components/channel_tab/channel_tab_banner/ChannelTabBannerControlsBook';
// temporarily disabled
// import ChannelTabBannerControlsBookshelf from 'components/channel_tab/channel_tab_banner/ChannelTabBannerControlsBookshelf';
// import ChannelTabBannerControlsMeeting from 'components/channel_tab/channel_tab_banner/ChannelTabBannerControlsMeeting';
import ChannelTabBannerControlsItem from 'components/channel_tab/channel_tab_banner/ChannelTabBannerControlsItem';

const ChannelTabBannerControls = () => {
  const { mediaQueries } = useTheme();
  // TypeScript only throws errors for this in Teams, not in our web app
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // const { clubDetailsQuery, user } = useContext(ChatContext);
  // const isModerator = clubDetailsQuery?.data?.data?.creator.id === user?.id;

  return (
    <FlexBox
      data-testid="banner-controls"
      flexWrap="wrap"
      className={css`
        gap: 16px;
        width: 100%;
        padding: 0 18px;
        ${mediaQueries.tablet} {
          padding: 0 32px;
          position: relative;
          button {
            white-space: nowrap;
          }
        }
      `}
    >
      {/* tablet and under (designs are too different to use default responsiveness) */}
      <div
        className={css`
          width: 100%;
          ${mediaQueries.tablet} {
            display: none;
          }
        `}
      >
        <ChannelTabBannerControlsItem>
          <FlexBox
            alignItems="center"
            className={css`
              gap: 29px;
            `}
          >
            <ChannelTabBannerControlsBook />
            <ChannelTabBannerControlsModerator />
          </FlexBox>
        </ChannelTabBannerControlsItem>
      </div>
      {/* tablet and up */}
      <FlexBox
        className={css`
          display: none;
          ${mediaQueries.tablet} {
            gap: 16px;
            display: flex;
            flex: 1 1 auto;
          }
          ${mediaQueries.tabletL} {
            justify-content: flex-end;
          }
        `}
      >
        <ChannelTabBannerControlsItem>
          <ChannelTabBannerControlsBook />
        </ChannelTabBannerControlsItem>
        <ChannelTabBannerControlsItem>
          <ChannelTabBannerControlsModerator />
        </ChannelTabBannerControlsItem>
      </FlexBox>
      {/* {isModerator && (
        <FlexBox
          className={css`
            flex: 1 1 auto;
            gap: 11px;
            ${mediaQueries.tablet} {
              gap: 16px;
            }
          `}
        >
          <ChannelTabBannerControlsItem>
            <ChannelTabBannerControlsMeeting />
          </ChannelTabBannerControlsItem>
        </FlexBox>
      )} */}
    </FlexBox>
  );
};

export default ChannelTabBannerControls;
