import { useContext } from 'react';
import { ChatContext, Message } from '@fable/chat';
import { OptionsMenu, OptionsMenuItem, TrashIcon } from '@fable/components';
import { css } from '@fable/theme';
import ChatPaneMessageDeleteModal from 'components/chat/ChatPaneMessageDeleteModal';

const ChatPaneMessageOptionsMenu = ({ message }: { message?: Message }) => {
  //@ts-ignore
  const { setModal, user } = useContext(ChatContext);

  const items: OptionsMenuItem[] = [
    {
      title: 'Delete',
      icon: <TrashIcon />,
      action: () =>
        setModal({
          show: true,
          component: <ChatPaneMessageDeleteModal message={message} />,
        }),
    },
  ];

  if (message?.user?.id !== user?.id) {
    return null;
  }

  return (
    <OptionsMenu
      control
      anchor="top"
      controlDirection="horizontal"
      containerClass={css`
        .fds-ui--dropdown-box-content {
          width: fit-content;
        }
        .fds-ui--dropdown-item {
          padding: 0px 15px 0px 9px;
        }
      `}
      items={items}
    />
  );
};

export default ChatPaneMessageOptionsMenu;
