import { TextStyleProps, TextStyleMapping } from '../types';

const baseStyles: TextStyleProps = {
  fontFamily: `'Heldane Display', serif`,
  fontWeight: 400,
};

const styles: TextStyleMapping = {
  XL: {
    fontSize: '58px',
    lineHeight: '60px',
    letterSpacing: '-1px',
  },
  L: {
    fontSize: '46px',
    lineHeight: '48px',
    letterSpacing: '-0.5px',
  },
  M: {
    fontSize: '36px',
    lineHeight: '36px',
    letterSpacing: '-0.5px',
  },
  S: {
    fontSize: '26px',
    lineHeight: '28px',
    letterSpacing: '0px',
  },
  XS: {
    fontSize: '22px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  XXS: {
    fontSize: '18px',
    lineHeight: '20px',
    letterSpacing: '0px',
  },
};

export const headerStyles = { styles, baseStyles };
