import { css } from '@fable/theme';
import { Button, ButtonProps, Text } from '@fluentui/react-northstar';
import { FlexBox } from '@fable/components';
import MicrosoftLogo from '../icons/MicrosoftLogo';
import peopleAtWorkImage from '../../assets/graphics/people-at-work.png';
import { ComponentEventHandler } from '@fluentui/react-northstar/dist/es/types';

interface SignInTaskProps {
  onSignInClick: ComponentEventHandler<ButtonProps>;
}

const SignInTask = ({ onSignInClick }: SignInTaskProps) => {
  return (
    <FlexBox
      flexDirection="column"
      className={css`
        width: 100%;
        height: 100vh;
      `}
    >
      <div
        className={css`
          flex: 0 0 auto;
          padding: 0 30px;
        `}
      >
        <div
          className={css`
            text-align: center;
            margin: 0 auto;
          `}
        >
          <img src={peopleAtWorkImage} alt="people at work" />
          <div>
            <Text
              styles={{
                display: 'block',
                marginBottom: '2px',
                fontSize: '20px',
                lineHeight: '28px',
              }}
              weight="bold"
            >
              Welcome to Fable. We’re glad you’re here.
            </Text>
          </div>
          <Text>Align your whole organization with book clubs.</Text>
        </div>
        <div
          className={css`
            text-align: center;
            margin: 20px auto 0 auto;
          `}
        >
          <Button
            icon={<MicrosoftLogo size="smallest" />}
            content="Sign in with Microsoft"
            onClick={onSignInClick}
          />
        </div>
      </div>
    </FlexBox>
  );
};

export default SignInTask;
