import {
  linkWithPopup,
  OAuthProvider,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
  linkWithRedirect,
  User,
  onAuthStateChanged,
} from 'firebase/auth';
import { firebaseAuth, hasProvider } from '@fable/api';
import { Observable } from '@apollo/client';

export const scopes = [
  'Channel.ReadBasic.All',
  'email',
  'offline_access',
  'openid',
  'profile',
  'Team.ReadBasic.All',
  'TeamsActivity.Send',
  'User.Read',
  'User.ReadBasic.All',
];

export const microsoftProvider = new OAuthProvider('microsoft.com');
scopes.forEach((scope) => microsoftProvider.addScope(scope));

export async function signInWithFirebase() {
  const result = await signInWithPopup(firebaseAuth, microsoftProvider);
  console.log('signInWithFirebase result', result);
  const credential = OAuthProvider.credentialFromResult(result);
  const idToken = await result.user.getIdToken();
  return { credential, idToken };
}

export async function signInWithFirebaseCustomToken(customToken: string) {
  const result = await signInWithCustomToken(firebaseAuth, customToken);
  return await result.user.getIdToken();
}

export async function linkToFirebaseToMicrosoft(user: User) {
  const result = await linkWithPopup(user, microsoftProvider);
  console.log('linkWithPopup', result);
  return result.user;
}

export async function linkToFirebaseToMicrosoftWithRedirect(user: User) {
  return await linkWithRedirect(user, microsoftProvider);
}

export function signOutWithFirebase() {
  signOut(firebaseAuth);
}

export function accountIsLinked() {
  if (!firebaseAuth.currentUser) {
    throw new Error('Expected firebaseAuth.currentUser');
  }
  return hasProvider(firebaseAuth.currentUser, 'microsoft.com');
}

export function waitForAccountLinked(): Promise<void> {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      firebaseAuth.currentUser?.reload();
      if (accountIsLinked()) {
        clearInterval(interval);
        resolve();
      }
    }, 1000);
  });
}

export type FirebaseUserUpdateEvent = {
  type: 'FIREBASE_USER_UPDATE';
  idToken?: string;
};

export function getFirebaseUser$() {
  return new Observable<FirebaseUserUpdateEvent>((observer) =>
    onAuthStateChanged(firebaseAuth, {
      next: async (user: User | null) => {
        const idToken = await user?.getIdToken();
        observer.next({
          type: 'FIREBASE_USER_UPDATE',
          idToken,
        } as FirebaseUserUpdateEvent); // not sure how how to avoid cast here
      },
      error: (error) => {
        observer.error(error);
      },
      complete: () => {
        observer.complete();
      },
    })
  );
}
