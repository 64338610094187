import ClubForm from 'components/club/ClubForm';
import { css } from '@fable/theme';
import { ClubContext, ClubContextProvider } from 'utils/ClubContext';
import ClubMasthead from 'components/club/ClubMasthead';
import { useContext, useEffect, useState } from 'react';
import { settings } from '@microsoft/teams-js';
import getHost from 'utils/getHost';
import { useTeams } from 'hooks/useTeams';
import { getCustomToken } from './utils/query';
import { Loader } from '@fluentui/react-northstar';

type ClubSettingProps = { postAuthCalled: boolean; accessToken?: string };

const ClubSetting = ({ postAuthCalled, accessToken }: ClubSettingProps) => {
  const { context } = useTeams();
  const { channelId = '' } = context || {};
  const [postAuthComplete, setPostAuthComplete] = useState(postAuthCalled);
  const { tenantClub, tenantClubQuery } = useContext(ClubContext);
  const isFetched = tenantClubQuery?.isFetched;

  useEffect(() => {
    const fn = async () => {
      if (isFetched && tenantClub) {
        setPostAuthComplete(true);
        return;
      }
      if (!postAuthComplete && accessToken) {
        await getCustomToken(accessToken);
        setPostAuthComplete(true);
      }
    };
    fn().catch((e) => console.error(e));
  }, [postAuthComplete, accessToken, isFetched, tenantClub]);

  useEffect(() => {
    const host = getHost();

    settings.setSettings({
      websiteUrl: `${host}/club`,
      contentUrl: `${host}/club?channel=${channelId}`,
      entityId: `fable-club-${channelId}`,
      suggestedDisplayName: 'Fable',
    });
  }, [channelId]);

  useEffect(() => {
    if (isFetched && !!tenantClub) {
      settings.setValidityState(true);
    }
  }, [isFetched, tenantClub]);

  if (!postAuthComplete) {
    return (
      <Loader color="#eeeeee" style={{ height: '100vh', width: '100%' }} />
    );
  }

  return (
    <div
      className={css`
        width: 100%;
      `}
    >
      {!isFetched && 'Loading tenantClub information'}
      {isFetched && tenantClub && (
        <ClubContextProvider>
          <ClubMasthead />
        </ClubContextProvider>
      )}
      {isFetched && !tenantClub && <ClubForm />}
    </div>
  );
};

export default ClubSetting;
