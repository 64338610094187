import { useRef } from 'react';
import { css, useTheme } from '@fable/theme';
import { useClickOutside } from '@fable/hooks';

interface Props {
  onClose?: () => void;
  children: React.ReactNode | React.ReactNode[];
}

const Modal = ({ onClose, children }: Props) => {
  const ref = useRef(null);
  const { colors } = useTheme();

  useClickOutside(ref, () => onClose);

  return (
    <div
      id="modal"
      className={css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${colors.blackSwanFaded};
        z-index: 999;
      `}
    >
      <div
        ref={ref}
        className={css`
          width: fit-content;
          height: fit-content;
          margin: 0 auto;
        `}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
