import { useEffect } from 'react';

export function useMachineLogger(service: any) {
  useEffect(() => {
    service
      .onTransition((state: any) => {
        console.log(`${service.id}.state`, state.value);
      })
      .onEvent((event: any) => {
        console.log(`${service.id}.event`, event);
      });
  }, [service]);
}
