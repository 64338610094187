import { Book } from '@fable/types';
import { FlexBox } from '@fable/components';
import { cx, css, useTheme } from '@fable/theme';
import { Text } from '@fluentui/react-northstar';

const BookDetailBanner = ({
  book,
  className,
  bookStyles,
  header,
  description,
}: {
  book: Book;
  className?: string;
  bookStyles: string;
  header: string;
  description: string;
}) => {
  const { mediaQueries } = useTheme();

  return (
    <FlexBox
      centerAll
      className={cx(
        css`
          gap: 10px;
          width: 100%;
          background: ${book.background_color};
        `,
        className
      )}
    >
      <div
        className={css`
          flex: 1 1 auto;
        `}
      >
        <Text
          color="white"
          weight="bold"
          className={css`
            &.ui-text {
              font-size: 17px;
              line-height: 22px;
              display: block;
              ${mediaQueries.mobile} {
                font-size: 20px;
                line-height: 28px;
              }
            }
          `}
        >
          {header}
        </Text>
        <Text
          color="white"
          className={css`
            &.ui-text {
              font-size: 15px;
              line-height: 20px;
              ${mediaQueries.mobile} {
                font-size: 18px;
                line-height: 24px;
              }
            }
          `}
        >
          {description}
        </Text>
      </div>
      <img
        className={bookStyles}
        src={`${book.cover_image_resize}?w=279`}
        alt={`Cover of ${book.title}`}
      />
    </FlexBox>
  );
};

export default BookDetailBanner;
