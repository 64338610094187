import { css } from '@fable/theme';
import { Loader } from '@fluentui/react-northstar';
import { FlexBox } from '@fable/components';
import peopleAtWorkImage from '../../assets/graphics/people-at-work.png';
import { useMachine } from '@xstate/react';
import { machine } from './LinkToMicrosoft.Machine';
import {
  getFirebaseUser$,
  linkToFirebaseToMicrosoftWithRedirect,
  signInWithFirebaseCustomToken,
} from '../../Firebase';
import { firebaseAuth, post } from '@fable/api';
import { useMachineLogger } from '../../hooks/useMachineLogger';
import { MachineContext } from '../../App.Machine';
import { errorMessage } from '../../utils/error';
import { Text } from '@fluentui/react-northstar';

const P = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      className={css`
        display: block;
        margin: 0.5em 0;
      `}
    >
      {children}
    </Text>
  );
};

const LinkToMicrosoftTask = () => {
  const [state, , service] = useMachine(machine, {
    services: {
      linkWithRedirect: async () => {
        if (!firebaseAuth.currentUser) {
          throw new Error('Expected Firebase user');
        }
        return linkToFirebaseToMicrosoftWithRedirect(firebaseAuth.currentUser);
      },
      getFirebaseUser: getFirebaseUser$,
      getCustomToken: async () => {
        const token = new URLSearchParams(window.location.search).get('token');
        const result = await post('/auth/custom_token', { auth_token: token });
        return result.data.custom_token;
      },
      signInWithFirebase: (ctx: MachineContext) => {
        if (!ctx.customToken) {
          throw new Error('Expected customToken');
        }
        return signInWithFirebaseCustomToken(ctx.customToken);
      },
    },
  });
  useMachineLogger(service);
  function renderError(error: Error) {
    return (
      <div
        className={css`
          text-align: center;
          margin: 20px auto 0 auto;
          font-size: 20px;
        `}
      >
        <P>Something went wrong, sorry!</P>
        <P>Go back to Teams and try again.</P>
        <P>Detailed error message:</P>
        <textarea
          value={errorMessage(error)}
          readOnly
          className={css`
            width: 100%;
            height: 100px;
            max-width: 400px;
          `}
        />
      </div>
    );
  }
  function renderSuccess() {
    return (
      <div
        className={css`
          text-align: center;
          margin: 20px auto 0 auto;
          font-size: 20px;
        `}
      >
        <P>Success!</P>
        <P>Your Fable account is now connected</P>
        <P>You can close this tab and return to Teams.</P>
      </div>
    );
  }
  function render() {
    if (state.matches('error')) {
      return renderError(state.context.error || new Error('Unknown error'));
    } else if (state.matches('success')) {
      return renderSuccess();
    } else {
      return (
        <Loader color="#eeeeee" style={{ height: '100vh', width: '100%' }} />
      );
    }
  }
  return <Container>{render()}</Container>;
};

function Container({ children }: { children: React.ReactNode }) {
  return (
    <FlexBox
      flexDirection="column"
      className={css`
        width: 100%;
        height: 100vh;
      `}
    >
      <div
        className={css`
          flex: 0 0 auto;
          padding: 0 30px;
        `}
      >
        <div
          className={css`
            text-align: center;
            margin: 0 auto;
          `}
        >
          <img src={peopleAtWorkImage} alt="people at work" />
          {children}
        </div>
      </div>
    </FlexBox>
  );
}

export default LinkToMicrosoftTask;
